import { createSlice } from '@reduxjs/toolkit';
import { notifyUserError, notifyUserSuccess } from 'utils/notifications';

import { getUserInfo, getUserInfoImage, patchUserPhoto, putUpdateUserInfo } from './profileActions';

export interface User {
  id: number;
  name: string;
  email: string;
  organizationName: string;
  roles: string[];
  phoneNumber: string;
  profileImageKey: string;
}

export interface UserProfilePhoto {
  userId: string | number;
  file: File;
}

export interface ProfileSliceProps {
  info: User | null;
  profileImage: any;
}

const initialState: ProfileSliceProps = {
  info: null,
  profileImage: null
};

export const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.fulfilled, (state: any, { payload }: any) => {
        state.info = payload;
      })
      .addCase(getUserInfo.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getUserInfoImage.fulfilled, (state: any, { payload }: any) => {
        state.profileImage = URL.createObjectURL(new Blob([payload]));
      })
      .addCase(getUserInfoImage.rejected, (state: any, { payload }: any) => {
        notifyUserError('Failed to download profile photo.');
      })
      .addCase(putUpdateUserInfo.fulfilled, (state: any, { payload }: any) => {
        state.info = { ...state.info, ...payload };
        notifyUserSuccess('Profile Updated');
      })
      .addCase(putUpdateUserInfo.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(patchUserPhoto.fulfilled, (state: any, { payload }: any) => {})
      .addCase(patchUserPhoto.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      });
  }
});

export default profileSlice.reducer;
