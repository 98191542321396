import mexico from 'assets/png/mexico.png';
import { Button, ModalPopup } from 'components';
import { FC, useMemo, useState } from 'react';
import {
  getEnrollmentProgram,
  getEnrollmentPrograms,
  getEnrollmentUsersList,
  getTrackerBar
} from 'store/enrollment/enrollmentActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import FormGoalDate from './formGoalDate';
import FormGoalModal from './formGoalModal';
import style from './goalModal.module.scss';

interface Props {
  setShowGoalModal: (isShow: boolean) => void;
  showGoalModal: boolean;
  openExpandMore: boolean;
}

const GoalModal: FC<Props> = ({ showGoalModal, setShowGoalModal, openExpandMore }) => {
  const { enrollmentProgram, enrollmentListNotConfigured } = useAppSelector(
    (state) => state.enrollment
  );
  const dispatch = useAppDispatch();
  const [step, setActiveStep] = useState<number>(1);

  const programList = enrollmentListNotConfigured?.programs;

  const backStep = () => {
    setActiveStep(step - 1);
  };

  const forwardStep = () => {
    setActiveStep(step + 1);
  };

  const foundProgram = useMemo(() => {
    if (programList && programList.length) {
      return programList[step - 2];
    }
  }, [programList, step]);

  const onCloseModal = () => {
    setActiveStep(1);
    setShowGoalModal(false);
    if (enrollmentProgram?.programId) {
      return dispatch(getEnrollmentProgram({ programId: enrollmentProgram.programId })).then(() => {
        dispatch(getTrackerBar({ programId: enrollmentProgram.programId }));
        if (openExpandMore) {
          dispatch(getEnrollmentUsersList({ programId: enrollmentProgram.programId }));
        }
      });
    }
    dispatch(getEnrollmentPrograms()).then(() => {
      dispatch(getTrackerBar({}));
      if (openExpandMore) {
        dispatch(getEnrollmentUsersList({}));
      }
    });
  };

  const lastStep =
    step === (enrollmentListNotConfigured && enrollmentListNotConfigured?.programs?.length + 2);

  if (!showGoalModal) return null;

  return (
    <ModalPopup open={showGoalModal} customClassInsideContainer={style.goalModalInside}>
      <div className={style.goalModal}>
        <div className={style.imgWrap}>
          <div className={style.text}>
            {step === 1 && 'Ready...'}
            {step > 1 &&
              programList &&
              programList.length > 0 &&
              step < programList.length + 2 &&
              !lastStep &&
              'Set...'}
            {lastStep && 'Goal!'}
          </div>
          <img alt="mexico" src={mexico} />
        </div>
        {step === 1 && enrollmentListNotConfigured && (
          <FormGoalDate
            program={enrollmentListNotConfigured}
            forwardStep={forwardStep}
            handleClose={onCloseModal}
          />
        )}
        {programList &&
          programList.length > 0 &&
          foundProgram &&
          step > 1 &&
          step < programList.length + 2 && (
            <FormGoalModal
              programsLength={programList.length}
              program={foundProgram}
              step={step}
              backStep={backStep}
              forwardStep={forwardStep}
              handleClose={onCloseModal}
            />
          )}
        {lastStep && (
          <div className={style.content}>
            <div>
              <div className={style.title}>
                Goal setting complete{' '}
                <div className={style.closeIcon} onClick={onCloseModal}>
                  <span className="material-icons">close</span>
                </div>
              </div>
              <div className={style.finishText}>
                You can come back and adjust this later if you need to. But try not to tinker too
                much. It's never fun to have goalposts moved.
              </div>
            </div>
            <div className={style.buttonsWrap}>
              <Button
                handleOnClick={backStep}
                theme="secondary"
                icon={<span className="material-icons">arrow_back_ios</span>}
              />
              <Button theme="primary" handleOnClick={onCloseModal} text="Close" />
            </div>
          </div>
        )}
      </div>
    </ModalPopup>
  );
};

export default GoalModal;
