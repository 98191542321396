import axios from 'axios';

import {
  getRefreshTokenFromLSAuth0,
  getSidFromLSAuth0,
  updateLsAccessToken
} from './localStorageTokens';
import { logout } from './networkTools';

export const apiRefreshToken = async (_accessToken: string | null) => {
  try {
    const sid = getSidFromLSAuth0('auth0spajs');
    const refreshToken = getRefreshTokenFromLSAuth0('auth0spajs');

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    const data = new URLSearchParams();
    data.append('grant_type', 'refresh_token');
    data.append('client_id', `${process.env.REACT_APP_AUTH0_CLIENT_ID}`);
    data.append('domain', `${process.env.REACT_APP_AUTH0_DOMAIN}`);
    data.append('refresh_token', String(refreshToken));
    data.append('sessionId', String(sid));

    const response = await axios.post(
      `https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
      data,
      config
    );

    const newAccessToken = response.data.access_token;

    if (response?.status !== 200) {
      throw new Error('Refresh unsuccessfull');
    }

    updateLsAccessToken(newAccessToken);

    return newAccessToken;
  } catch (error: any) {
    // debugger;
    //REFRESH TOKEN EXPIRED
    if (
      true
      // error.code === 'ERR_NETWORK' ||
      // error.response.status === 401 ||
      // error.response.status === 500 ||
      // error.response.status === 503
    ) {
      // logout if refresh not possible

      logout();
      return null;
    }
  }
};

export default apiRefreshToken;
