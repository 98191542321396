import { RecurringType } from 'constants/data';

import {
  Button,
  DatePicker,
  Dropdown,
  ErrorContainer,
  InputField,
  LabelComponent,
  Stack,
  StandardCheckbox,
  TimePicker
} from 'components';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { updateTodo } from 'store/roadmap/roadmapActions';
import { GoalsDTO, TodosDTO } from 'store/roadmap/roadmapSlice';
import { dateValidation } from 'utils/validations/validations';
import * as yup from 'yup';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);
import style from './../roadmaps.module.scss';

type Props = {
  onClose: () => void;
  todo: TodosDTO;
  dataParent?: GoalsDTO;
};

const hasDifferentEndDate = (
  startDateTime: string | undefined,
  endDateTime: string | undefined
) => {
  if (!endDateTime) {
    return false;
  }

  if (!endDateTime && !startDateTime) {
    return false;
  }

  return !dayjs(startDateTime).isSame(endDateTime, 'date');
};

const TodoEventForm = ({ onClose, todo, dataParent }: Props) => {
  const { clientId } = useParams();
  const dispatch = useAppDispatch();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isValid,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    setValues
  } = useFormik({
    initialValues: {
      ...todo,
      date:
        todo.startDateTime || todo.date
          ? dayjs(todo.startDateTime ?? todo.date).format('YYYY-MM-DD')
          : null,
      endDate: todo.endDateTime ? dayjs(todo.endDateTime).format('YYYY-MM-DD') : null,
      startTime: todo.startDateTime ? dayjs(todo.startDateTime).format('HH:mm') : null,
      endTime: todo.endDateTime ? dayjs(todo.endDateTime).format('HH:mm') : null,
      separationCount: todo.separationCount || null,
      recurringType: todo.recurringType || null,
      hasDifferentEndDate: hasDifferentEndDate(todo.startDateTime, todo.endDateTime),
      location: todo?.location
    },
    validateOnMount: true,
    validationSchema: yup.object().shape(
      {
        date: dateValidation
          .when('hasDifferentEndDate', {
            is: true,
            then: (schema) => schema.required('Start Date is required.')
          })
          .required('Date is required'),
        endDate: yup
          .string()
          .nullable()
          .when('hasDifferentEndDate', {
            is: true,
            then: (schema) => schema.required('End Date is required.')
          })
          .test(
            'endDateBeforeStartDate',
            'End Time should be after Start Time.',
            (endDate, context) => {
              if (!context.parent.hasDifferentEndDate || endDate == null) {
                return true;
              }
              const startDate = context.parent.date;

              return dayjs(endDate).isSameOrAfter(startDate, 'day');
            }
          ),
        startTime: yup
          .string()
          .nullable()
          .test('requiredStartTimeTest', 'Start Time is required.', (fieldVal, context) => {
            if (context.parent.hasDifferentEndDate && context.parent.endDate && fieldVal == null) {
              return false;
            }

            if (context.parent.endTime == null || context.parent.endTime === '') {
              return true;
            }

            if (fieldVal != null && fieldVal !== '') {
              return true;
            }

            return false;
          })
          .test('startTimeTest', 'Start Time cannot be in the past.', (startTime, context) => {
            const startDate = context.parent.date;
            if (startDate == null || startDate === '' || startTime == null || startTime === '') {
              return true;
            }
            const now = dayjs();

            const isStartTimeInFuture = dayjs(
              startDate ? `${startDate}T${startTime}` : `2022-01-01T${startTime}`
            ).isAfter(now);

            return isStartTimeInFuture;
          }),
        endTime: yup
          .string()
          .nullable()
          .test('requiredEndTimeTest', 'End Time is required.', (fieldVal, context) => {
            if (context.parent.hasDifferentEndDate && context.parent.endDate && fieldVal == null) {
              return false;
            }

            if (context.parent.startTime == null || context.parent.startTime === '') {
              return true;
            }

            if (fieldVal != null && fieldVal !== '') {
              return true;
            }

            return false;
          })
          .test('endTimeTest', 'End Time needs to be after Start Time.', (fieldVal, context) => {
            const startTime = context.parent.startTime;
            const startDate = context.parent.date;
            const endDate = context.parent.hasDifferentEndDate ? context.parent.endDate : startDate;

            if (startTime == null || startTime === '' || fieldVal == null || fieldVal === '') {
              return true;
            }

            const startTimeDate = dayjs(
              startDate ? `${startDate}T${startTime}` : `2022-01-01T${startTime}`
            );
            const endTimeDate = dayjs(
              endDate ? `${endDate}T${fieldVal}` : `2022-01-01T${fieldVal}`
            );

            return startTimeDate.isBefore(endTimeDate);
          }),
        separationCount: yup
          .string()
          .nullable()
          .when('recurringType', {
            is: (val: string | null) => Boolean(val),
            then: (schema) => schema.required('This field is required.')
          }),
        recurringType: yup
          .string()
          .nullable()
          .when('separationCount', {
            is: (val: string | null) => Boolean(val),
            then: (schema) => schema.required('This field is required.')
          })
      },
      // this is needed to avoid cyclic dependency issue (https://github.com/jquense/yup/issues/79#issuecomment-699605408)
      [['separationCount', 'recurringType']]
    ),
    onSubmit: (newData) => {
      dispatch(updateTodo({ data: newData, clientId, dataParent }));
      onClose();
    }
  });
  const recurringTypes = Object.entries(RecurringType).map((item) => ({
    id: item[0],
    name: item[1].toLowerCase()
  }));

  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        <Stack mb="16px">
          <LabelComponent text={values.hasDifferentEndDate ? 'Start Date' : 'Date'} mandatory />
          <DatePicker
            onChange={(date: string) => {
              setFieldValue('date', date).then(() => {
                setFieldTouched('date', true);
                setFieldTouched('startTime', true);
                setFieldTouched('endTime', true);
              });
            }}
            onBlur={() => {
              setFieldTouched('date');
            }}
            value={values.date || ''}
            placeholder="YYYY-MM-DD"
            minDate={dayjs()}
            disablePast
          />
          <ErrorContainer
            errors={errors}
            name="date"
            visible={errors.date && touched.date}
            autoHeight
          />
          <Stack mt="10px">
            <StandardCheckbox
              value={values.hasDifferentEndDate}
              onChange={(event) => {
                if (!event.target.checked) {
                  setValues({ ...values, endDate: null, startTime: null, endTime: null }).then(
                    () => {
                      setFieldTouched('startTime');
                      setFieldTouched('endTime');
                    }
                  );
                } else {
                  setFieldValue('endDate', values.date).then(() => {
                    setFieldTouched('startTime');
                    setFieldTouched('endTime');
                  });
                }
                handleChange(event);
              }}
              onBlur={handleBlur}
              label="Add an End Date"
              name="hasDifferentEndDate"
            />
          </Stack>
        </Stack>
        {values.hasDifferentEndDate && (
          <Stack mb="16px">
            <LabelComponent text="End Date" mandatory />
            <DatePicker
              onChange={(date: string) => {
                setFieldValue('endDate', date).then(() => {
                  setFieldTouched('endDate', true);
                });
              }}
              onBlur={() => {
                setFieldTouched('endDate');
              }}
              minDate={values.date ? dayjs(values.date) : undefined}
              value={values.endDate}
              placeholder="YYYY-MM-DD"
              disablePast
            />
            <ErrorContainer
              errors={errors}
              name="endDate"
              visible={errors.endDate && touched.endDate}
              autoHeight
            />
          </Stack>
        )}
        <Stack display="flex" flexDirection="row" gap="12px">
          <Stack width="100%">
            <LabelComponent text="Start Time" mandatory={values.hasDifferentEndDate} />
            <TimePicker
              name="startTime"
              onChange={(date: string) => {
                setFieldValue('startTime', date === 'Invalid Date' ? null : date).then(() => {
                  setFieldTouched('startTime');
                  setFieldTouched('endTime');
                  setFieldTouched('date');
                });
              }}
              onBlur={() => {
                setFieldTouched('startTime');
              }}
              value={values.startTime}
              showIcon={false}
            />
            <ErrorContainer
              visible={errors.startTime && touched.startTime}
              errors={errors}
              name="startTime"
              autoHeight
            />
          </Stack>
          <Stack width="100%">
            <LabelComponent text="End Time" mandatory={values.hasDifferentEndDate} />
            <TimePicker
              name="endTime"
              onChange={(date: string) => {
                setFieldValue('endTime', date === 'Invalid Date' ? null : date).then(() => {
                  setFieldTouched('endTime');
                  setFieldTouched('startTime');
                  setFieldTouched('date');
                });
              }}
              onBlur={() => {
                setFieldTouched('endTime');
              }}
              value={values.endTime}
              showIcon={false}
            />
            <ErrorContainer
              visible={errors.endTime && touched.endTime}
              errors={errors}
              name="endTime"
            />
          </Stack>
        </Stack>
        <Stack display="flex" flexDirection="row" alignItems="flex-end">
          <Stack width="100%">
            <LabelComponent text="Repeat Every" />
            <InputField
              classnamesProps={style.todoInput}
              id="separationCount"
              name="separationCount"
              type="number"
              placeholder="0"
              min={'0'}
              onChange={(e) => {
                if (e.target.value === '0') {
                  handleChange({
                    ...e,
                    target: { ...e.target, value: '', name: e.target.name }
                  });
                  setFieldTouched('recurringType');
                  return;
                }
                handleChange(e);
                setFieldTouched('recurringType');
              }}
              onBlur={handleBlur}
              value={values.separationCount || ''}
            />
            <ErrorContainer
              visible={errors.separationCount && touched.separationCount}
              errors={errors}
              name="separationCount"
            />
          </Stack>
          <Stack width="100%">
            <Dropdown
              className={style.todoInput}
              onChange={(e) => {
                handleChange(e);
                setFieldTouched('separationCount');
              }}
              onBlur={handleBlur}
              data={recurringTypes}
              name="recurringType"
              id="recurringType"
              value={values.recurringType || ''}
              label="Select"
            />
            <ErrorContainer
              visible={errors.recurringType && touched.recurringType}
              errors={errors}
              name="recurringType"
            />
          </Stack>
        </Stack>
        <Stack width="100%">
          <LabelComponent text="Location" />
          <InputField
            id="location"
            name="location"
            placeholder="Enter location"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.location || ''}
          />
          <ErrorContainer
            visible={errors.location && touched.location}
            errors={errors}
            name="location"
          />
        </Stack>
        <Stack mt="12px" display="flex" flexDirection="row" gap="12px">
          <Button
            type="button"
            theme="transparent"
            size="extraSmall"
            text="Cancel"
            handleOnClick={onClose}
          />
          <Button type="submit" theme="primary" size="extraSmall" text="Save" disabled={!isValid} />
        </Stack>
      </Stack>
    </form>
  );
};

export default TodoEventForm;
