import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormikProps } from 'formik';

import type { RolesDTO } from 'store/roles/rolesSlice';

import { notifyUserError, notifyUserSuccess } from '../../utils/notifications';
import {
  deleteUser,
  getAboutOrganization,
  getCHWReport,
  getIntegrationsEMR,
  getIntegrationsEMRMessageTypes,
  getIsProgramActive,
  getOrganizationConnections,
  getOrganizationsList,
  getOrganizationsListAutocomplete,
  getProgram,
  getProgramList,
  getProgramListAutocomplete,
  getProgramsReport,
  getUser,
  getUserListAutocomplete,
  getUsersImage,
  getUsersList,
  getValidPrograms,
  patchChangeOrganizationStatus,
  patchChangeProgramStatus,
  postAboutPageCreate,
  postActivateOrganization,
  postActivateProgram,
  postResendInvite,
  postUserCreate,
  putAboutPageCreate,
  putCreateProgream,
  putIntegrationsEMR,
  putOrganizationSSO,
  putUpdateDoingTheWork,
  putUpdateUser
} from './organizationActions';

export interface AboutPageProps {
  name?: string | undefined;
  logoPath?: string | undefined;
  logoUrl?: string | undefined;
  description?: string | undefined;
  contractConfirmation?: boolean | undefined;
  isActive?: boolean | undefined;
  sso: SSOProps;
}

export interface ProgramContentItem {
  id: number;
  name: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  valid: boolean;
  createdBy: string;
  updatedBy: string;
}
export interface ReportingListItem {
  id: number;
  name: string;
  profileImageKey?: string;
}

export interface CHWSItem {
  id: number;
  name: string;
  profileImageKey: string;
}

export interface ProgramValidItem {
  id: number;
  name: string;
  buildingProgramValid: boolean;
  doingTheWorkValid: boolean;
}

export interface Site {
  id: number;
  newSite?: boolean;
  siteName: string;
  status: 'ACTIVE' | 'INACTIVE';
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode: string;
}

export interface ProgramDetails {
  status?: string;
  organizationStatus: string;
  id?: number;
  name?: string;
  sites?: Site[];
  programHiring?: {
    campaigns: number[];
    chwExisting: number;
    chwNew: number;
    managerExisting: number;
    managerNew: number;
    directorExisting: number;
    directorNew: number;
    coordinatorExisting: number;
    coordinatorNew: number;
  };
  eligibilityCriteria: {
    geographyEnabled: boolean;
    zipCodes: { name: string; id: number }[];
    languageEnabled: boolean;
    languages: { name: string; id: number }[];
    insuranceEnabled: boolean;
    insurances: { name: string; id: number }[];
    healthConditionEnabled: boolean;
    healthConditions: { name: string; id: number; description: string }[];
    priorHospitalsVisitsEnabled: boolean;
    priorHospitalsVisitsCondition: string;
    priorHospitalsVisitsValue: number;
    socialNeedsEnabled: boolean;
    referralEnabled: boolean;
    pregnancyIncludeEnabled: boolean;
    rulesForEligibilityEnabled: boolean;
    rulesForEligibility: string;
  };
  enrollmentGoals: {
    chwNumber: number;
    recommendedAverageAnnualEnrollment: number;
  };
  maximumCaseload: {
    maximumChwCaseload: number;
  };
  autoConnectSettings: {
    autoConnectFrequencyMinutes: string;
  };
  programModalityAndDuration: {
    type: string;
    zipCodes: { name: string; id: number }[];
    duration: number;
    period: string;
    indefinite: boolean;
  };
}

export interface ProgramPageProps {
  name: string;
  programsList: {
    content: ProgramContentItem[] | [];
    page: number;
    size: number;
    totalNumber: number;
  };
  programsListAutocomplete: AutocompleteItem[];
  programsListLoading: boolean;
  programDetails: {
    loading: boolean;
    details: ProgramDetails | null;
  };
  programsListValid: {
    programs: ProgramValidItem[];
    hasValidProgram: boolean;
  };
}

export interface OrganizationsContentItem {
  id: number;
  name: string;
  status: string;
  eligibleStatuses: string[];
  logoUrl: string;
  createdAt: string;
  updatedAt: string;
}

export interface UsersContentItem {
  id: number;
  name: string;
  status: string;
  email: string;
  programs: string[];
  roles: string[];
  activationDateTime: string;
  isInitialUser: boolean;
  locked: boolean;
  profileImageKey: string;
  profileImageUrl?: string;
}

export interface AutocompleteItem {
  id: number;
  name: string;
}

export interface OrganizationsListProps {
  organizationsList: {
    content: OrganizationsContentItem[] | [];
    page: number;
    size: number;
    totalNumber: number;
  };
  organizationsListAutocomplete: AutocompleteItem[];
  organizationsListLoading: boolean;
}

export interface UsersListProps {
  usersList: {
    content: UsersContentItem[];
    page: number;
    size: number;
    totalNumber: number;
    customerRoles: number;
    impactRoles: number;
    pendingUsers: number;
  };
  usersListLoading: boolean;
  usersListAutocomplete: AutocompleteItem[];
}

export interface IntegrationsPropsEMRItem {
  id?: number;
  note: string | null;
  directAddress: string | null;
}

export interface IntegrationsEMRMessageTypesItem {
  id: string;
  name: string;
}

export interface IntegrationsPropsEMR {
  enabled: boolean;
  messageType: string | null;
  directAddresses: IntegrationsPropsEMRItem[];
  integrationsEMRMessageTypes?: IntegrationsEMRMessageTypesItem[];
}

type InviteStatus = 'PENDING' | 'CONFIRMED' | 'REJECTED' | 'EXPIRED';

export interface Invite {
  dateTime: string;
  status: InviteStatus;
}

type LinkedUser = {
  name: string;
  surname: string;
  organizationId: number;
  organizationName: string;
};
export interface User {
  id: number;
  name: string;
  surname: string;
  userId: string;
  email: string;
  phoneNumber: string;
  status: string;
  eligibleStatuses: string[];
  organizationId: 0;
  programs: Pick<ProgramContentItem, 'id' | 'name'>[];
  roles: RolesDTO[];
  invites: Invite[];
  isInitialUser: boolean;
  linkedUsers: LinkedUser[];
}

export interface OrganizationSliceProps {
  organizations: OrganizationsListProps;
  about: AboutPageProps | null;
  program: ProgramPageProps;
  programsListReport: ProgramContentItem[];
  chwsListReport: CHWSItem[];
  confirmableRouteChange: LinkChangeProps;
  users: UsersListProps;
  integrationsEMR: IntegrationsPropsEMR;
  selectedUser: { details: User | null; loading: boolean };
  auth0ConnectionList: string[];
}
export interface LinkChangeProps {
  formik: {
    isValid: FormikProps<any>['isValid'];
    submitForm: FormikProps<any>['submitForm'];
  } | null;
  hasUnsavedWork: boolean;
  openConfirmationModal: boolean;
  linkToGo: null | string;
}

export interface SSOProps {
  federated: boolean;
  auth0Connection: string | null;
}

// LINK CHANGE CONFIRM MODAL
const confirmableRouteChange: LinkChangeProps = {
  hasUnsavedWork: false,
  openConfirmationModal: false,
  linkToGo: null,
  formik: null
};
const SSOOrganization: SSOProps = {
  federated: false,
  auth0Connection: null
};
const aboutOrganization: AboutPageProps = {
  name: '',
  logoPath: '',
  description: '',
  contractConfirmation: false,
  isActive: false,
  sso: SSOOrganization
};
const programOrganization: ProgramPageProps = {
  name: 'General Adult Program',
  programsList: {
    content: [],
    page: 0,
    size: 10,
    totalNumber: 0
  },
  programsListAutocomplete: [],
  programsListLoading: true,
  programsListValid: {
    programs: [],
    hasValidProgram: false
  },
  programDetails: {
    loading: false,
    details: null
  }
};
const organizationsList: OrganizationsListProps = {
  organizationsList: {
    content: [],
    page: 0,
    size: 5,
    totalNumber: 0
  },
  organizationsListAutocomplete: [],
  organizationsListLoading: true
};

const usersList: UsersListProps = {
  usersList: {
    content: [],
    page: 0,
    size: 10,
    totalNumber: 0,
    customerRoles: 0,
    impactRoles: 0,
    pendingUsers: 0
  },
  usersListLoading: true,
  usersListAutocomplete: []
};

const integrationOrganizationEMR: IntegrationsPropsEMR = {
  enabled: false,
  messageType: '',
  directAddresses: [],
  integrationsEMRMessageTypes: []
};

const initialState: OrganizationSliceProps = {
  organizations: organizationsList,
  about: aboutOrganization,
  program: programOrganization,
  programsListReport: [],
  chwsListReport: [],
  confirmableRouteChange,
  users: usersList,
  integrationsEMR: integrationOrganizationEMR,
  selectedUser: {
    details: null,
    loading: false
  },
  auth0ConnectionList: []
};

export const organizationSlice = createSlice({
  name: 'organizationSlice',
  initialState,
  reducers: {
    resetAboutOrganization: (state) => {
      state.about = aboutOrganization;
    },
    resetAutocompleteList: (state) => {
      state.organizations.organizationsListAutocomplete = [];
    },
    resetAutocompleteProgramsList: (state) => {
      state.program.programsListAutocomplete = [];
    },
    resetAutocompleteUsersList: (state) => {
      state.users.usersListAutocomplete = [];
    },
    // LINK CHANGE CONFIRM MODAL
    setHasUnsavedWork: (
      state,
      action: PayloadAction<{
        formik: {
          isValid: FormikProps<any>['isValid'];
          submitForm: FormikProps<any>['submitForm'];
        } | null;
        hasUnsavedWork: boolean;
      }>
    ) => {
      state.confirmableRouteChange.formik = action.payload.formik;
      state.confirmableRouteChange.hasUnsavedWork = action.payload.hasUnsavedWork;
    },
    setLinkToGo: (state, action) => {
      state.confirmableRouteChange.linkToGo = action.payload;
    },
    setConfirmationModal: (state, action) => {
      state.confirmableRouteChange.openConfirmationModal = action.payload;
    }
    // END LINK CHANGE CONFIRM MODAL
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAboutOrganization.fulfilled, (state: any, { payload }: any) => {
        state.about = payload;
      })
      .addCase(getAboutOrganization.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(postAboutPageCreate.fulfilled, (state: any, { payload }: any) => {
        notifyUserSuccess(`The organization ${payload.name} was successfully added to the system.`);
      })
      .addCase(postAboutPageCreate.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getOrganizationsList.pending, (state: any) => {
        state.organizations.organizationsListLoading = true;
      })
      .addCase(getOrganizationsList.fulfilled, (state: any, { payload }: any) => {
        state.organizations.organizationsList = payload;
        state.organizations.organizationsListLoading = false;
      })
      .addCase(getOrganizationsList.rejected, (state: any, { payload }: any) => {
        state.organizations.organizationsListLoading = false;
        notifyUserError(payload);
      })
      .addCase(getOrganizationsListAutocomplete.fulfilled, (state: any, { payload }: any) => {
        state.organizations.organizationsListAutocomplete = payload;
      })
      .addCase(getProgramListAutocomplete.fulfilled, (state: any, { payload }: any) => {
        state.program.programsListAutocomplete = payload;
      })
      .addCase(getUsersList.pending, (state: any) => {
        state.users.usersListLoading = true;
      })
      .addCase(getUsersList.fulfilled, (state: any, { payload }: any) => {
        state.users.usersList = payload;
        state.users.usersListLoading = false;
      })
      .addCase(getUsersList.rejected, (state: any, { payload }: any) => {
        state.users.usersListLoading = false;
        notifyUserError(payload);
      })
      .addCase(getUserListAutocomplete.fulfilled, (state: any, { payload }: any) => {
        state.users.usersListAutocomplete = payload;
      })
      .addCase(getProgramList.pending, (state: any) => {
        state.users.programsListLoading = true;
      })
      .addCase(getProgramList.fulfilled, (state: any, { payload }: any) => {
        state.program.programsList = payload;
        state.users.programsListLoading = false;
      })
      .addCase(getProgramList.rejected, (state: any, { payload }: any) => {
        state.users.programsListLoading = false;
        notifyUserError(payload);
      })
      .addCase(putAboutPageCreate.fulfilled, (state: any, { payload }: any) => {
        notifyUserSuccess("Your changes were saved. You're all set.");
      })
      .addCase(putAboutPageCreate.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(postUserCreate.fulfilled, (state, { payload, meta }) => {
        state.users.usersList.content.unshift(payload);
        notifyUserSuccess(`${payload.name} added to the organization`);
      })
      .addCase(postUserCreate.rejected, (state, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getUser.pending, (state) => {
        state.selectedUser.loading = true;
      })
      .addCase(getUser.fulfilled, (state, { payload }) => {
        state.selectedUser = {
          details: payload,
          loading: false
        };
      })
      .addCase(putUpdateUser.fulfilled, (state, { payload }) => {
        const userIndex = state.users.usersList.content.findIndex((user) => user.id === payload.id);
        state.users.usersList.content[userIndex] = payload;
        notifyUserSuccess('User updated successfully');
      })
      .addCase(postResendInvite.fulfilled, (state, { payload }) => {
        notifyUserSuccess('Invite resent successfully');
      })
      .addCase(deleteUser.rejected, (state, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getProgram.pending, (state) => {
        state.program.programDetails.loading = true;
      })
      .addCase(getProgram.fulfilled, (state, { payload }) => {
        state.program.programDetails = {
          details: { ...state.program.programDetails.details, ...payload },
          loading: false
        };
      })
      .addCase(getIsProgramActive.pending, (state) => {})
      .addCase(getIsProgramActive.fulfilled, (state, { payload }) => {})
      .addCase(putCreateProgream.fulfilled, (state, { payload, meta }) => {
        if (state.program.programDetails.details) {
          state.program.programDetails = {
            ...state.program.programDetails,
            details: { ...state.program.programDetails.details, name: meta.arg.program.name }
          };
        }
      })
      .addCase(postActivateProgram.fulfilled, (state, { payload }) => {
        notifyUserSuccess('Program activated successfully');
      })
      .addCase(postActivateProgram.rejected, (state, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putUpdateDoingTheWork.fulfilled, (state) => {
        notifyUserSuccess('Your changes were saved.');
      })
      .addCase(putUpdateDoingTheWork.rejected, (state, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getIntegrationsEMR.fulfilled, (state: any, { payload }: any) => {
        state.integrationsEMR = {
          ...payload,
          integrationsEMRMessageTypes: state.integrationsEMR.integrationsEMRMessageTypes
        };
      })
      .addCase(getIntegrationsEMR.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getIntegrationsEMRMessageTypes.fulfilled, (state: any, { payload }: any) => {
        state.integrationsEMR = { ...state.integrationsEMR, integrationsEMRMessageTypes: payload };
      })
      .addCase(getIntegrationsEMRMessageTypes.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putIntegrationsEMR.fulfilled, (state: any, { payload }: any) => {
        state.integrationsEMR = {
          ...payload,
          integrationsEMRMessageTypes: state.integrationsEMR.integrationsEMRMessageTypes
        };
        notifyUserSuccess('Your changes were saved.');
      })
      .addCase(putIntegrationsEMR.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getValidPrograms.fulfilled, (state: any, { payload }: any) => {
        state.program.programsListValid = payload;
      })
      .addCase(getValidPrograms.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(postActivateOrganization.fulfilled, (state: any, { payload }: any) => {
        notifyUserSuccess(`${state.about.name} was successfully activated`);
        state.program.programsListValid = { ...state.program.programsListValid, payload };
      })
      .addCase(patchChangeOrganizationStatus.fulfilled, (state, { meta }) => {
        const index = state.organizations.organizationsList.content.findIndex(
          (org) => org.id === meta.arg.data.organizationID
        );

        if (meta.arg.data.status === 'INACTIVE') {
          state.organizations.organizationsList.content[index].status = 'INACTIVATION_PENDING';

          notifyUserSuccess(
            'All IMPaCT Leaders have received an email notification of this inactivation request. If no action is taken, in 3 days, all users will be notified of inactivation after a 30-day grace period.'
          );
          return;
        }

        state.organizations.organizationsList.content[index].status = meta.arg.data.status;
      })
      .addCase(patchChangeOrganizationStatus.rejected, () => {
        notifyUserError('Something went wrong. Please try again later.');
      })
      .addCase(patchChangeProgramStatus.fulfilled, (state, { meta }) => {
        const index = state.program.programsList.content.findIndex(
          (program) => program.id === meta.arg.data.programID
        );

        if (meta.arg.data.status === 'INACTIVE') {
          state.program.programsList.content[index].status = 'INACTIVATION_PENDING';

          return;
        }

        state.program.programsList.content[index].status = meta.arg.data.status;
      })
      .addCase(patchChangeProgramStatus.rejected, () => {
        notifyUserError('Something went wrong. Please try again later.');
      })
      .addCase(postActivateOrganization.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getUsersImage.fulfilled, (state: any, { payload }: any) => {
        const newArr = state.users.usersList.content;
        const found = newArr.findIndex((a: { id: number }) => a.id === payload.userId);
        newArr[found].profileImageUrl = URL.createObjectURL(new Blob([payload.data]));
        state.users.usersList = { ...state.users.usersList, content: newArr };
      })
      .addCase(getUsersImage.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getOrganizationConnections.fulfilled, (state: any, { payload }: any) => {
        let selectData = payload;
        if (state.about?.sso?.auth0Connection) {
          selectData = [...selectData, state.about?.sso?.auth0Connection];
        }

        state.auth0ConnectionList = selectData;
      })
      .addCase(getOrganizationConnections.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putOrganizationSSO.fulfilled, (state: any, { payload }: any) => {
        notifyUserSuccess('Your changes were saved.');
      })
      .addCase(putOrganizationSSO.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getProgramsReport.fulfilled, (state: any, { payload }: any) => {
        state.programsListReport = payload;
      })
      .addCase(getProgramsReport.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCHWReport.fulfilled, (state: any, { payload }: any) => {
        state.chwsListReport = payload;
      })
      .addCase(getCHWReport.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      });
  }
});

export const {
  resetAboutOrganization,
  resetAutocompleteList,
  setHasUnsavedWork,
  setLinkToGo,
  setConfirmationModal,
  resetAutocompleteProgramsList,
  resetAutocompleteUsersList
} = organizationSlice.actions;
export default organizationSlice.reducer;
