import clsx from 'clsx';
import RouteChangeConfirmModal from 'components/linkWithConfirm/confirmModal/routeChangeConfirmModal';
import * as React from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';

import { Header } from '../../modules';
import style from './layout.module.scss';

interface LayoutProps {}

const Layout: React.FunctionComponent<LayoutProps> = () => {
  const [searchParams] = useSearchParams();

  const context = searchParams.get('context');

  return (
    <>
      {context !== 'popup' && <Header />}
      <div className={clsx(style.layoutContainer, context === 'popup' && style.layoutCondensed)}>
        <Outlet />
        <RouteChangeConfirmModal />
      </div>
    </>
  );
};

export default Layout;
