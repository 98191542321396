import clsx from 'clsx';
import * as React from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setLinkToGo, setConfirmationModal } from 'store/organization/organizationSlice';

import style from './linkWithIndirectRouting.module.scss';

export interface LinkWithIndirectRoutingProps {
  path: string;
  styleClass?: string;
  styleClassActive?: string;
  content: any;
  disabled?: boolean;
}

const LinkWithIndirectRouting = React.forwardRef<HTMLAnchorElement, LinkWithIndirectRoutingProps>(
  ({ path, styleClass, styleClassActive, content, disabled, ...props }, ref) => {
    const location = useLocation();

    const match = useMatch(location.pathname);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const hasUnsavedWork = useAppSelector(
      (state) => state.organization.confirmableRouteChange.hasUnsavedWork
    );

    const handleLinkClick = (pathToGo: string): void => {
      if (hasUnsavedWork) {
        dispatch(setLinkToGo(pathToGo));
        dispatch(setConfirmationModal(true));
      } else {
        navigate(path);
      }
    };

    return (
      <a
        className={clsx(
          style.defaultLink,
          styleClass,
          match?.pathname.includes(path) && styleClassActive,
          disabled && style.linkDisabled
        )}
        {...props}
        ref={ref}
        onClick={() => !disabled && handleLinkClick(path)}
      >
        {content}
      </a>
    );
  }
);

export default LinkWithIndirectRouting;
