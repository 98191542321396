import * as React from 'react';

import style from './logo.module.scss';

interface LogoProps {
  type: 'orange' | 'white' | 'small';
}

const Logo: React.FunctionComponent<LogoProps> = ({ type }) => {
  return (
    <>
      {type == 'orange' && <div className={style.logoOrange} />}
      {type == 'white' && <div className={style.logoWhite} />}
      {type == 'small' && <div className={style.logoSmall} />}
    </>
  );
};

export default Logo;
