import { createSlice } from '@reduxjs/toolkit';

import { notifyUserError } from '../../utils/notifications';
import { getRoles } from './rolesActions';

export interface RolesDTO {
  id: number;
  name: string;
}

export interface RolesSliceProps {
  rolesList: RolesDTO[];
}

const initialState: RolesSliceProps = {
  rolesList: []
};

export const rolesSlice = createSlice({
  name: 'rolesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.fulfilled, (state: any, { payload }: any) => {
        state.rolesList = payload;
      })
      .addCase(getRoles.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      });
  }
});

export default rolesSlice.reducer;
