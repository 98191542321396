import MaterialStack, { StackProps } from '@mui/material/Stack';
import { forwardRef } from 'react';

const Stack = forwardRef(
  (
    { gap, component = 'div', ...props }: StackProps & { component?: React.ElementType<any> },
    ref
  ) => {
    return (
      <MaterialStack
        {...props}
        ref={ref}
        component={component}
        sx={{
          ...props.sx,
          '& > * + *': {
            marginTop: props.flexDirection !== 'row' ? gap : '0px',
            marginLeft: props.flexDirection === 'row' ? gap : '0px'
          }
        }}
      />
    );
  }
);

export default Stack;
