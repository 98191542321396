import { useEffect } from 'react';
import { getLsAccessToken } from 'modules/networkTools/localStorageTokens';

export const useReloadCacheLogout = () => {
  useEffect(() => {
    const handlePageShow = (event: PageTransitionEvent) => {
      if (event.persisted && !getLsAccessToken()) {
        location.reload();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, [getLsAccessToken()]);
};
