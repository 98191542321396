import * as React from 'react';
import CircleLoader from 'react-spinners/CircleLoader';

import style from './loadingSpinner.module.scss';
type LoadingSpinnerProps = {
  size?: number;
};
const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = ({ size = 50 }) => {
  return <CircleLoader color={'#219ebc'} className={style.spinnerColor} size={size} />;
};

export default LoadingSpinner;
