import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { getAboutOrganization } from 'store/organization/organizationActions';

export function useGetOrganization(organizationID: string | undefined) {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const [currentOrganizationName, setCurrentOrganizationName] = useState<any>();

  const getOrganizationData = async () => {
    if (organizationID) {
      const apiRestData = await dispatch(getAboutOrganization(organizationID));
      const organizationData = apiRestData.payload;

      setCurrentOrganizationName({
        name: organizationData.name,
        isActive: organizationData.isActive
      });
    } else {
      setCurrentOrganizationName(null);
    }
  };

  useEffect(() => {
    getOrganizationData();
    return () => {};
  }, [pathname]);
  return currentOrganizationName;
}
