import { PHONE_INPUT_MASK } from 'constants/utils';

import { Grid } from '@mui/material';
import {
  DatePicker,
  Dropdown,
  ErrorContainer,
  InputField,
  LabelComponent,
  Stack
} from 'components';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import { getInsurancesAutocomplete, getLanguagesAutocomplete } from 'store/clients/clientsActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { displayErrorStyling } from 'utils/errorMessages';

import { FormValues } from '../../clientForm';

const genders = [
  { id: 'Woman', name: 'Woman' },
  { id: 'Man', name: 'Man' },
  {
    id: 'Non-binary/Genderqueer/Gender nonconforming',
    name: 'Non-binary/Genderqueer/Gender nonconforming'
  },
  { id: 'Transgender', name: 'Transgender' },
  { id: 'Self-described', name: 'Self-described' }
];

const pronouns = [
  { id: 'She/Her/Hers', name: 'She/Her/Hers' },
  { id: 'He/Him/His', name: 'He/Him/His' },
  { id: 'They/Them/Theirs', name: 'They/Them/Theirs' },
  { id: 'Use name only', name: 'Use name only' },
  { id: 'Self-described', name: 'Self-described' }
];

const PersonalDetails = () => {
  const insurances = useAppSelector((state) => state.clients.insurancesAutocomplete.data);
  const dispatch = useAppDispatch();
  const formik = useFormikContext<FormValues>();

  useEffect(() => {
    dispatch(getLanguagesAutocomplete({ term: '' }));
    dispatch(getInsurancesAutocomplete());
  }, []);

  return (
    <div data-test-id="profile-person-details">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="First Name" mandatory />
            <InputField
              value={formik.values.firstName}
              name="firstName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="First name"
            />
            <ErrorContainer
              visible={formik.touched.firstName && formik.errors.firstName}
              errors={formik.errors}
              name="firstName"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Last Name" mandatory />
            <InputField
              value={formik.values.lastName}
              name="lastName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Last name"
            />
            <ErrorContainer
              visible={formik.touched.lastName && formik.errors.lastName}
              errors={formik.errors}
              name="lastName"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Preferred Name" />
            <InputField
              value={formik.values.preferredName}
              name="preferredName"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Preferred Name"
            />
            <ErrorContainer
              visible={formik.touched.preferredName && formik.errors.preferredName}
              errors={formik.errors}
              name="preferredName"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="DOB" mandatory />
            <DatePicker
              value={formik.values.dob}
              onChange={(value: string) => {
                // @ts-expect-error this is valid but the formik types are incorrect
                formik.setFieldValue('dob', value).then(() => {
                  formik.setFieldTouched('dob', true);
                });
              }}
              onBlur={() => formik.setFieldTouched('dob', true)}
              disableFuture
            />
            <ErrorContainer
              visible={formik.touched.dob && formik.errors.dob}
              errors={formik.errors}
              name="dob"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="MRN or Client Identifier" mandatory />
            <InputField
              value={formik.values.clientIdentifier}
              name="clientIdentifier"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="0000000000"
            />
            <ErrorContainer
              visible={formik.touched.clientIdentifier && formik.errors.clientIdentifier}
              errors={formik.errors}
              name="clientIdentifier"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Primary language" />
            <InputField
              value={formik.values.language}
              name="language"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder=""
            />
            <ErrorContainer
              visible={formik.touched.language && formik.errors.language}
              errors={formik.errors}
              name="language"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Gender Identity" />
            <Dropdown
              value={formik.values.genderIdentity ?? ''}
              name="genderIdentity"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Select"
              data={genders}
            />
            <ErrorContainer
              visible={formik.touched.genderIdentity && formik.errors.genderIdentity}
              errors={formik.errors}
              name="genderIdentity"
            />
          </Stack>
          {formik.values.genderIdentity === 'Self-described' && (
            <Stack>
              <LabelComponent text="Self-described Gender Identity" />
              <InputField
                name="genderSelfDescribed"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.genderSelfDescribed}
                placeholder="Type description"
              />
              <ErrorContainer
                visible={formik.touched.genderSelfDescribed && formik.errors.genderSelfDescribed}
                errors={formik.errors}
                name="genderSelfDescribed"
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Pronouns" />
            <Dropdown
              name="pronouns"
              value={formik.values.pronouns ?? ''}
              data={pronouns}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Select"
            />
            <ErrorContainer
              visible={formik.touched.pronouns && formik.errors.pronouns}
              errors={formik.errors}
              name="pronouns"
            />
          </Stack>
          {formik.values.pronouns === 'Self-described' && (
            <Stack>
              <LabelComponent text="Self-described Pronouns" />
              <InputField
                name="pronounsSelfDescribed"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.pronounsSelfDescribed}
                placeholder="Type description"
              />
              <ErrorContainer
                visible={
                  formik.touched.pronounsSelfDescribed && formik.errors.pronounsSelfDescribed
                }
                errors={formik.errors}
                name="pronounsSelfDescribed"
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Insurance Category" />
            <Dropdown
              value={formik.values.insuranceId ?? ''}
              name="insuranceId"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Select"
              data={insurances ?? []}
            />
            <ErrorContainer
              visible={formik.touched.insuranceId && formik.errors.insuranceId}
              errors={formik.errors}
              name="insuranceId"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Insurance Plan" />
            <InputField
              value={formik.values.insurancePlan}
              name="insurancePlan"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Insurance Plan"
            />
            <ErrorContainer
              visible={formik.touched.insurancePlan && formik.errors.insurancePlan}
              errors={formik.errors}
              name="insurancePlan"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Primary Phone Number" mandatory />
            <MaskedInput
              guide={false}
              mask={PHONE_INPUT_MASK}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.primaryPhoneNumber ?? ''}
              render={(ref, props) => (
                <InputField
                  classnamesProps={displayErrorStyling(
                    formik.touched.primaryPhoneNumber,
                    formik.errors.primaryPhoneNumber
                  )}
                  placeholder="(000) 000-0000"
                  name="primaryPhoneNumber"
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  value={formik.values.primaryPhoneNumber}
                  ref={(el) => {
                    if (el) {
                      ref(el);
                    }
                  }}
                />
              )}
            />
            <ErrorContainer
              visible={formik.touched.primaryPhoneNumber && formik.errors.primaryPhoneNumber}
              errors={formik.errors}
              name="primaryPhoneNumber"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack width="100%">
            <LabelComponent text="Secondary Phone Number" />
            <MaskedInput
              guide={false}
              mask={PHONE_INPUT_MASK}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.secondaryPhoneNumber ?? ''}
              render={(ref, props) => (
                <InputField
                  classnamesProps={displayErrorStyling(
                    formik.touched.secondaryPhoneNumber,
                    formik.errors.secondaryPhoneNumber
                  )}
                  placeholder="(000) 000-0000"
                  name="secondaryPhoneNumber"
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                  value={formik.values.secondaryPhoneNumber}
                  ref={(el) => {
                    if (el) {
                      ref(el);
                    }
                  }}
                />
              )}
            />
            <ErrorContainer
              visible={formik.touched.secondaryPhoneNumber && formik.errors.secondaryPhoneNumber}
              errors={formik.errors}
              name="secondaryPhoneNumber"
            />
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default PersonalDetails;
