import { getLsAccessToken, getLsUserRoleObject } from 'modules/networkTools/localStorageTokens';
export interface AuthStatusProps {
  token: string | null;
  roles: string[];
  permissions: string[];
}
export type PermissionProps = Omit<AuthStatusProps, 'roles'>;
export type RolesProps = Omit<AuthStatusProps, 'permissions'>;

export const useUserAuthStatusCheckerByRole = (): RolesProps => {
  const token = getLsAccessToken();
  const rolesFromLs = getLsUserRoleObject();

  const userGroups = rolesFromLs?.currentOrganization?.roles || [];

  if (!token || userGroups.length < 1) {
    // notifyUserError('Missing token or roles permission');
    return { token: null, roles: [] };
  }

  return { token: token, roles: userGroups };
};

export const useUserAuthStatusCheckerByPermission = (): PermissionProps => {
  const token = getLsAccessToken();

  const roleObject = getLsUserRoleObject();
  const permissionsFromObject = roleObject?.currentOrganization?.permissions || [];

  if (!token || permissionsFromObject.length < 1) {
    // notifyUserError('Missing token or roles permission');
    return { token: null, permissions: [] };
  }

  return { token: token, permissions: permissionsFromObject };
};
