import { ASCII_CHARS_REGEX, NUMBERS_REGEX } from 'constants/utils';

import dayjs from 'dayjs';
import {
  MSG_ASCII_CHARACTERS,
  MSG_FIELD_LESS_100_CHARS,
  MSG_FIELD_LESS_40_CHARS
} from 'utils/errorMessages';
import * as Yup from 'yup';

const zipCodeValidation = Yup.string()
  .nullable()
  .matches(NUMBERS_REGEX, 'Please use valid numbers only.')
  .test('len', 'Field must be 5 digits in length.', (val) => {
    if (val == null || val === '') {
      return true;
    }

    return val?.length === 5;
  });

export const dateValidation = Yup.string()
  .nullable()
  .test('dateTest', 'Date cannot be in the past.', (fieldVal) => {
    if (fieldVal == null || fieldVal === '') {
      return true;
    }
    const today = dayjs();
    const selectedDate = dayjs(fieldVal);
    const isStartDateInPast = selectedDate.isBefore(today, 'd');

    return !isStartDateInPast;
  });

export const addressSchema = Yup.object().shape({
  streetAddress: Yup.string()
    .nullable()
    .max(100, MSG_FIELD_LESS_100_CHARS)
    .matches(ASCII_CHARS_REGEX, MSG_ASCII_CHARACTERS),
  streetAddress2: Yup.string()
    .nullable()
    .max(100, MSG_FIELD_LESS_100_CHARS)
    .matches(ASCII_CHARS_REGEX, MSG_ASCII_CHARACTERS),
  city: Yup.string()
    .nullable()
    .matches(ASCII_CHARS_REGEX, MSG_ASCII_CHARACTERS)
    .max(40, MSG_FIELD_LESS_40_CHARS),
  state: Yup.string().nullable().matches(ASCII_CHARS_REGEX, MSG_ASCII_CHARACTERS),
  zipCode: zipCodeValidation,
  building: Yup.string()
    .nullable()
    .max(40, MSG_FIELD_LESS_40_CHARS)
    .matches(ASCII_CHARS_REGEX, MSG_ASCII_CHARACTERS),
  floor: Yup.string()
    .nullable()
    .matches(ASCII_CHARS_REGEX, MSG_ASCII_CHARACTERS)
    .max(40, MSG_FIELD_LESS_40_CHARS),
  room: Yup.string()
    .nullable()
    .matches(ASCII_CHARS_REGEX, MSG_ASCII_CHARACTERS)
    .max(40, MSG_FIELD_LESS_40_CHARS)
});

export const addressSchemaWithRequiredZip = addressSchema.concat(
  Yup.object({
    zipCode: zipCodeValidation.required('Zip Code is required.')
  })
);
