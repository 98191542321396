export const changeDataAndItemAtIndex = (arr: any, index: number) => {
  if (index < 0 || index >= arr.length) {
    throw new Error('Index is out of bounds');
  }
  for (let i = 0; i < index; i++) {
    arr[i] = { ...arr[i], orderNum: i + 1 };
  }
  for (let i = index + 1; i < arr.length; i++) {
    arr[i] = { ...arr[i], orderNum: i + 1 };
  }

  arr[index] = { ...arr[index], orderNum: index + 1 };

  return arr;
};
