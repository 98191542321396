import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { axiosPrivate } from 'modules/networkTools/networkTools';
import { convertLocalToUTC } from 'utils/dateHelpers/dateHelpers';

import { GoalsDTO, StatusDTO, TodosDTO } from './roadmapSlice';

const getDates = (
  date: string | null | undefined,
  endDate: string | null | undefined,
  startTime: string | null | undefined,
  endTime: string | null | undefined,
  hasDifferentEndDate: boolean
) => {
  if (!startTime || !endTime) {
    if (!date) {
      return { date: null, startDateTime: null, endDateTime: null };
    }
    return { date, startDateTime: null, endDateTime: null };
  }

  return {
    startDateTime: convertLocalToUTC(date ?? '', startTime),
    endDateTime: convertLocalToUTC((hasDifferentEndDate ? endDate : date) ?? '', endTime)
  };
};

export const getTodos: any = createAsyncThunk(
  'client/getTodos',
  async (
    { statuses, clientId }: { statuses: StatusDTO[] | StatusDTO; clientId: string },
    { rejectWithValue }
  ) => {
    const statusesFiltered =
      statuses && Array.isArray(statuses) && statuses.length > 0
        ? '?statuses=' + statuses.map((item) => `${item}`).join(',')
        : '';

    try {
      const response = await axiosPrivate.get(`client/${clientId}/todo${statusesFiltered}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getGoals: any = createAsyncThunk(
  'client/getGoals',
  async (
    { statuses, clientId }: { statuses: StatusDTO[] | StatusDTO; clientId: string },
    { rejectWithValue }
  ) => {
    const statusesFiltered =
      statuses && Array.isArray(statuses) && statuses.length > 0
        ? '?statuses=' + statuses.map((item) => `${item}`).join(',')
        : '';

    try {
      const response = await axiosPrivate.get(`client/${clientId}/goals${statusesFiltered}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createTodo: any = createAsyncThunk(
  'client/createTodo',
  async (
    {
      data,
      clientId,
      dataParent
    }: {
      data: TodosDTO & { hasDifferentEndDate: boolean; endDate: string | undefined };
      clientId: string;
      dataParent?: GoalsDTO;
    },
    { rejectWithValue }
  ) => {
    const dates = getDates(
      data.date,
      data.endDate,
      data.startTime,
      data.endTime,
      data.hasDifferentEndDate
    );
    try {
      const response = await axiosPrivate.post(`client/${clientId}/todo`, {
        ...data,
        ...dates
      });
      return { data: { ...response.data, orderNum: data.orderNum }, dataParent };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue({ error: error.response.data.errors, data });
      } else {
        return rejectWithValue({ error: error.message, data });
      }
    }
  }
);

export const createGoal: any = createAsyncThunk(
  'client/createGoal',
  async ({ data, clientId }: { data: GoalsDTO; clientId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(`client/${clientId}/goals`, data);
      return { data: { ...response.data, orderNum: data.orderNum } };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue({ error: error.response.data.errors, data });
      } else {
        return rejectWithValue({ error: error.message, data });
      }
    }
  }
);

export const updateTodo: any = createAsyncThunk(
  'client/updateTodo',
  async (
    {
      data,
      clientId,
      dataParent
    }: {
      data: TodosDTO & { hasDifferentEndDate: boolean; endDate: string | undefined };
      clientId: string;
      dataParent?: GoalsDTO;
    },
    { rejectWithValue }
  ) => {
    const dates = getDates(
      data.date,
      data.endDate,
      data.startTime,
      data.endTime,
      data.hasDifferentEndDate
    );
    try {
      const response = await axiosPrivate.put(`client/${clientId}/todo`, {
        ...data,
        ...dates
      });
      return { data: { ...response.data, orderNum: data.orderNum }, dataParent };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateGoal = createAsyncThunk(
  'client/updateGoal',
  async ({ data, clientId }: { data: GoalsDTO; clientId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`client/${clientId}/goals`, data);
      return { data: { ...response.data, orderNum: data.orderNum } };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTodoOrder: any = createAsyncThunk(
  'client/updateTodoOrder',
  async (
    {
      data,
      clientId,
      isTodo,
      dataParent
    }: { data: GoalsDTO; clientId: string; isTodo?: boolean; dataParent?: GoalsDTO },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(`client/${clientId}/todo/order`, data);
      return { data: { ...response.data, orderNum: data.orderNum }, isTodo, dataParent };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateGoalOrder: any = createAsyncThunk(
  'client/updateGoalOrder',
  async ({ data, clientId }: { data: GoalsDTO; clientId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`client/${clientId}/goals/order`, data);
      return { data: { ...response.data, orderNum: data.orderNum } };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
