import { FC } from 'react';

import style from './helpSection.module.scss';
interface Props {}

const HelpSection: FC<Props> = (props) => {
  return (
    <div className={style.helpSectionContainer}>
      <span>Need help?</span>{' '}
      <a href={'https://support.impactchw.com/hc/'} target="_blank" className={style.linkHelp}>
        Click here
      </a>
    </div>
  );
};

export default HelpSection;
