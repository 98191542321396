import clsx from 'clsx';
import React from 'react';

import style from './badge.module.scss';

interface BadgeProps {
  text: string;
  type?: 'default' | 'success' | 'warning';
  icon?: string;
  size?: 'small' | 'medium' | 'large';
}

const Badge: React.FunctionComponent<BadgeProps> = ({
  text,
  type = 'default',
  icon,
  size = 'medium'
}) => {
  return (
    <span
      className={clsx(
        style.defaultStyle,
        type === 'success' && style.badgeSuccess,
        type === 'warning' && style.badgeWarning,
        size === 'small' && style.small
      )}
    >
      {icon && <span className={clsx('material-icons')}>{icon}</span>}
      <p>{text}</p>
    </span>
  );
};

export default Badge;
