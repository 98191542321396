import { Button, ModalPopup, Title } from 'components';
import { FC, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import style from './helpButton.module.scss';
interface Props {}

const HelpButton: FC<Props> = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        icon={<span className="material-icons">contact_support</span>}
        type="button"
        customStyleClass={style.helpButton}
        handleOnClick={() => setOpen(true)}
      />
      <ModalPopup
        open={open}
        handleClose={() => setOpen(false)}
        customClassInsideContainer={style.modal}
        children={
          <div>
            <Title type="h3" text="Support" />
            <div className={style.content}>
              <p>
                <b>Phone:&nbsp;</b>
                <span>(831) 480-3625</span>
              </p>
              <p>
                <b>Email:&nbsp;</b>
                <a href="mailto: support@impactcarehq.com">support@impactcarehq.com</a>
                <CopyToClipboard text="support@impactcarehq.com">
                  <span>
                    <Button
                      icon={<span className="material-icons">content_copy</span>}
                      theme={'primary'}
                      customStyleClass={style.copyButton}
                    />
                  </span>
                </CopyToClipboard>
              </p>
              <p>
                <b>Help Center:&nbsp;</b>
                <a href="https://support.impactchw.com/hc/en-us">support.impactchw.com </a>
              </p>
            </div>
          </div>
        }
      />
    </>
  );
};

export default HelpButton;
