import { Button, Expander, Tooltip } from 'components';
import { FC, SyntheticEvent, useState } from 'react';
import clsx from 'clsx';
import { createTodoOrGoal, RoadmapStatus, updateStatuses } from 'store/roadmap/roadmapSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getGoals, getTodos } from 'store/roadmap/roadmapActions';
import { useParams } from 'react-router-dom';

import Roadmaps from './roadmaps';
import {
  getLsUserRoleObject,
  getRoadmapsFiltersObject,
  setRoadmapsFiltersObject
} from '../../networkTools/localStorageTokens';
import style from './../ourWorkExpanderSection.module.scss';

interface Props {}

const ExpanderRoadmap: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { clientId } = useParams();
  const userRoleData = getLsUserRoleObject();
  const { statusesFilter } = useAppSelector((state) => state.roadmap);
  const [isTodo, setIsTodo] = useState<boolean>(false);
  const [isExpandedRoadmap, setIsExpandedRoadmap] = useState<boolean>(
    getRoadmapsFiltersObject()?.length > 0
      ? getRoadmapsFiltersObject().find((a: any) => a.userId === userRoleData.userId)?.expander ??
          true
      : true
  );

  const isActiveFilter = statusesFilter.includes(RoadmapStatus.ACTIVE);
  const filterStatusesActive = isActiveFilter
    ? statusesFilter
    : [RoadmapStatus.ACTIVE, ...statusesFilter];

  const onExpand = (val: boolean) => {
    setIsExpandedRoadmap(val);
    const newObject = { expander: val, userId: userRoleData.userId };
    const existingIndex = getRoadmapsFiltersObject()?.findIndex(
      (obj: any) => obj.userId === newObject.userId
    );
    if (existingIndex !== -1) {
      const updatedRoadmapsFiltersObject = [...getRoadmapsFiltersObject()];
      updatedRoadmapsFiltersObject[existingIndex] = newObject;
      setRoadmapsFiltersObject(updatedRoadmapsFiltersObject);
    } else {
      setRoadmapsFiltersObject([...getRoadmapsFiltersObject(), newObject]);
    }
  };

  return (
    <Expander
      isExpanded={isExpandedRoadmap}
      onChange={(_, val) => onExpand(val)}
      customInsideComponent={
        <div className={style.iconsWrap} data-test-id="profile-roadmaps-actions">
          <div className={style.roadmapToggle}>
            <Tooltip toolTipText="To-do view" blackTooltip placement="bottom">
              <div
                onClick={(e: SyntheticEvent) => {
                  e.stopPropagation();
                  setIsTodo(true);
                }}
                className={clsx(style.toggleItem, isTodo && style.activeToggle)}
              >
                <span className={clsx('material-icons')}>task</span>
              </div>
            </Tooltip>
            <Tooltip toolTipText="Goal view" blackTooltip placement="bottom">
              <div
                onClick={(e: SyntheticEvent) => {
                  e.stopPropagation();
                  setIsTodo(false);
                }}
                className={clsx(style.toggleItem, !isTodo && style.activeToggle)}
              >
                <span className={clsx('material-icons')}>landscape</span>
              </div>
            </Tooltip>
          </div>
          <Tooltip
            toolTipText={isTodo ? 'Add a To-do' : 'Add a Goal'}
            blackTooltip
            placement="bottom"
          >
            <Button
              handleOnClick={(e: SyntheticEvent) => {
                e.stopPropagation();
                if (!isExpandedRoadmap) {
                  onExpand(true);
                }
                const action = isTodo ? getTodos : getGoals;
                if ((statusesFilter.length > 0 && isActiveFilter) || !statusesFilter.length) {
                  dispatch(action({ statuses: statusesFilter, clientId })).then((res: any) => {
                    if (!res.error) {
                      return dispatch(createTodoOrGoal({ isTodo }));
                    }
                  });
                }
                if (statusesFilter.length > 0 && !isActiveFilter) {
                  dispatch(updateStatuses(filterStatusesActive));
                  dispatch(action({ statuses: filterStatusesActive, clientId })).then(
                    (res: any) => {
                      if (!res.error) {
                        return dispatch(createTodoOrGoal({ isTodo }));
                      }
                    }
                  );
                }
              }}
              customStyleClass={style.iconAdd}
              icon={<span className={'material-icons'}>add</span>}
              theme="transparent"
            />
          </Tooltip>
        </div>
      }
      expanderBlue
      title={'Roadmaps'}
    >
      <Roadmaps isTodo={isTodo} />
    </Expander>
  );
};

export default ExpanderRoadmap;
