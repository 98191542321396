import { ComponentProps, useEffect, useRef } from 'react';
import ReactCodeInput from 'react-code-input';

import style from './codeInput.module.scss';

type Props = {
  onBlur: (e: FocusEvent) => void;
  fields: number;
  placeholder: string;
} & ComponentProps<typeof ReactCodeInput>;

const CodeInput = ({ onBlur, placeholder, ...props }: Props) => {
  const inputRef = useRef<any>();

  useEffect(() => {
    // react-code-input placeholder is not working so we have to set it through a ref
    // also it doesn't expose onBlur handler so we have to listen to the blur event on all the inputs
    if (inputRef.current !== null) {
      const allInputs = document.querySelectorAll('input');
      allInputs.forEach((node) => node.setAttribute('name', props.name));
      allInputs.forEach((node) => node.setAttribute('placeholder', placeholder));
      allInputs.forEach((node) => {
        node.addEventListener('blur', (e) => {
          onBlur(e);
        });
      });

      return () =>
        allInputs.forEach((node) => {
          node.removeEventListener('blur', () => {});
        });
    }
  }, []);

  return <ReactCodeInput ref={inputRef} className={style.codeInput} {...props} />;
};

export default CodeInput;
