import { createSlice } from '@reduxjs/toolkit';
import { notifyUserError, notifyUserSuccess } from 'utils/notifications';

import {
  createGoal,
  createTodo,
  getGoals,
  getTodos,
  updateGoal,
  updateGoalOrder,
  updateTodo,
  updateTodoOrder
} from './roadmapActions';

export enum RoadmapStatus {
  COMPLETED = 'COMPLETED',
  ABANDONED = 'ABANDONED',
  ACTIVE = 'ACTIVE'
}
export type StatusDTO = RoadmapStatus.ACTIVE | RoadmapStatus.COMPLETED | RoadmapStatus.ABANDONED;

interface CommonDTO {
  id?: number;
  title: string;
  orderNum: number;
  todos?: TodosDTO[];
  status: RoadmapStatus;
}

export interface TodosDTO extends CommonDTO {
  id?: number;
  title: string;
  orderNum: number;
  startDateTime?: string;
  endDateTime?: string;
  date?: string | null;
  startTime?: string | null;
  endTime?: string | null;
  recurringType?: string | null;
  separationCount?: number | null;
  goalId?: number | null;
  status: RoadmapStatus;
  location?: string;
}

export interface GoalsDTO extends CommonDTO {
  id?: number;
  title: string;
  orderNum: number;
  startDateTime?: string;
  endDateTime?: string;
  todos?: TodosDTO[];
  status: RoadmapStatus;
  date?: string | null;
}

export interface RolesSliceProps {
  statusesFilter: RoadmapStatus[];
  todosList: TodosDTO[];
  goalsList: GoalsDTO[];
}

export const AllStatuses = [RoadmapStatus.ACTIVE, RoadmapStatus.COMPLETED, RoadmapStatus.ABANDONED];

const initialState: RolesSliceProps = {
  todosList: [],
  goalsList: [],
  statusesFilter: [RoadmapStatus.ACTIVE]
};

const correctOrder = [RoadmapStatus.ACTIVE, RoadmapStatus.COMPLETED, RoadmapStatus.ABANDONED];

const filterAndSortItems = (
  items: (TodosDTO | GoalsDTO)[],
  statusesFilter: RoadmapStatus[],
  foundObject: TodosDTO | GoalsDTO
) => {
  const groupedItems: Record<RoadmapStatus, (TodosDTO | GoalsDTO)[]> = {
    [RoadmapStatus.ACTIVE]: [],
    [RoadmapStatus.COMPLETED]: [],
    [RoadmapStatus.ABANDONED]: []
  };

  const foundObjectStatus = foundObject.status;

  items.forEach((item) => {
    if (item === foundObject) {
      return;
    }
    if (statusesFilter.includes(item.status)) {
      groupedItems[item.status].push(item);
    }
  });

  groupedItems[foundObjectStatus].push(foundObject);

  const sortedItems: (TodosDTO | GoalsDTO)[] = [];
  correctOrder.forEach((status) => {
    if (statusesFilter.includes(status)) {
      sortedItems.push(...groupedItems[status]);
    }
  });

  let orderNum = 1;
  const indexedItems: (TodosDTO | GoalsDTO)[] = sortedItems.map((item) => ({
    ...item,
    orderNum: item === foundObject ? sortedItems.length : orderNum++
  }));

  return indexedItems;
};

const handleCreateRejected = (state: any, payload: any, isTodo: boolean) => {
  const arr = isTodo ? [...state.todosList] : [...state.goalsList];
  const index = arr.findIndex((obj) => obj.orderNum === payload.data.orderNum);
  if (index !== -1) {
    arr.splice(index, 1);
  }
  if (isTodo) {
    state.todosList = arr;
  } else {
    state.goalsList = arr;
  }
  notifyUserError(payload.error);
};

const handleCreate = (state: any, payload: any, isTodo: boolean) => {
  if (payload.dataParent?.status === RoadmapStatus.ACTIVE) {
    const arr = [...state.goalsList];
    const indexNew = arr.findIndex((obj) => obj.id === payload.dataParent.id);
    const todos = payload.dataParent.todos.map((todo: TodosDTO) => {
      if (todo.orderNum === payload.data.orderNum) {
        return payload.data;
      }
      return todo;
    });
    arr[indexNew] = { ...payload.dataParent, todos };
    state.goalsList = arr;
  }
  const arr = isTodo ? [...state.todosList] : [...state.goalsList];
  const index = arr.findIndex((obj) => obj.orderNum === payload.data.orderNum);
  if (index !== -1 && !payload.dataParent) {
    arr[index] = payload.data;
    if (isTodo) {
      state.todosList = arr;
    } else {
      state.goalsList = arr;
    }
  }
  notifyUserSuccess(isTodo ? 'New To-do created' : 'New Goal created');
};

const handleGet = (state: any, payload: any, isTodo: boolean) => {
  const listKey = isTodo ? 'todosList' : 'goalsList';

  state[listKey] = payload.map((item: any, index: number) => ({
    ...item,
    orderNum: index + 1
  }));
};

export const roadmapSlice = createSlice({
  name: 'roadmapSlice',
  initialState,
  reducers: {
    createTodoOrGoal: (state, action) => {
      const arr = action.payload.isTodo ? [...state.todosList] : [...state.goalsList];
      const filterStatuses = state.statusesFilter.length ? state.statusesFilter : AllStatuses;

      if (arr.find((a) => !a.title)) return;

      const newTodo = {
        title: '',
        status: RoadmapStatus.ACTIVE,
        orderNum: arr.length + 1
      };

      arr.push(newTodo);
      if (!action.payload.isTodo) {
        state.goalsList = filterAndSortItems(arr, filterStatuses, newTodo);
      }
      if (action.payload.isTodo) {
        state.todosList = filterAndSortItems(arr, filterStatuses, newTodo);
      }
    },
    createTodoInGoal: (state, action) => {
      const arr = [...state.goalsList];
      const filterStatuses = state.statusesFilter.length ? state.statusesFilter : AllStatuses;

      const index = arr.findIndex((obj) => obj.id === action.payload.id);
      const newTodo = {
        title: '',
        status: RoadmapStatus.ACTIVE,
        orderNum: arr.length + 1
      };
      const todos =
        action.payload.todos && action.payload.todos.length > 0 ? action.payload.todos : [];
      const newTodos = filterAndSortItems([...todos, newTodo], filterStatuses, newTodo);
      arr[index] = { ...action.payload, todos: newTodos };
      state.goalsList = arr;
    },
    revertUpdateTodoOrGoal: (state) => {
      const arrTodo = [...state.todosList];
      const arrGoal = [...state.goalsList];

      state.todosList = arrTodo;
      state.goalsList = arrGoal;
    },
    updateTodoOrGoal: (state, action) => {
      const arr = action.payload.isTodo ? [...state.todosList] : [...state.goalsList];
      const filterStatuses = state.statusesFilter.length ? state.statusesFilter : AllStatuses;
      const index = arr.findIndex((obj) => obj.id === action.payload.data.id);
      if (index !== -1) {
        let newArrGoal = action.payload.data;
        if (action.payload.data?.todos && action.payload.data.todos.length > 0) {
          newArrGoal = {
            ...newArrGoal,
            todos: action.payload.data.todos.filter((a: TodosDTO) => a.id)
          };
        }
        arr[index] = newArrGoal;

        if (!action.payload.isTodo) {
          state.goalsList = filterAndSortItems(arr, filterStatuses, newArrGoal);
        }
        if (action.payload.isTodo) {
          state.todosList = filterAndSortItems(arr, filterStatuses, action.payload.data);
        }
      }

      if (action.payload.dataParent) {
        const newArr = [...state.goalsList];
        const indexNew = newArr.findIndex((obj) => obj.id === action.payload.dataParent.id);

        const todos = action.payload.dataParent.todos
          .map((todo: TodosDTO) => {
            if (todo.id === action.payload.data.id) {
              return action.payload.data;
            }
            return todo;
          })
          .filter((todo: TodosDTO) => todo.goalId === action.payload.dataParent.id);
        const newTodos = todos.length ? todos : [];

        newArr[indexNew] = { ...action.payload.dataParent, todos: newTodos };

        const filteredTodos = newArr.findIndex((obj) => obj.id === action.payload.data.goalId);
        const filteredGoal = newArr[filteredTodos];
        if (filteredGoal && filteredGoal.todos) {
          const test = filteredGoal.todos.length > 0 ? filteredGoal.todos : [];
          const newArrFiltered = filterAndSortItems(
            [...test, action.payload.data],
            AllStatuses,
            action.payload.data
          );
          newArr[filteredTodos] = {
            ...filteredGoal,
            todos: newArrFiltered
          };
        }
        state.goalsList = newArr;
      }
    },
    updateTodoOrGoalList: (state, action) => {
      if (!action.payload.isTodo) {
        state.goalsList = action.payload.data;
      }
      if (action.payload.isTodo) {
        state.todosList = action.payload.data;
      }
    },
    deleteTodoOrGoal: (state, action) => {
      const isTodo = action.payload.isTodo;
      const listKey = isTodo ? 'todosList' : 'goalsList';
      const arr = [...state[listKey]];

      if (action.payload.dataParent) {
        const { dataParent, orderNum } = action.payload;
        const indexParent = arr.findIndex((obj) => obj.id === dataParent.id);

        if (indexParent !== -1) {
          const todos = [...dataParent.todos];
          const indexToDelete = todos.findIndex((item) => item.orderNum === orderNum);

          if (indexToDelete !== -1) {
            todos.splice(indexToDelete, 1);

            todos.forEach((item, index) => {
              return { ...item, orderNum: index + 1 };
            });

            arr[indexParent] = { ...dataParent, todos };
            state[listKey] = arr;
          }
        }
      } else {
        const indexToDelete = arr.findIndex((item) => item.orderNum === action.payload.orderNum);

        if (indexToDelete !== -1) {
          arr.splice(indexToDelete, 1);

          arr.forEach((item, index) => ({ ...item, orderNum: index + 1 }));

          state[listKey] = arr;
        }
      }
    },
    updateStatuses: (state, action) => {
      state.statusesFilter = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTodos.fulfilled, (state: any, { payload }: any) => {
        handleGet(state, payload, true);
      })
      .addCase(getTodos.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getGoals.fulfilled, (state: any, { payload }: any) => {
        handleGet(state, payload, false);
      })
      .addCase(getGoals.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })

      .addCase(updateTodo.fulfilled, (state: any, { payload }: any) => {
        const arr = payload.dataParent ? [...state.goalsList] : [...state.todosList];
        const index = arr.findIndex((obj) => obj.id === payload.data.id);

        let message: string | null;
        let todoInGoalName = false;
        if (payload.dataParent?.status === RoadmapStatus.ACTIVE) {
          const indexNew = arr.findIndex((obj) => obj.id === payload.dataParent.id);
          const todos = payload.dataParent.todos.map((todo: TodosDTO) => {
            if (todo.id === payload.data.id) {
              return payload.data;
            }
            return todo;
          });
          const foundStatus = payload.dataParent.todos.find(
            (obj: TodosDTO) => obj.id === payload.data.id
          );
          todoInGoalName = payload.data.status !== foundStatus.status;

          arr[indexNew] = { ...payload.dataParent, todos };
          state.goalsList = arr;
        }

        switch (payload.data.status) {
          case RoadmapStatus.COMPLETED:
            message = `To-do was completed.`;
            break;
          case RoadmapStatus.ABANDONED:
            message = `To-do was abandoned.`;
            break;
          case RoadmapStatus.ACTIVE:
            message =
              payload.data.id &&
              payload.data.status !== arr[index]?.status &&
              `To-do was reactivated.`;
            if (!arr[index] && payload.dataParent && todoInGoalName) {
              message = `To-do was reactivated.`;
            }
            if (!arr[index] && payload.dataParent && !todoInGoalName) {
              message = null;
            }

            break;
          default:
            message = null;
        }

        if (index !== -1 && !payload.dataParent) {
          arr[index] = payload.data;
          state.todosList = arr;
        }

        if (message) {
          notifyUserSuccess(message);
        }
      })
      .addCase(updateTodo.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(updateGoal.fulfilled, (state: any, { payload }: any) => {
        const arr = [...state.goalsList];
        const index = arr.findIndex((obj) => obj.id === payload.data.id);
        let message: string | null;
        switch (payload.data.status) {
          case RoadmapStatus.COMPLETED:
            message =
              payload.data.todos?.length > 0
                ? 'Goal and To-dos were completed.'
                : `Goal was completed.`;
            break;
          case RoadmapStatus.ABANDONED:
            message =
              payload.data.todos?.length > 0
                ? 'Goal and To-dos were abandoned.'
                : `Goal was abandoned.`;
            break;
          case RoadmapStatus.ACTIVE:
            message =
              payload.data.id &&
              payload.data.status !== arr[index].status &&
              (payload.data.todos?.length > 0
                ? 'Goal and To-dos were reactivated.'
                : `Goal was reactivated.`);

            if (arr[index] && payload.data.title !== arr[index].title) {
              message = null;
            }
            break;
          default:
            message = null;
        }
        if (message) {
          notifyUserSuccess(message);
        }

        if (index !== -1) {
          arr[index] = payload.data;
        }
        state.goalsList = arr;
      })
      .addCase(updateGoal.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })

      .addCase(createTodo.fulfilled, (state: any, { payload }: any) => {
        handleCreate(state, payload, true);
      })
      .addCase(createTodo.rejected, (state: any, { payload }: any) => {
        handleCreateRejected(state, payload, true);
      })
      .addCase(createGoal.fulfilled, (state: any, { payload }: any) => {
        handleCreate(state, payload, false);
      })
      .addCase(createGoal.rejected, (state: any, { payload }: any) => {
        handleCreateRejected(state, payload, false);
      })
      .addCase(updateTodoOrder.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(updateGoalOrder.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      });
  }
});

export const {
  createTodoOrGoal,
  updateTodoOrGoal,
  deleteTodoOrGoal,
  updateTodoOrGoalList,
  updateStatuses,
  createTodoInGoal,
  revertUpdateTodoOrGoal
} = roadmapSlice.actions;
export default roadmapSlice.reducer;
