import { ClickAwayListener, Popper } from '@mui/material';
import { ReactElement, useState } from 'react';

import style from './todoPopper.module.scss';

type Props = {
  children({ onClose }: { onClose: () => void }): ReactElement;
  icon: string;
};

const TodoPopper = ({ children, icon }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const onClose = () => {
    setAnchorEl(null);
  };

  const onClickAway = (e: MouseEvent | TouchEvent) => {
    // for some reason when clicking on Dropdown component inside the popper
    // it register as a click on body element, hence this check
    if (e.target instanceof HTMLBodyElement) {
      return;
    }
    onClose();
  };

  return (
    <>
      <div onClick={(e) => setAnchorEl(e.currentTarget)} className="material-icons">
        {icon}
      </div>
      <Popper anchorEl={anchorEl} open={Boolean(anchorEl)}>
        <ClickAwayListener onClickAway={onClickAway}>
          <div className={style.popper}>{children({ onClose })}</div>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default TodoPopper;
