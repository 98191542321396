import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { axiosPrivate } from '../../modules/networkTools/networkTools';
import { User, UserProfilePhoto } from './profileSlice';

export const getUserInfo = createAsyncThunk(
  'profile/getUserInfo',
  async (organizationId: number | null, { rejectWithValue }) => {
    const id = organizationId ? `?organizationId=${organizationId}` : '';
    const url = `setup/organization/user/profile${id}`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putUpdateUserInfo = createAsyncThunk(
  'profile/putUpdateUserInfo',
  async (data: User, { rejectWithValue }) => {
    const url = `setup/organization/user${data.phoneNumber ? '?phone=' + data.phoneNumber : ''}`;
    try {
      const formConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };
      const bodyFormData = new FormData();
      bodyFormData.append('image', data.profileImageKey);

      const response = await axiosPrivate.patch(url, bodyFormData, formConfig);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchUserPhoto = createAsyncThunk(
  'profile/patchUserPhoto',
  async (data: UserProfilePhoto, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('image', data.file);
      const axiosConfig = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      };

      const response = await axiosPrivate.patch(`setup/organization/user`, formData, axiosConfig);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUserInfoImage = createAsyncThunk(
  'profile/getUserInfoImage',
  async (key: string, { rejectWithValue }) => {
    const url = `setup/organization/user/profile-image?key=${key}`;
    try {
      const response = await axiosPrivate.get(url, { responseType: 'blob' });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
