import Modal from '@mui/material/Modal';
import clsx from 'clsx';
import * as React from 'react';

import style from './modal.module.scss';

interface ModalProps {
  keepMounted?: boolean;
  customClassInsideContainer?: any;
  customClassOutsideContainer?: any;
  handleClose?: any;
  open: boolean;
  children: JSX.Element;
}

const ModalPopup: React.FunctionComponent<ModalProps> = ({
  keepMounted = false,
  customClassInsideContainer,
  customClassOutsideContainer,
  open,
  handleClose,
  children
}) => {
  return (
    <>
      <Modal
        keepMounted={keepMounted}
        className={clsx(style.defaultModalMainContainer, customClassOutsideContainer)}
        open={open}
        aria-labelledby="parent-modal"
        aria-describedby="parent-modal-description"
        onClose={(event, reason) => {
          //Prevent closing on outside click
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleClose();
          }
        }}
      >
        <div className={clsx(style.defaultModalInsideContainer, customClassInsideContainer)}>
          <div className={style.modalContentContainer}>
            {handleClose && (
              <div className={clsx('closeIconContainer', style.closeIcon)} onClick={handleClose}>
                <span className={clsx('material-icons', 'closeIcon')}>close</span>
              </div>
            )}
            {React.cloneElement(children, { onClose: handleClose })}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalPopup;
