import { Badge, Stack, Title } from 'components';
import { ClientProfileContentItem } from 'store/clientProfile/clientProfileSlice';
import { Client, noteTypeData } from 'store/clients/clientsSlice';

import style from './noteHeader.module.scss';

type Props = {
  note: ClientProfileContentItem;
  client: Client;
};

const NoteHeader = ({ note, client }: Props) => {
  const noteTypeName = noteTypeData.find((n) => n.id === note.noteType)?.name;

  return (
    <div className={style.header}>
      <Stack display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack gap="8px" display="flex" flexDirection="row">
          <Title text={noteTypeName ?? 'Note'} type="h3" />
          {note.draft && <Badge size="small" text="Draft" type="warning" />}
        </Stack>
        <Stack display="flex" flexDirection="row" alignItems="center" gap="12px">
          <Title text={`${client?.firstName} ${client?.lastName}`} type="h3" />
          <p className={style.impactId}>IMPaCT ID: {client?.prependedId}</p>
        </Stack>
      </Stack>
    </div>
  );
};

export default NoteHeader;
