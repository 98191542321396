import notesBgr from 'assets/png/notesBgr.png';
import { useEffect, useState } from 'react';
import { getClientProfileImg } from 'store/clients/clientsActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
export const useSetClientImage = (clientId: string) => {
  const [profileImgUrl, setProfileImgUrl] = useState<string | undefined>();
  const dispatch = useAppDispatch();
  const clientProfileImage = useAppSelector(
    (state) => state.clients?.client?.data?.profileImageKey
  );

  useEffect(() => {
    const getClientPhotoFromApi = async () => {
      if (clientProfileImage) {
        const response = await dispatch(
          getClientProfileImg({ fileName: clientProfileImage, clientId })
        );
        const imgData = URL.createObjectURL(new Blob([response?.payload]));

        setProfileImgUrl(imgData || notesBgr);
      } else {
        setProfileImgUrl(notesBgr);
      }
    };

    getClientPhotoFromApi();
  }, [clientProfileImage]);

  return profileImgUrl;
};
