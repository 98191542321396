import { createSlice } from '@reduxjs/toolkit';
import uuid from 'react-uuid';

import { notifyUserError } from '../../utils/notifications';
import {
  getCandidate,
  getCandidateCV,
  getCandidateVideo,
  postCandidateRegistration,
  putCandidatePaid,
  putCandidateStatus
} from './candidateActions';

export interface ReferenceDTO {
  name: string;
  company: string;
  relationship: string;
  phoneOrEmail: string;
}

export interface CandidateProfileDTO {
  name: string;
  phone: string;
  email: string;
  status?: string;
  educationList?: string[];
  workExperienceList?: string[];
  background?: string;
  references: ReferenceDTO[];
  areVaccinatedAgainstCovid?: boolean;
  areComfortableVisitingPeople?: boolean;
  videoExtension?: string;
  cvExtension?: string;
  cvFileName?: string;
  videoFileName?: string;
}
export interface CandidateSliceProps {
  candidateProfile: CandidateProfileDTO | null;
  candidateProfileView: CandidateProfileDTO | null;
  candidateId: string | null;
  candidateFormSectionApplyValid: boolean;
  candidateFormReferencesValid: boolean;
}
const initialReference = {
  name: '',
  company: '',
  relationship: '',
  phoneOrEmail: ''
};
const freshProfile = {
  name: '',
  phone: '',
  email: '',
  background: '',
  references: [initialReference, initialReference]
};
const initialState: CandidateSliceProps = {
  candidateProfile: freshProfile,
  candidateProfileView: null,
  candidateFormSectionApplyValid: false,
  candidateFormReferencesValid: false,
  candidateId: null
};

export const candidateSlice = createSlice({
  name: 'candidateSlice',
  initialState,
  reducers: {
    updateCandidateData: (state, action) => {
      state.candidateProfile = { ...state.candidateProfile, ...action.payload };
    },
    createCandidateID: (state) => {
      const candidateId = uuid();
      state.candidateId = candidateId;
    },

    resetCandidateView: (state, action) => {
      state.candidateProfileView = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCandidateRegistration.fulfilled, (state: any, { payload }: any) => {
        state.candidateProfile = payload;
      })
      .addCase(postCandidateRegistration.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCandidate.fulfilled, (state: any, { payload }: any) => {
        state.candidateProfileView = payload;
      })
      .addCase(getCandidate.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putCandidateStatus.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putCandidatePaid.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCandidateVideo.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getCandidateCV.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      });
  }
});

export const { updateCandidateData, createCandidateID, resetCandidateView } =
  candidateSlice.actions;
export default candidateSlice.reducer;
