import { LOGIN_PAGE_URL } from 'constants/utils';

import axios from 'axios';

import {
  deleteLsBothTokens,
  deleteLsFeaturesEnrollmentPlanner,
  deleteLsUserRoleObject,
  deleteReportingFilters,
  getLsAccessToken
} from './localStorageTokens';
import { apiRefreshToken } from './useRefreshToken';

const userAccessTokenInitial = getLsAccessToken();
const timezoneOffset = Math.floor(Number(new Date().getTimezoneOffset()) / 60);

export const BACKEND_API_EXTENSION = 'core/';

export const axiosPublic = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + BACKEND_API_EXTENSION,
  headers: {
    'x-time-zone-offset': timezoneOffset
  }
});
export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + BACKEND_API_EXTENSION,
  headers: { Authorization: `Bearer ${userAccessTokenInitial}` }
});
//initial req, when authorization header is not set
axiosPrivate.interceptors.request.use(
  (config: any) => {
    //TODO will be changed with sso
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${getLsAccessToken()}`,
      'x-time-zone-offset': timezoneOffset
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const logout = () => {
  deleteLsBothTokens();
  deleteLsUserRoleObject();
  deleteReportingFilters();
  deleteLsFeaturesEnrollmentPlanner();
  window.location.href = `${window.location.origin + LOGIN_PAGE_URL}`;
};

axiosPrivate.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error?.config;

    //is 401 and  prev request was not sent to prevent loop - retry only once
    // if (false && !originalRequest?.sent) {
    if (error?.response?.status === 401 && !originalRequest?.sent) {
      originalRequest.sent = true;

      const userAccessToken = getLsAccessToken();
      const newAccessToken = await apiRefreshToken(userAccessToken);

      if (!newAccessToken) {
        // logout();

        return Promise.reject(error);
      }

      originalRequest.headers = {
        ...originalRequest.headers,
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${newAccessToken}`
      };
      return axiosPrivate(originalRequest);
    }

    // if after the token refresh, the api still returns 401,
    // we want to logout the user
    if (error?.response?.status === 401 && originalRequest?.sent) {
      logout();
    }

    return Promise.reject(error);
  }
);
