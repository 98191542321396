import { toast } from 'react-toastify';

import { components } from '../../api/generated-types';

export const notifyUserSuccess = (message: string) => {
  return toast.success(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    toastId: 'success-notification'
  });
};

export const notifyUserInfo = (message: string) => {
  return toast.info(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    toastId: 'success-notification'
  });
};

export const notifyUserError = (
  errorMessage: components['schemas']['ErrorListResponse']['errors'] | string | undefined,
  keepOpen?: boolean
) => {
  let error: string | undefined;
  if (typeof errorMessage === 'string') {
    error = errorMessage;
  } else if (errorMessage === undefined) {
    error = 'Something went wrong';
  } else if (Array.isArray(errorMessage)) {
    const errorMessagesFromArray = errorMessage.map(function (err) {
      return err.message;
    });

    error = errorMessagesFromArray?.join(' ');
  }

  return toast.error(error, {
    position: 'top-right',
    autoClose: keepOpen ? false : 5000,
    hideProgressBar: true,
    closeOnClick: keepOpen ? true : false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    toastId: 'error-notification'
  });
};
