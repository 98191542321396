import { Avatar } from '@mui/material';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { noteTypeData } from 'store/clients/clientsSlice';

import type { ClientProfileContentItem } from 'store/clientProfile/clientProfileSlice';

import style from './note.module.scss';

type Props = {
  note: ClientProfileContentItem;
};

const Note = ({ note }: Props) => {
  const { clientId } = useParams();
  const openNoteWindow = () => {
    window.open(
      `/clients/edit/${clientId}/notes/${note.id}?context=popup`,
      String(note.id),
      `width=700,height=400,left=${window.screen.availWidth / 2 - 200},top=${
        window.screen.availHeight / 2 - 150
      }`
    );
  };

  return (
    <div className={style.note} onClick={openNoteWindow}>
      <div className={style.noteRow}>
        <p>
          {noteTypeData.find((noteType) => noteType.id === note?.noteType)?.name ?? 'Note'}{' '}
          {note?.draft && <span className={style.draft}>Draft</span>}
          {/* {note?.id}
          {note?.draft} */}
        </p>
        <span className={style.date} data-timestamp={note?.createdAt}>
          {dayjs(note?.createdAt).format('MM/DD/YYYY')}
        </span>
      </div>
      <div className={style.avatarRow}>
        <Avatar
          className={style.avatar}
          alt={note?.author?.profileImageKey}
          src={note?.author?.profileImageUrl}
        >
          <span className="material-icons-outlined">person</span>
        </Avatar>
        <div className={style.avatarName}> {note?.author?.name}</div>
        {note.hasAddendums && (
          <span className={clsx('material-icons', style.edit)} onClick={() => null}>
            drive_file_rename_outline
          </span>
        )}
      </div>
    </div>
  );
};

export default Note;
