import { Button, Stack } from 'components';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { getAddendums, getUsersImageNotes } from 'store/clientProfile/clientProfileActions';
import {
  Addendum as AddendumType,
  ClientProfileContentItem
} from 'store/clientProfile/clientProfileSlice';
import { clientReachedData, noteTypeData, typesOfSupportData } from 'store/clients/clientsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { Addendum } from './addendum';
import { AddendumForm } from './addendumForm';
import style from './noteView.module.scss';

type Props = {
  note: ClientProfileContentItem;
};

const NoteView = ({ note }: Props) => {
  const addendums = useAppSelector((state) => state.clientProfile.addendums.data);
  const [showAddendum, setShowAddendum] = useState(false);
  const noteType = noteTypeData.find((n) => n.id === note.noteType);
  const clientReached = clientReachedData.find((n) => n.id === note.clientReached);
  // const typeOfSupport = note.typeOfSupport.map(
  //   (t) => typesOfSupportData.find((n) => n.id === t)?.name
  // );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAddendums({ noteId: note.id.toString() })).then((res: any) => {
      if (!res.error && res.payload.content.length > 0) {
        //TODO need to optimize
        res.payload.content.map((addendum: AddendumType) => {
          if (addendum.author.profileImageKey) {
            dispatch(
              getUsersImageNotes({
                profileImageKey: addendum.author.profileImageKey,
                noteId: addendum.id.toString(),
                noteAddendums: true
              })
            );
          }
        });
      }
    });
  }, []);

  const sortValues = (val: string[] | string, sortOrder: any[]) => {
    if (Array.isArray(val)) {
      return sortOrder
        .filter((item) => val.includes(item.id.toString()))
        .map((item) => item.name)
        .join(', ');
    } else {
      return val;
    }
  };

  return (
    <Stack mt="36px" display="flex" gap="28px">
      <Stack display="flex" flexDirection="row">
        <Stack display="flex" gap="8px" flex="1">
          <p className={style.label}>Note Type:</p>
          <p className={style.text}>{noteType?.name}</p>
        </Stack>
        <Stack display="flex" gap="8px" flex="1">
          <p className={style.label}>Client Reached:</p>
          <p className={style.text}>{clientReached?.name}</p>
        </Stack>
      </Stack>
      <Stack display="flex" flexDirection="row">
        <Stack display="flex" gap="8px" flex="1">
          <p className={style.label}>Date of Encounter:</p>
          <p className={style.text}>{dayjs(note?.dateOfEncounter).format('MM/DD/YYYY')}</p>
        </Stack>
        <Stack display="flex" gap="8px" flex="1">
          <p className={style.label}>Next Contact:</p>
          {dayjs(note.nextContact).isValid() ? dayjs(note?.nextContact).format('MM/DD/YYYY') : ''}
        </Stack>
      </Stack>
      <Stack display="flex" flexDirection="row">
        <Stack display="flex" gap="8px" flex="1">
          <p className={style.label}>Time Spent:</p>
          <p className={style.text}>{note.timeSpent} Mins</p>
        </Stack>
        <Stack display="flex" gap="8px" flex="1">
          <p className={style.label}>Type of Support:</p>
          <p className={style.text}>{sortValues(note.typeOfSupport, typesOfSupportData)}</p>
        </Stack>
      </Stack>
      <Stack display="flex" flexDirection="row">
        <Stack display="flex" gap="8px" flex="1">
          <p className={style.label}>Note:</p>
          <p className={style.text}>{note.note}</p>
        </Stack>
      </Stack>
      {addendums.map((addendum) => (
        <Addendum key={addendum.id} addendum={addendum} />
      ))}
      {!showAddendum && (
        <Stack width="200px">
          <Button
            handleOnClick={() => setShowAddendum(true)}
            theme="transparent"
            text="Add Addendum"
            icon={<span className="material-icons">add</span>}
          />
        </Stack>
      )}
      {showAddendum && <AddendumForm editInitial={true} hideForm={() => setShowAddendum(false)} />}
    </Stack>
  );
};

export default NoteView;
