import { Avatar } from '@mui/material';
import { Stack } from 'components';

import style from './avatarQuote.module.scss';

type Props = {
  text: string;
  src: string;
};

const AvatarQuote = ({ text, src }: Props) => {
  return (
    <Stack
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
    >
      <Stack display="flex" flexDirection="row" alignItems="center" gap="16px">
        <div className={style.avatar}>
          <Avatar
            sx={{
              width: 64,
              height: 64
            }}
            src={src}
          />
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9.9895" cy="9.48975" r="8.5" fill="white" stroke="#E2E8F0" />
            <path
              d="M7.17204 12.4066C7.46954 12.4066 7.74371 12.2374 7.87204 11.9749L8.70038 10.3182C8.78204 10.1549 8.82288 9.97991 8.82288 9.79908V7.15658C8.82288 6.83574 8.56038 6.57324 8.23954 6.57324H5.90621C5.58538 6.57324 5.32288 6.83574 5.32288 7.15658V9.48991C5.32288 9.81074 5.58538 10.0732 5.90621 10.0732H7.07288L6.47204 11.2749C6.20954 11.7941 6.58871 12.4066 7.17204 12.4066ZM13.0054 12.4066C13.3029 12.4066 13.577 12.2374 13.7054 11.9749L14.5337 10.3182C14.6154 10.1549 14.6562 9.97991 14.6562 9.79908V7.15658C14.6562 6.83574 14.3937 6.57324 14.0729 6.57324H11.7395C11.4187 6.57324 11.1562 6.83574 11.1562 7.15658V9.48991C11.1562 9.81074 11.4187 10.0732 11.7395 10.0732H12.9062L12.3054 11.2749C12.0429 11.7941 12.422 12.4066 13.0054 12.4066Z"
              fill="#188AA1"
            />
          </svg>
        </div>
        <p className={style.quoteText}>{text}</p>
      </Stack>
      <svg
        width="27"
        height="18"
        viewBox="0 0 27 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.45008 17.3334C6.30008 17.3334 7.08341 16.8501 7.45008 16.1001L9.81675 11.3667C10.0501 10.9001 10.1667 10.4001 10.1667 9.88341V2.33341C10.1667 1.41675 9.41675 0.666748 8.50008 0.666748H1.83341C0.916748 0.666748 0.166748 1.41675 0.166748 2.33341V9.00008C0.166748 9.91675 0.916748 10.6667 1.83341 10.6667H5.16675L3.45008 14.1001C2.70008 15.5834 3.78341 17.3334 5.45008 17.3334ZM22.1167 17.3334C22.9667 17.3334 23.7501 16.8501 24.1167 16.1001L26.4834 11.3667C26.7167 10.9001 26.8334 10.4001 26.8334 9.88341V2.33341C26.8334 1.41675 26.0834 0.666748 25.1667 0.666748H18.5001C17.5834 0.666748 16.8334 1.41675 16.8334 2.33341V9.00008C16.8334 9.91675 17.5834 10.6667 18.5001 10.6667H21.8334L20.1167 14.1001C19.3667 15.5834 20.4501 17.3334 22.1167 17.3334Z"
          fill="#B6E3EB"
        />
      </svg>
    </Stack>
  );
};

export default AvatarQuote;
