import clsx from 'clsx';
import React from 'react';

import EnrollmentGoalIcon from '../icon/enrollmentGoalIcon';
import style from './line.module.scss';

interface EnrollmentGoalTrackerProps {
  zIndex: number;
  percentage?: number;
  positionY: number;
  iconPositionY: number;
  text: string | number | any;
  bgrColor: string;
  toolTipText: string;
  disableHints?: boolean;
}

const Line: React.FunctionComponent<EnrollmentGoalTrackerProps> = ({
  zIndex,
  positionY,
  iconPositionY,
  percentage,
  bgrColor,
  text,
  toolTipText,
  disableHints
}) => {
  return (
    <div
      className={clsx(style.progressValue)}
      style={{
        width: `${percentage}%`,
        background: bgrColor,
        top: `${positionY}px`,
        zIndex: zIndex
      }}
    >
      <EnrollmentGoalIcon
        toolTipText={toolTipText}
        zIndex={zIndex}
        text={text || ''}
        positionY={iconPositionY}
        positionX={95}
        disableHints={disableHints}
      />
    </div>
  );
};

export default Line;
