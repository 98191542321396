import { NUMBERS_REGEX } from 'constants/utils';

import {
  TableBody,
  TableCell,
  TableContainer,
  Table as TableMaterial,
  TableRow
} from '@mui/material';
import { Button, InputField } from 'components';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { FC, useEffect } from 'react';
import {
  getEnrollmentGoalsTemplate,
  putEnrollmentProgram
} from 'store/enrollment/enrollmentActions';
import { EnrollmentNotConfigureProgramDTO } from 'store/enrollment/enrollmentSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as Yup from 'yup';

import style from './goalModal.module.scss';

interface Props {
  program: EnrollmentNotConfigureProgramDTO;
  programsLength?: number;
  step: number;
  backStep: () => void;
  forwardStep: () => void;
  handleClose: () => void;
}

const FormGoalModal: FC<Props> = ({
  program,
  programsLength,
  step,
  backStep,
  forwardStep,
  handleClose
}) => {
  const dispatch = useAppDispatch();
  const { enrollmentTemplate } = useAppSelector((state) => state.enrollment);

  useEffect(() => {
    if (!enrollmentTemplate) {
      dispatch(getEnrollmentGoalsTemplate());
    }
  }, [enrollmentTemplate]);

  const {
    handleChange,
    handleBlur,
    values,
    handleSubmit,
    errors,
    touched,
    resetForm,
    isValid: isFormValid,
    dirty: isFormTouched
  } = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    initialValues: { ...program, enrollmentGoal: program.enrollmentGoal || '' },
    validationSchema: Yup.object({
      enrollmentGoal: Yup.string()
        .nullable()
        .test(
          'validateEnrollGoalField',
          'Please use whole numbers greater than zero and less than 100,000', // error message
          (value: any, context: any) => {
            // allow empty field
            if (typeof value === 'undefined') {
              return true;
            }
            // allow only whole numbers
            const digitsOnlyCondition = !NUMBERS_REGEX.test(value);

            if (digitsOnlyCondition) {
              return false;
            }

            const stringToNumber = Number(value);
            // which are in between 1 and 100k
            if (stringToNumber < 1 || stringToNumber >= 100000) {
              return false;
            }

            return true;
          }
        )
    }),
    onSubmit: async (val: EnrollmentNotConfigureProgramDTO) => {
      if (isFormValid && isFormTouched) {
        dispatch(
          putEnrollmentProgram({
            data: { programId: val.programId, enrollmentGoal: val.enrollmentGoal || null },
            programId: val.programId
          })
        );
      }
    }
  });

  useEffect(() => {
    if (program) {
      resetForm();
    }
  }, [program]);

  return (
    <div className={style.content}>
      <form>
        <div className={style.title}>
          Set an annual enrollment goal
          <div
            className={style.closeIcon}
            onClick={(e: any) => {
              e.preventDefault();
              handleSubmit(e);
              handleClose();
            }}
          >
            <span className="material-icons">close</span>
          </div>
        </div>
        <div className={style.inputItem}>
          <div className={style.subTitle}>
            {program.programName}{' '}
            {programsLength && (
              <div className={style.count}>
                ({step - 1}/{programsLength})
              </div>
            )}
          </div>
          <InputField
            name="enrollmentGoal"
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSubmit();
                forwardStep();
              }
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.enrollmentGoal}
            type="text"
            placeholder="Enter number here"
            min="0"
            max="100000"
          />
          {touched.enrollmentGoal && errors.enrollmentGoal ? (
            <div className={style.fieldError}>{errors.enrollmentGoal}</div>
          ) : null}
          <div className={style.helpText}>
            A good rule of thumb is for each CHW to enroll 75 clients per year. If you want to get
            fancier,{' '}
            <a href={enrollmentTemplate} download="EnrollmentPlanningTool.xlsx">
              this template
            </a>{' '}
            can help.
          </div>
        </div>
        {program?.history && program?.history.length > 0 && (
          <div className={style.history}>
            <div className={style.historyTitle}>Goal history:</div>
            <TableContainer>
              <TableMaterial>
                <TableBody>
                  {program.history.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        Annual Program Enrollment Goal:{' '}
                        <div className={style.counter}>{item.enrollmentGoal || 'Cleared'}</div>
                      </TableCell>
                      <TableCell>
                        <div className={style.info}>
                          <div className={style.date}>
                            {item.createdAt && dayjs(item.createdAt).format('MM/DD/YYYY')}
                          </div>
                          <div className={style.subName}>{item.user}</div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </TableMaterial>
            </TableContainer>
          </div>
        )}
      </form>
      <div className={style.buttonsWrap}>
        <Button
          handleOnClick={(e) => {
            handleSubmit(e);
            backStep();
          }}
          type="button"
          theme="secondary"
          icon={<span className="material-icons">arrow_back_ios</span>}
        />
        <Button
          handleOnClick={(e) => {
            handleSubmit(e);
            forwardStep();
          }}
          type="button"
          theme="secondary"
          icon={<span className="material-icons">arrow_forward_ios</span>}
        />
      </div>
    </div>
  );
};

export default FormGoalModal;
