import { createSlice } from '@reduxjs/toolkit';
import { notifyUserError, notifyUserSuccess } from 'utils/notifications';

import {
  deleteAddendum,
  deleteNoteInput,
  getAddendums,
  getClientNote,
  getClientNotes,
  getUsersImageNotes,
  patchEditAddendum,
  postCreateAddendum,
  putEditAddendum
} from './clientProfileActions';

export interface ClientProfileContentItem {
  createdBy: number;
  createdAt: string;
  updatedBy: number;
  updatedAt: string;
  id: number;
  noteType: string;
  clientReached: string;
  hasAddendums: boolean;
  dateOfEncounter: string;
  nextContact: string;
  timeSpent: number;
  typeOfSupport: string[];
  note: string;
  author: {
    id: number;
    name: string;
    profileImageKey: string;
    profileImageUrl?: string;
  };
  draft: boolean;
}

type Status = 'DRAFT' | 'FINAL';

type Author = {
  id: number;
  name: string;
  profileImageKey: string;
  profileImageUrl?: string;
};

export type Addendum = {
  id: number;
  noteId: number;
  content: string;
  status: Status;
  author: Author;
  createdBy: number;
  createdAt: string;
  updatedBy: number;
  updatedAt: string;
};

export interface ClientProfileSliceProps {
  clientProfileList: {
    notesList: ClientProfileContentItem[];
    page: number;
    size: number;
    totalNumber: number;
    loading: boolean;
  };
  clientNotes: {
    [key: string]: {
      loading: boolean;
      data: ClientProfileContentItem | null | undefined;
    };
  };

  addendums: {
    data: Addendum[];
    loading: boolean;
  };
}

const initialState: ClientProfileSliceProps = {
  clientProfileList: {
    notesList: [],
    page: 0,
    size: 2,
    totalNumber: 0,
    loading: false
  },
  clientNotes: {},
  addendums: {
    data: [],
    loading: false
  }
};

export const clientProfileSlice = createSlice({
  name: 'clientProfileSlice',
  initialState,
  reducers: {
    updateNotesListSearchParams: (state, action) => {
      state.clientProfileList = { ...action.payload };
    },
    setFilterOnNotesList: (state, action) => {
      state.clientProfileList.notesList = [...action.payload];
    },
    resetClientNotes: (state) => {
      state.clientProfileList = {
        notesList: [],
        page: 0,
        size: 2,
        totalNumber: 0,
        loading: false
      };
    },
    removeNote: (state) => {
      state.clientNotes = {};
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientNotes.pending, (state, { payload }: any) => {
        state.clientProfileList.loading = true;
      })
      .addCase(getClientNotes.fulfilled, (state, { payload }: any) => {
        state.clientProfileList = {
          ...payload,
          loading: false,
          notesList: payload.content
        };
      })
      .addCase(getClientNotes.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getClientNote.pending, (state, { meta }) => {
        state.clientNotes[meta.arg.noteId] = {
          loading: true,
          data: null
        };
      })
      .addCase(getClientNote.fulfilled, (state, { payload, meta }) => {
        state.clientNotes[meta.arg.noteId] = {
          data: payload,
          loading: false
        };
      })
      .addCase(getClientNote.rejected, (_, { payload, error }: any) => {
        notifyUserError(payload);
      })
      .addCase(getAddendums.fulfilled, (state, { payload }) => {
        state.addendums.data = payload.content;
      })
      .addCase(getAddendums.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(postCreateAddendum.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putEditAddendum.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(patchEditAddendum.fulfilled, (state, { payload, meta }) => {
        const addendumIdx = state.addendums.data.findIndex(
          (addendum) => addendum.id === meta.arg.addendumId
        );
        if (addendumIdx !== -1) {
          state.addendums.data[addendumIdx] = {
            ...state.addendums.data[addendumIdx],
            status: 'FINAL',
            content: meta.arg.content
          };
        }

        notifyUserSuccess('Your addendum was saved');
      })
      .addCase(patchEditAddendum.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(deleteAddendum.fulfilled, (state, { meta }) => {
        const filteredAddendums = state.addendums.data.filter(
          (addendum) => addendum.id !== meta.arg.addendumId
        );

        state.addendums.data = filteredAddendums;
      })
      .addCase(deleteAddendum.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(deleteNoteInput.fulfilled, (state, { meta }) => {
        state.clientProfileList.notesList = state.clientProfileList.notesList.filter(
          (note) => note.id.toString() !== meta.arg.noteId.toString()
        );
      })
      .addCase(getUsersImageNotes.fulfilled, (state: any, { payload }: any) => {
        if (payload.notePopup) {
          state.clientNotes[payload.noteId] = {
            data: {
              ...state.clientNotes[payload.noteId].data,
              author: {
                ...state.clientNotes[payload.noteId].data.author,
                profileImageUrl: URL.createObjectURL(new Blob([payload.data]))
              }
            },
            loading: false
          };
        }
        if (payload.noteAddendums) {
          const newAddendums = { ...state.addendums };
          const newAddendumsData =
            newAddendums.data.length &&
            newAddendums.data.map((a: Addendum) => {
              if (a.id.toString() === payload.noteId) {
                return {
                  ...a,
                  author: {
                    ...a.author,
                    profileImageUrl: URL.createObjectURL(new Blob([payload.data]))
                  }
                };
              }
              return a;
            });
          state.addendums = { ...state.addendums, data: newAddendumsData || state.addendums.data };
        }
        if (payload.notesList) {
          const foundNote = state.clientProfileList.notesList.findIndex(
            (a: ClientProfileContentItem) => a.id.toString() === payload.noteId
          );
          if (foundNote !== -1) {
            state.clientProfileList.notesList[foundNote] = {
              ...state.clientProfileList.notesList[foundNote],
              author: {
                ...state.clientProfileList.notesList[foundNote].author,
                profileImageUrl: URL.createObjectURL(new Blob([payload.data]))
              }
            };
          }
        }
      })
      .addCase(getUsersImageNotes.rejected, (_, { payload }: any) => {
        notifyUserError(payload);
      });
  }
});
export const { updateNotesListSearchParams, setFilterOnNotesList, resetClientNotes, removeNote } =
  clientProfileSlice.actions;
export default clientProfileSlice.reducer;
