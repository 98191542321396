import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Stack } from 'components';

import style from './collapse.module.scss';

type Props = {
  title: string;
  children: JSX.Element;
  initial?: boolean;
  handleOnClick?: (a?: any) => void;
  blackTitle?: boolean;
  onlyOnceCollapse?: boolean;
};

const Collapse = ({
  title,
  children,
  initial = true,
  handleOnClick,
  blackTitle,
  onlyOnceCollapse
}: Props) => {
  const [open, setOpen] = useState(initial);

  useEffect(() => {
    if (onlyOnceCollapse) {
      setOpen(initial);
    }
  }, [initial, onlyOnceCollapse]);

  return (
    <Stack gap="24px">
      <div
        onClick={() => {
          if (handleOnClick) {
            handleOnClick();
          }
          return setOpen((val) => !val);
        }}
        className={clsx(style.sectionTitle, blackTitle && style.blackTitle)}
      >
        <span className="material-icons">{open ? 'expand_less' : 'expand_more'}</span> {title}
      </div>
      {open && children}
    </Stack>
  );
};

export default Collapse;
