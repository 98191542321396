import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import style from './lastSaved.module.scss';

dayjs.extend(relativeTime);

type Props = {
  lastSaved: string | null;
};

function useForceUpdate() {
  const [, setToggle] = useState(false);
  return () => setToggle((toggle) => !toggle);
}

const LastSaved = ({ lastSaved }: Props) => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate();
    }, 40 * 1000);

    return () => clearInterval(interval);
  }, []);

  if (!lastSaved) {
    return null;
  }

  return (
    <div className={style.autosave}>
      <span className="material-icons">check</span> Autosaved {dayjs().to(lastSaved)}
    </div>
  );
};

export default LastSaved;
