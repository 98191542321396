import dayjs from 'dayjs';
import {
  CalendarEventDTOProps,
  CalendarEventProps
} from 'modules/calendar/myDayCalendar/myDayCalendar';
import { getEnrollmentCalendar } from 'store/enrollment/enrollmentActions';
import {
  setAddEditCalendarEventSideModalOpen,
  setCalendarEvents,
  setExpectedEnrollmentData,
  updateSideModalData
} from 'store/enrollment/enrollmentSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export const useHandleClickOnEvent = () => {
  const dispatch = useAppDispatch();
  const enrollmentProgramId = useAppSelector(
    (state) => state.enrollment.enrollmentProgram?.programId
  );
  const handleClickOnEvent = (event: any | null, openModal: boolean) => {
    console.log(event);
    dispatch(
      updateSideModalData({
        dateOfCallInitiator: dayjs(event?.start).format('YYYY-MM-DD').toString(),
        startDate: event?.startDate,
        endDate: event?.endDate,
        startTime: event?.startTime,
        endTime: event?.endTime,
        ...event,
        programId: event?.programId ?? enrollmentProgramId,
        startDateTime: event?.start,
        endDateTime: event?.end
      })
    );
    dispatch(setAddEditCalendarEventSideModalOpen(openModal));
  };
  return { handleClickOnEvent };
};

export const useLoadCalendarEvents = () => {
  const dispatch = useAppDispatch();
  const enrollmentProgramId = useAppSelector(
    (state) => state.enrollment.enrollmentProgram?.programId
  );

  const adjustDataForCalendar = (data: CalendarEventDTOProps) => {
    return data?.data?.map((element: CalendarEventProps) => ({
      ...element,
      start: new Date(element.start),
      end: new Date(element.end),
      isDraggable: false
    }));
  };

  const loadCalEvents = async (aroundDate?: string, programId?: string) => {
    const startDate = dayjs(aroundDate || undefined)
      .subtract(1, 'month')
      .format('YYYY-MM-DD')
      .toString();
    const endDate = dayjs(aroundDate || undefined)
      .add(1, 'month')
      .format('YYYY-MM-DD')
      .toString();

    const response = await dispatch(
      getEnrollmentCalendar({
        programId: programId ?? enrollmentProgramId,
        start: startDate,
        end: endDate
      })
    );

    const events: unknown = adjustDataForCalendar((response as any).payload);

    dispatch(
      setExpectedEnrollmentData({
        autoConnectFrequencyMinutes: (response as any)?.payload?.autoConnectFrequencyMinutes,
        typicalEnrollmentRate: (response as any)?.payload?.typicalEnrollmentRate
      })
    );
    dispatch(setCalendarEvents(events));

    return events;
  };

  return { loadCalEvents };
};
