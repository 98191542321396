import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { axiosPrivate } from '../../modules/networkTools/networkTools';

export const getScreeners = createAsyncThunk(
  'screener/getScreneers',
  async ({ clientId }: { clientId: string | number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`clients/${clientId}/screeners`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getScreenerQuestions = createAsyncThunk(
  'screener/getScreenerQuestions',
  async (
    { clientId, type }: { clientId: string | number; type: 'impact' },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.get(`clients/${clientId}/screener/questions/${type}`);
      return response.data.sections;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getScreenerDetails = createAsyncThunk(
  'screener/getScreenerDetails',
  async (
    { clientId, type }: { clientId: string | number; type: 'impact' },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.get(`clients/${clientId}/screener/${type}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type Screener = {
  answers: {
    // This key will be the Section[id] which will contain question answers
    [key: string]: {
      // This key will be the Question[id] and the value is answer or answers
      [key: string]: {
        answer: string | string[];
        note: string;
      };
    };
  };
};

export const putScreener = createAsyncThunk(
  'screener/putScreener',
  async (
    { clientId, type, screener }: { clientId: string | number; type: string; screener: Screener },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(`clients/${clientId}/screener/${type}`, screener);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchScreener = createAsyncThunk(
  'screener/patchScreener',
  async (
    {
      clientId,
      type,
      screener
    }: { clientId: string | number; type: string; screener: Screener & { submitted: boolean } },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`clients/${clientId}/screener/${type}`, screener);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getScreenerHistory = createAsyncThunk(
  'screener/getScreenerHistory',
  async (
    { clientId, type }: { clientId: string | number; type: 'impact' },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.get(`clients/${clientId}/screener/${type}/history`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOtherScreenerDetails = createAsyncThunk(
  'screener/getOtherScreenerDetails',
  async ({ clientId }: { clientId: string | number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`clients/${clientId}/screener/other`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type OtherScreener = {
  dateOfScreening?: string;
  needsIdentified?: string[];
  notes?: string;
};

export const putOtherScreener = createAsyncThunk(
  'screener/putOtherScreener',
  async (
    {
      clientId,
      type,
      screener
    }: { clientId: string | number; type: string; screener: OtherScreener },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(`clients/${clientId}/screener/${type}`, screener);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchOtherScreener = createAsyncThunk(
  'screener/patchOtherScreener',
  async (
    {
      clientId,
      type,
      screener
    }: {
      clientId: string | number;
      type: string;
      screener: OtherScreener & { submitted: boolean };
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`clients/${clientId}/screener/${type}`, screener);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOtherScreenerHistory = createAsyncThunk(
  'screener/getOtherScreenerHistory',
  async ({ clientId }: { clientId: string | number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`clients/${clientId}/screener/other/history`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
