import { FC } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

import style from './amountProgress.module.scss';

interface Props {
  title: string;
  subTitle: string;
  amount: number;
  fullAmount: number;
}

const AmountProgress: FC<Props> = ({ title, subTitle, amount, fullAmount }) => {
  const value = (amount * 100) / fullAmount;

  return (
    <div className={style.AmountProgress}>
      <div className={style.title}>
        {title}
        <span>{subTitle}</span>
      </div>
      <div className={style.progress}>
        <div className={style.amount}>$ {amount}</div>
        <LinearProgress className={style.linearProgress} variant="determinate" value={value} />
      </div>
    </div>
  );
};

export default AmountProgress;
