import clsx from 'clsx';
import { Button } from 'components/button';
import dayjs from 'dayjs';
import React, { ChangeEvent } from 'react';
import {
  useLineColors,
  usePlaceStackedVertically,
  useReturnLinePositions
} from 'utils/hooks/useEnrGoalTrackerUtil';

import style from './enrollmentGoalTracker.module.scss';
import Line from './line/line';

interface EnrollmentGoalTrackerProps {
  startDate?: string;
  endDate?: string;
  line1ActualAmount?: number;
  line2ExpectedAmount?: number;
  line3EnrolmentGoalAmount?: number | null; //SET THIS TO NULL to use only 2 lines (expected and actual)
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onEdit?: () => void;
  customClass?: string;
  disableHints?: boolean;
  isGreen?: boolean;
  canEdit?: boolean;
}

const EnrollmentGoalTracker: React.FunctionComponent<EnrollmentGoalTrackerProps> = ({
  line1ActualAmount = 0,
  line2ExpectedAmount = 0,
  line3EnrolmentGoalAmount,
  startDate,
  endDate,
  onEdit,
  customClass,
  disableHints,
  isGreen,
  canEdit
}) => {
  const { line1IconPositionX, line2IconPositionX, line3IconPositionX } = useReturnLinePositions(
    line1ActualAmount,
    line2ExpectedAmount,
    line3EnrolmentGoalAmount
  );

  const { line1IconPositionY, line2IconPositionY, line3IconPositionY } = usePlaceStackedVertically(
    line1IconPositionX,
    line2IconPositionX,
    line3IconPositionX,
    disableHints
  );
  const { line1Color } = useLineColors(
    line1ActualAmount,
    line3EnrolmentGoalAmount || line2ExpectedAmount,
    isGreen
  );

  const textWithIcon = (_text: string | number, iconName: string, colorClass: any) => {
    return (
      <div className={style.textWithIcon}>
        <span className={clsx('material-icons', colorClass)}>{iconName}</span>
        &nbsp;{_text}
      </div>
    );
  };

  return (
    <div className={clsx(style.goalTracker, customClass)}>
      {startDate && <div className={style.dates}>{dayjs(startDate).format('MM/DD/YYYY')}</div>}

      <div className={style.progress}>
        <div className={style.dashedLine} />

        <Line
          zIndex={3}
          text={textWithIcon(line1ActualAmount, 'hiking', style.hiking)}
          percentage={line1IconPositionX}
          positionY={5}
          iconPositionY={line1IconPositionY}
          bgrColor={line1Color}
          toolTipText={'Actual: This is the number of clients enrolled year to date.'}
          disableHints={disableHints}
        />

        <Line
          zIndex={4}
          text={textWithIcon(line2ExpectedAmount, 'flag', style.flag)}
          percentage={line2IconPositionX}
          positionY={1}
          iconPositionY={line2IconPositionY}
          bgrColor={'transparent'}
          toolTipText={
            'Expected: This is the number of clients we expect to be enrolled by the end of the enrollment year. It is calculated as: Actual + Expected from each scheduled enrollment On-call.'
          }
          disableHints={disableHints}
        />
        {line3EnrolmentGoalAmount && (
          <Line
            zIndex={5}
            text={textWithIcon(line3EnrolmentGoalAmount, 'landscape', style.landscape)}
            percentage={line3IconPositionX}
            positionY={1}
            iconPositionY={line3IconPositionY}
            bgrColor={'transparent'}
            toolTipText={
              'Goal: This is the yearly enrollment goal. Only the Director can edit this.'
            }
            disableHints={disableHints}
          />
        )}
      </div>

      {endDate && <div className={style.dates}>{dayjs(endDate).format('MM/DD/YYYY')}</div>}
      {onEdit && canEdit && (
        <div className={style.edit}>
          <Button
            handleOnClick={onEdit}
            type="button"
            theme={'transparent'}
            icon={<span className={'material-icons'}>edit</span>}
          />
        </div>
      )}
    </div>
  );
};

export default EnrollmentGoalTracker;
