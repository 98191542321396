import { ErrorContainer, Stack } from 'components';
import { useFormikContext } from 'formik';
import { TextAreaInputField } from 'components/inputField/textAreaInpuField';

import { FormValues } from '../../clientForm';
import SectionTitle from '../sectionTitle/sectionTitle';

const CareTeam = () => {
  const formik = useFormikContext<FormValues>();

  return (
    <Stack gap="24px" data-test-id="profile-referral">
      <SectionTitle>Care Team</SectionTitle>
      <Stack height="100px">
        <TextAreaInputField
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.careTeam ?? ''}
          placeholder="Enter care team"
          name="careTeam"
        />
        <ErrorContainer
          visible={formik.errors.careTeam && formik.touched.careTeam}
          name="careTeam"
          errors={formik.errors}
        />
      </Stack>
    </Stack>
  );
};

export default CareTeam;
