import { FC } from 'react';

import style from './title.module.scss';

interface Props {
  text: string;
  type: 'h0' | 'h1' | 'h2' | 'h3' | 'h4';
}

const Title: FC<Props> = ({ text, type }) => {
  return (
    <>
      {type == 'h0' && <div className={style.h0}>{text}</div>}
      {type == 'h1' && <h1 className={style.h1}>{text}</h1>}
      {type == 'h2' && <h2 className={style.h2}>{text}</h2>}
      {type == 'h3' && <h3 className={style.h3}>{text}</h3>}
      {type == 'h4' && <h4 className={style.h4}>{text}</h4>}
    </>
  );
};

export default Title;
