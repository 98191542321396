import clsx from 'clsx';
import { Tooltip } from 'components/tooltip';
import React from 'react';

import style from './enrollmentGoalIcon.module.scss';
interface EnrollmentGoalTrackerProps {
  zIndex: number;
  positionY?: number;
  positionX?: number;
  text: string | number | any;
  toolTipText: string | number;
  disableHints?: boolean;
}

const EnrollmentGoalIcon: React.FunctionComponent<EnrollmentGoalTrackerProps> = ({
  zIndex,
  positionY,
  positionX,
  text,
  toolTipText,
  disableHints
}) => {
  return (
    <>
      <div
        className={clsx(style.inputContainerIcon, disableHints && style.disableHints)}
        style={{ zIndex: zIndex, left: `${positionX ? positionX + '%' : 'unset'}`, top: positionY }}
      >
        {disableHints && <div>{text}</div>}
        {!disableHints && <Tooltip toolTipText={toolTipText}>{text}</Tooltip>}
      </div>
    </>
  );
};

export default EnrollmentGoalIcon;
