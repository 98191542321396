import { FC } from 'react';

import style from './ourWorkExpanderSection.module.scss';
import { ExpanderRoadmap } from './roadmaps';

interface Props {}

const OurWorkExpanderSection: FC<Props> = () => {
  return (
    <div className={style.ourWorkExpanderSection}>
      <ExpanderRoadmap />
    </div>
  );
};

export default OurWorkExpanderSection;
