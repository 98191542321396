import { PHONE_INPUT_MASK } from 'constants/utils';

import { Avatar } from '@mui/material';
import { FC } from 'react';
import { User } from 'store/profile/profileSlice';
import { conformToMask } from 'react-text-mask';

import style from './profile.module.scss';

interface Props {
  profile: User | null;
  profileImage: string | undefined;
}

const ProfileInfo: FC<Props> = ({ profile, profileImage }) => {
  if (!profile) return null;

  return (
    <>
      <div className={style.profileWrap}>
        <Avatar className={style.avatar} alt={profile.profileImageKey} src={profileImage}>
          <span className="material-icons-outlined">person</span>
        </Avatar>
        <div className={style.titleWrap}>
          <div className={style.name}>{profile.name}</div>
          <div className={style.role}>{profile.roles?.join(', ')}</div>
        </div>
      </div>
      <div className={style.textWrap}>
        {profile.organizationName && (
          <div className={style.text}>
            <span className={'material-icons'}>domain</span>
            <p>{profile.organizationName}</p>
          </div>
        )}
        {profile.email && (
          <div className={style.text}>
            <span className={'material-icons'}>mail</span>
            <p>{profile.email}</p>
          </div>
        )}
        {profile.phoneNumber && (
          <div className={style.text}>
            <span className={'material-icons'}>phone</span>
            <p>
              {
                conformToMask(profile.phoneNumber?.replace('+1', ''), PHONE_INPUT_MASK)
                  .conformedValue
              }
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileInfo;
