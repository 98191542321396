import { FC } from 'react';
import { Drawer } from '@mui/material';
import clsx from 'clsx';

import { Title } from '../title';
import style from './sidebar.module.scss';

interface Props {
  children: JSX.Element;
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: FC<Props> = ({ children, title, isOpen, onClose }) => {
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose} className={style.sidebar}>
      <div className={style.titleWrap}>
        <Title text={title} type="h2" />
        <span className={clsx('material-icons', style.closeIcon)} onClick={onClose}>
          close
        </span>
      </div>
      <div className={style.content}>{children}</div>
    </Drawer>
  );
};

export default Sidebar;
