import React, { FocusEventHandler } from 'react';
import clsx from 'clsx';

import style from './textAreaInputField.module.scss';

interface TextAreaInputFieldProps {
  ref?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  rowsNumber?: number;
  onBlur: FocusEventHandler<HTMLTextAreaElement>;
  onChange: FocusEventHandler<HTMLTextAreaElement>;
  maxLength?: number;
  enableCounter?: boolean;
  classnamesProps?: string;
  disabled?: boolean;
}

const TextAreaInputField: React.FunctionComponent<TextAreaInputFieldProps> = ({
  ref,
  id,
  name,
  placeholder,
  value = '',
  rowsNumber = 7,
  onBlur,
  onChange,
  maxLength = 500,
  enableCounter = false,
  classnamesProps,
  disabled
}) => {
  return (
    <div className={clsx(style.inputContainer)}>
      {enableCounter && (
        <div className={clsx(style.textAreaCounter)} data-error={value.length > maxLength}>
          {value.length || 0}/{maxLength}
        </div>
      )}
      <textarea
        className={clsx(classnamesProps)}
        maxLength={10000}
        ref={ref}
        id={id}
        name={name}
        rows={rowsNumber}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      ></textarea>
    </div>
  );
};

export default TextAreaInputField;
