import { Avatar } from '@mui/material';
import clsx from 'clsx';
import { Button, LoadingSpinner } from 'components';
import EnrollmentGoalTracker from 'components/goalTracker/enrollmentGoalTracker';
import { FC, useEffect, useMemo, useState } from 'react';
import { DragDropContext, DragStart, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import {
  getEnrollmentUsersList,
  getUsersImageGoals,
  putEnrollmentUsersList
} from 'store/enrollment/enrollmentActions';
import {
  EnrollmentUsersListItem,
  setAddEditCalendarEventSideModalOpen
} from 'store/enrollment/enrollmentSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeDataAndItemAtIndex } from 'utils/changeDataAndItemAtIndex';
import { useHandleClickOnEvent } from 'utils/hooks/useCalendarEvents';

import style from './enrollmentGoalsProgress.module.scss';

interface Props {
  canEdit?: boolean;
  openExpandMore: boolean;
  setOpenExpandMore: (a: boolean) => void;
}

const EnrollmentGoalsProgress: FC<Props> = ({ canEdit, openExpandMore, setOpenExpandMore }) => {
  const dispatch = useAppDispatch();
  const { enrollmentUsersList, trackerBar, enrollmentProgram } = useAppSelector(
    (state) => state.enrollment
  );
  const [edit, setEdit] = useState<boolean>(false);
  const [selectedDraggableId, setSelectedDraggableId] = useState<string | null>(null);
  const [enrollmentsList, setEnrollmentsList] = useState<EnrollmentUsersListItem[]>([]);
  const { handleClickOnEvent } = useHandleClickOnEvent();

  useEffect(() => {
    if (openExpandMore) {
      dispatch(getEnrollmentUsersList({ programId: enrollmentProgram?.programId }));
    }
  }, [openExpandMore, enrollmentProgram?.programId]);

  const isGreen = useMemo(() => {
    return !!(trackerBar && trackerBar?.actualCaseload >= trackerBar?.enrollmentGoal);
  }, [trackerBar]);

  useEffect(() => {
    const fetchUserImageGoals = async () => {
      const chwDetails = enrollmentUsersList.chwDetails;
      const imageGoalsPromises = [];
      for (const item of chwDetails) {
        if (item.profileImageKey && !item.profileImageUrl) {
          imageGoalsPromises.push(
            dispatch(
              getUsersImageGoals({ profileImageKey: item.profileImageKey, userId: item.userId })
            )
          );
        }
      }
    };

    if (enrollmentUsersList.chwDetails.length > 0) {
      fetchUserImageGoals().then();
    }
  }, [enrollmentUsersList.chwDetails.length, enrollmentProgram?.programId]);

  useEffect(() => {
    setEnrollmentsList(enrollmentUsersList.chwDetails);
  }, [enrollmentUsersList.chwDetails]);

  const onDragEnd = (data: DropResult) => {
    setSelectedDraggableId(null);
    if (!data.destination) return;

    const items = Array.from(enrollmentsList);
    const [reorderedItem] = items.splice(data.source.index, 1);
    items.splice(data.destination.index, 0, reorderedItem);
    const filtered = changeDataAndItemAtIndex(items, data.destination.index);
    setEnrollmentsList(filtered);
  };

  const onDragStart = (data: DragStart) => {
    setSelectedDraggableId(data.draggableId);
  };

  const onReset = () => {
    dispatch(
      putEnrollmentUsersList({
        programId: enrollmentUsersList.programId,
        useDefaultChwOrder: true
      })
    );
    setEdit(false);
  };

  const onSave = () => {
    const chwDetails = enrollmentsList.map((a) => {
      return { userId: a.userId, orderNum: a.orderNum };
    });

    const newData = {
      organizationId: enrollmentUsersList.organizationId,
      programId: enrollmentUsersList.programId,
      useDefaultChwOrder: false,
      chwOrderItems: chwDetails
    };
    dispatch(putEnrollmentUsersList(newData));
    setEdit(false);
  };

  const addEvent = (data: any) => {
    setEdit(false);
    handleClickOnEvent(
      {
        organizationId: enrollmentUsersList.organizationId,
        programId: enrollmentUsersList.programId,
        ...data
      },
      true
    );
    setOpenExpandMore(false);
  };

  return (
    <>
      {openExpandMore && (
        <div className={style.enrollmentGoalsProgress}>
          <div className={style.titleWrap}>
            <div className={style.title}>Progress by CHW</div>
            {enrollmentsList.length > 0 && canEdit && (
              <div className={style.buttonsWrap}>
                {edit ? (
                  <>
                    <Button
                      text="Reset to default"
                      type="button"
                      theme={'transparent'}
                      size="extraSmall"
                      customStyleClass={style.buttonReset}
                      handleOnClick={onReset}
                    />
                    <Button
                      text="Save"
                      handleOnClick={onSave}
                      type="button"
                      theme={'secondary'}
                      size="extraSmall"
                    />
                  </>
                ) : (
                  <Button
                    text="Edit order"
                    type="button"
                    theme={'transparent'}
                    size="extraSmall"
                    handleOnClick={() => setEdit(true)}
                  />
                )}
              </div>
            )}
          </div>
          {enrollmentUsersList.isLoading && (
            <div className={style.loading}>
              <LoadingSpinner />
            </div>
          )}
          {!enrollmentUsersList.isLoading && enrollmentsList.length === 0 && (
            <div className={style.noData}>no data</div>
          )}
          {!enrollmentUsersList.isLoading && enrollmentsList.length > 0 && (
            <div className={clsx(style.trackerWrap, selectedDraggableId && style.trackerWrapOpen)}>
              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId="droppable" type="COLUMN" direction="vertical">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {enrollmentsList.map((item, index) => (
                        <Draggable
                          key={item.orderNum}
                          draggableId={item.orderNum?.toString()}
                          index={index}
                          isDragDisabled={!edit}
                        >
                          {(providedChildren, snapshot) => (
                            <div
                              ref={providedChildren.innerRef}
                              {...providedChildren.draggableProps}
                              {...providedChildren.dragHandleProps}
                              className={clsx(style.item, selectedDraggableId && style.selected)}
                            >
                              <div className={style.nameWrap}>
                                {edit && (
                                  <span
                                    className={clsx('material-icons-outlined', style.dragIndicator)}
                                  >
                                    drag_indicator
                                  </span>
                                )}
                                <Avatar
                                  className={style.avatar}
                                  alt={item.chwName}
                                  src={item.profileImageUrl}
                                >
                                  <span className="material-icons-outlined">person</span>
                                </Avatar>
                                <div
                                  className={clsx(style.name, !item.isActive && style.nameDisabled)}
                                >
                                  {item.chwName}
                                </div>
                              </div>
                              <div className={style.contentWrap}>
                                <div className={style.nameWrap}>
                                  {item.currentCaseload > item.maximumRecommendedCaseload ? (
                                    <div className={clsx(style.counter, style.counterError)}>
                                      Current Caseload:&nbsp;<p>{item.currentCaseload}</p>
                                      &nbsp;
                                      <span className={clsx('material-icons-outlined', style.icon)}>
                                        warning_amber
                                      </span>
                                      &nbsp;High
                                    </div>
                                  ) : (
                                    <div className={style.counter}>
                                      Current Caseload:&nbsp;<p>{item.currentCaseload}</p>
                                    </div>
                                  )}
                                  <EnrollmentGoalTracker
                                    line1ActualAmount={item.actualCaseload}
                                    line2ExpectedAmount={item.expectedCaseload}
                                    customClass={style.enrollmentGoalTrackerWrap}
                                    isGreen={isGreen}
                                    disableHints
                                  />
                                </div>
                                <Button
                                  icon={<span className="material-icons">add</span>}
                                  text="Add Event"
                                  type="button"
                                  theme={'transparent'}
                                  size="extraSmall"
                                  disabled={!item.isActive}
                                  customStyleClass={style.addButton}
                                  handleOnClick={() => addEvent(item)}
                                />
                                <div className={style.placeholderNone}>{provided.placeholder}</div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
        </div>
      )}
      <div
        className={style.buttonOpen}
        onClick={() => {
          dispatch(setAddEditCalendarEventSideModalOpen(false));
          setOpenExpandMore(!openExpandMore);
        }}
      >
        <span className={clsx('material-icons-outlined', openExpandMore && style.buttonOpened)}>
          expand_more
        </span>
      </div>
    </>
  );
};

export default EnrollmentGoalsProgress;
