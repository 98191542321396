import { FC, SyntheticEvent, ReactNode } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import clsx from 'clsx';

import { Title } from '../title';
import { Button } from '../button';
import style from './expander.module.scss';

export interface Props {
  title: string;
  children: ReactNode;
  isExpanded?: boolean;
  disabled?: boolean;
  defaultExpanded?: boolean;
  onChange?: (event: SyntheticEvent<Element, Event>, expanded: boolean) => void;
  expanderBlue?: boolean;
  customInsideComponent?: JSX.Element;
}

export const Expander: FC<Props> = ({
  title,
  disabled,
  children,
  isExpanded,
  defaultExpanded,
  onChange,
  expanderBlue,
  customInsideComponent
}): JSX.Element => (
  <Accordion
    square
    disabled={disabled}
    elevation={0}
    expanded={isExpanded}
    className={clsx(style.expander, expanderBlue && style.expanderBlue)}
    defaultExpanded={defaultExpanded}
    onChange={onChange}
  >
    <AccordionSummary
      className={style.title}
      expandIcon={
        <Button
          customStyleClass={style.icon}
          icon={<span className={'material-icons'}>expand_more</span>}
          theme="transparent"
        />
      }
    >
      <div className={style.titleWrap}>
        <Title type="h2" text={title} />
        <div className={style.buttonsWraps}>{customInsideComponent}</div>
      </div>
    </AccordionSummary>
    <AccordionDetails className={style.content}>{children}</AccordionDetails>
  </Accordion>
);

export default Expander;
