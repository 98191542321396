import { createSlice } from '@reduxjs/toolkit';
import { notifyUserError } from 'utils/notifications';

import {
  CitiesAutocompleteResponse,
  StatesAutocompleteResponse,
  ZipCodesAutocompleteResponse,
  LanguagesAutocompleteResponse,
  InsurancesAutocompleteResponse,
  IcdCodesAutocompleteResponse
} from './dataActions';
import {
  getCitiesAutocomplete,
  getStatesAutocomplete,
  getZipCodesAutocomplete,
  getLanguagesAutocomplete,
  getInsurancesAutocomplete,
  getIcdCodesAutocomplete
} from './dataActions';

interface InitialState {
  citiesAutocomplete: {
    loading: boolean;
    data: CitiesAutocompleteResponse;
  };
  statesAutocomplete: {
    loading: boolean;
    data: StatesAutocompleteResponse;
  };
  zipCodesAutocomplete: {
    loading: boolean;
    data: ZipCodesAutocompleteResponse;
  };
  languagesAutocomplete: {
    loading: boolean;
    data: LanguagesAutocompleteResponse;
  };
  insurancesAutocomplete: {
    loading: boolean;
    data: InsurancesAutocompleteResponse;
  };
  icdCodesAutocomplete: {
    loading: boolean;
    data: IcdCodesAutocompleteResponse;
  };
}

const initialState: InitialState = {
  citiesAutocomplete: {
    loading: false,
    data: []
  },
  statesAutocomplete: {
    loading: false,
    data: []
  },
  zipCodesAutocomplete: {
    loading: false,
    data: []
  },
  languagesAutocomplete: {
    loading: false,
    data: []
  },
  insurancesAutocomplete: {
    loading: false,
    data: []
  },
  icdCodesAutocomplete: {
    loading: false,
    data: []
  }
};

const dataSlice = createSlice({
  name: 'dataSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Cities
      .addCase(getCitiesAutocomplete.pending, (state) => {
        state.citiesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getCitiesAutocomplete.fulfilled, (state, action) => {
        state.citiesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getCitiesAutocomplete.rejected, (state, payload: any) => {
        notifyUserError(payload);
      })
      // States
      .addCase(getStatesAutocomplete.pending, (state) => {
        state.statesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getStatesAutocomplete.fulfilled, (state, action) => {
        state.statesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getStatesAutocomplete.rejected, (_, payload: any) => {
        notifyUserError(payload);
      })
      // ZIP Codes
      .addCase(getZipCodesAutocomplete.pending, (state) => {
        state.zipCodesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getZipCodesAutocomplete.fulfilled, (state, action) => {
        state.zipCodesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getZipCodesAutocomplete.rejected, (_, payload: any) => {
        notifyUserError(payload);
      })
      // Languages
      .addCase(getLanguagesAutocomplete.pending, (state) => {
        state.languagesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getLanguagesAutocomplete.fulfilled, (state, action) => {
        state.languagesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getLanguagesAutocomplete.rejected, (_, payload: any) => {
        notifyUserError(payload);
      })
      // Insurances
      .addCase(getInsurancesAutocomplete.pending, (state) => {
        state.insurancesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getInsurancesAutocomplete.fulfilled, (state, action) => {
        state.insurancesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getInsurancesAutocomplete.rejected, (_, payload: any) => {
        notifyUserError(payload);
      })
      // ICD Codes
      .addCase(getIcdCodesAutocomplete.pending, (state) => {
        state.icdCodesAutocomplete = {
          data: [],
          loading: true
        };
      })
      .addCase(getIcdCodesAutocomplete.fulfilled, (state, action) => {
        state.icdCodesAutocomplete = {
          data: action.payload,
          loading: false
        };
      })
      .addCase(getIcdCodesAutocomplete.rejected, (_, payload: any) => {
        notifyUserError(payload);
      });
  }
});

export default dataSlice.reducer;
