import { ORGANIZATIONS_SECTION_URL } from './utils';

export const trainingData: string[] = [
  '1. Standardized Patient (SP) Encounter',
  '4. IMPaCT Manual Quiz',
  '2. IMPaCT Video Orientation',
  '5. Explaining the Role',
  '3. Organization & Documentation',
  '6. Meet the Person Interview (MPI) Observation'
];

export const outreachPriorityCharacteristics = [
  '1. Referred clients',
  '2. Recently hospitalized clients',
  '3. Clients with upcoming outpatient appointments',
  '4. General enrollment pool'
];

export const disqualifyingCriteria = [
  '1. Terminated from program for safety concerns',
  '2. Client has responded STOP to enrollment text',
  '3. < 1 year since prior IMPaCT graduation'
];

export const gettingBetterProcessMetrics = [
  '1. Engagement (enrolled clients / total outreached)',
  '2. MPI completion rate',
  '3. CHW weekly contacts',
  '4. Disengagement',
  '5. Client goals achieved'
];

export const gettingBetterOutcomeMetrics = [
  '1. Net Promoter Score',
  '2. Client-reported quality of care',
  '3. Chronic disease management',
  '4. Self-rated mental health',
  '5. Primary care access',
  '6. Hospital days',
  '7. ER visits'
];

export const OrganizationsListStatuses = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  INACTIVATION_PENDING: 'Inactivation Pending'
};

export const UsersListStatuses = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const InviteStatuses = {
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED'
};

//Page data
export const addNewOrgPageData = {
  title: 'New Organization',
  breadcrumbItems: [{ name: 'organizations', path: ORGANIZATIONS_SECTION_URL }],
  breadcrumbLastItem: `New Organization`
};

export const modalityAndDurationTypes = [
  { id: 'IN_PERSON_ZIP_CODE', name: 'In person within ZIP Code radius' },
  { id: 'IN_PERSON_ALL', name: 'In person for all' },
  { id: 'REMOTE_ALL', name: 'Remote for all' }
] as const;

export const modalityAndDurationPeriods = [
  { id: 'WEEKS', name: 'Weeks' },
  { id: 'MONTHS', name: 'Months' }
] as const;

export const CustomerEnrollmentStatuses = {
  SUPERVISOR_DIRECTOR: 'Supervisor (Director)',
  SUPERVISOR_MANAGER: 'Supervisor (Manager)'
};

export const RecurringType = {
  DAY: 'DAY',
  WEEK: 'WEEK'
  // MONTH: 'MONTH'
};

export const UpdateType = {
  CURRENT: 'CURRENT',
  NEXT_OCCURRENCES: 'NEXT_OCCURRENCES'
};

export const EventType = {
  SHIFT: 'SHIFT',
  UNAVAILABLE: 'UNAVAILABLE'
};

export const StatusRoadmap = {
  ACTIVE: 'Active',
  COMPLETED: 'Completed',
  ABANDONED: 'Abandoned'
};
