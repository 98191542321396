import { Button, Dropdown, LoadingSpinner, Stack, Title } from 'components';
import { FC, useEffect, useState } from 'react';
import { getClientNotes, getUsersImageNotes } from 'store/clientProfile/clientProfileActions';
import { noteTypeData } from 'store/clients/clientsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ClientProfileContentItem } from 'store/clientProfile/clientProfileSlice';
import { getCurrentNote } from 'modules/networkTools/localStorageTokens';
import { useSearchParams } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';

import { Note } from './note';
import style from './notesList.module.scss';

type Props = {
  clientId: string;
  organizationId: number;
};

const NotesList: FC<Props> = ({ clientId, organizationId }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const noteTypes = searchParams.get('noteTypes') ?? '';
  const dispatch = useAppDispatch();
  const { notesList, totalNumber, size } = useAppSelector(
    (state) => state.clientProfile.clientProfileList
  );
  const currentNote = getCurrentNote();
  const [loading, setIsLoading] = useState(false);

  const getNotes = async (s: number) => {
    setIsLoading(true);
    await dispatch(
      getClientNotes({
        clientId,
        organizationId,
        page: 0,
        size: s,
        types: noteTypes
      })
    ).then((res: any) => {
      if (!res.error && res.payload.content.length > 0) {
        //TODO need to optimize
        res.payload.content.map((noteItem: ClientProfileContentItem) => {
          if (noteItem.author.profileImageKey) {
            dispatch(
              getUsersImageNotes({
                profileImageKey: noteItem.author.profileImageKey,
                userId: clientId,
                noteId: noteItem.id.toString(),
                notesList: true
              })
            );
          }
        });
      }
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getNotes(2);
  }, []);

  useEffect(() => {
    getNotes(size);
  }, [noteTypes]);

  const onChange = (e: SelectChangeEvent<string | string[]>) => {
    setSearchParams((params) => {
      console.log(e.target.value);
      if (e.target.value.length < 1) {
        params.delete('noteTypes');
      } else {
        params.set(
          'noteTypes',
          Array.isArray(e.target.value) ? e.target.value.join(',') : e.target.value
        );
      }

      return params;
    });
  };

  return (
    <div className={style.notesList} data-test-id="profile-notes-list">
      <div className={style.notesHeader}>
        <Title text="History" type="h3" />
        <Stack width="140px">
          <Dropdown
            multiSelect
            value={noteTypes.split(',').filter(Boolean)}
            data={noteTypeData}
            onChange={onChange}
            label="All Notes"
            rounded
          />
        </Stack>
      </div>
      {loading ? (
        <Stack width="100%" display="flex" flexDirection="row" justifyContent="center" mt="24px">
          <LoadingSpinner size={35} />
        </Stack>
      ) : (
        <>
          {notesList?.map((item, index) => (
            <button
              key={item.id}
              style={{ width: '100%' }}
              type={currentNote.currentNoteID === item.id ? 'reset' : 'button'}
              form="noteEdit"
            >
              <Note note={item} />
            </button>
          ))}
          {notesList && notesList.length > 0 && notesList.length !== totalNumber && (
            <div className={style.btnWrap}>
              <Button
                size="extraSmall"
                theme="transparent"
                handleOnClick={() => getNotes(size + 5)}
                text="See more"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NotesList;
