import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { axiosPrivate } from '../../modules/networkTools/networkTools';

export const getRoles: any = createAsyncThunk(
  'organization/getRoles',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`setup/organization/roles`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
