import { createSlice } from '@reduxjs/toolkit';
import { notifyUserSuccess } from 'utils/notifications';

import {
  getOtherScreenerDetails,
  getOtherScreenerHistory,
  getScreenerDetails,
  getScreenerHistory,
  getScreenerQuestions,
  getScreeners,
  patchOtherScreener,
  patchScreener
} from './screenerActions';

export type ScreenerTypeUppercase = 'IMPACT' | 'OTHER';

export type ScreenerTypeLowercase = 'impact' | 'other';

type Screener = {
  name: string;
  type: ScreenerTypeUppercase;
};

export type Answers = {
  // This key will be the Section[id] which will contain question answers
  [key: number]: {
    // This key will be the Question[id] and the value is answer or answers
    [key: number]: {
      answer: string | string[]; // this field is a string or an array of strings depending if the question is multi answer
      note: string;
    };
  };
};

type ScreenerDetails = {
  updatedAt: string;
  id: string;
  submitted: boolean;
  answers: Answers;
};

type Answer = {
  text: string;
  id: number;
};

type Question = {
  id: number;
  title: string;
  multiselect: boolean;
  answers: Answer[];
};

export type Section = {
  title: string;
  id: number;
  questions: Question[];
};

type Author = {
  id: number;
  name: string;
  profileImageKey: string;
};

type ScreenerHistory = {
  author: Author;
  submittedOn: string;
  id: string;
};

type OtherScreenerHistory = {
  author: Author;
  dateOfScreening: string;
  id: string;
};

type OtherScreenerDetails = {
  updatedAt: string;
  dateOfScreening: string;
  needsIdentified: string[];
  notes: string;
  submitted: boolean;
};

type InitialState = {
  [clientId: string]: {
    screeners: { data: Screener[]; loading: boolean };
    questionnare: {
      [key in Exclude<ScreenerTypeLowercase, 'other'>]?: Section[];
    };
    screener: {
      [key in Exclude<ScreenerTypeLowercase, 'other'>]: {
        data: ScreenerDetails | null;
        loading: boolean;
      };
    };
    screenerHistory: {
      [key in Exclude<ScreenerTypeLowercase, 'other'>]?: ScreenerHistory[];
    };
    otherScreenerHistory: OtherScreenerHistory[];
    otherScreener: OtherScreenerDetails | null;
  };
};

const initialState: InitialState = {};

export const screenerSlice = createSlice({
  name: 'screenerSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getScreeners.fulfilled, (state, action) => {
        state[action.meta.arg.clientId] = {
          ...state[action.meta.arg.clientId],
          screeners: {
            data: action.payload,
            loading: false
          }
        };
      })
      .addCase(getScreeners.rejected, (state, action) => {})
      .addCase(getScreenerQuestions.fulfilled, (state, action) => {
        state[action.meta.arg.clientId] = {
          ...state[action.meta.arg.clientId],
          questionnare: {
            [action.meta.arg.type]: action.payload
          }
        };
      })
      .addCase(getScreenerQuestions.rejected, (state, action) => {})
      .addCase(getScreenerDetails.pending, (state, action) => {
        state[action.meta.arg.clientId] = {
          ...state[action.meta.arg.clientId],
          screener: {
            [action.meta.arg.type]: {
              ...state[action.meta.arg.clientId]?.screener[action.meta.arg.type],
              loading: true
            }
          }
        };
      })
      .addCase(getScreenerDetails.fulfilled, (state, action) => {
        state[action.meta.arg.clientId] = {
          ...state[action.meta.arg.clientId],
          screener: {
            [action.meta.arg.type]: {
              data: action.payload,
              loading: false
            }
          }
        };
      })
      .addCase(getScreenerDetails.rejected, (state, action) => {})
      .addCase(getScreenerHistory.fulfilled, (state, action) => {
        state[action.meta.arg.clientId] = {
          ...state[action.meta.arg.clientId],
          screenerHistory: {
            [action.meta.arg.type]: action.payload
          }
        };
      })
      .addCase(patchScreener.fulfilled, (state, action) => {
        notifyUserSuccess('Your changes were saved');
      })
      .addCase(patchOtherScreener.fulfilled, (state, action) => {
        notifyUserSuccess('Your changes were saved');
      })
      .addCase(getOtherScreenerDetails.fulfilled, (state, action) => {
        state[action.meta.arg.clientId] = {
          ...state[action.meta.arg.clientId],
          otherScreener: action.payload
        };
      })
      .addCase(getOtherScreenerHistory.fulfilled, (state, action) => {
        state[action.meta.arg.clientId] = {
          ...state[action.meta.arg.clientId],
          otherScreenerHistory: action.payload
        };
      });
  }
});

export default screenerSlice.reducer;
