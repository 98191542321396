import { Button, Dropdown, Stack } from 'components';
import { useFormik } from 'formik';
import { TodosDTO, RoadmapStatus, GoalsDTO, updateTodoOrGoal } from 'store/roadmap/roadmapSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateTodo } from 'store/roadmap/roadmapActions';

type Props = {
  onClose: () => void;
  todo: TodosDTO;
  isTodo?: boolean;
  clientId: string;
  dataParent?: GoalsDTO;
};

const GoalForm = ({ onClose, todo, isTodo, clientId, dataParent }: Props) => {
  const dispatch = useAppDispatch();
  const { goalsList } = useAppSelector((state) => state.roadmap);

  const { handleChange, handleBlur, handleSubmit, values } = useFormik({
    initialValues: todo,
    onSubmit: (newData) => {
      dispatch(
        updateTodo({
          data: { ...newData, goalId: newData.goalId ? Number(newData.goalId) : null },
          clientId,
          isTodo,
          dataParent
        })
      ).then(
        () =>
          dataParent &&
          dispatch(
            updateTodoOrGoal({
              data: { ...newData, goalId: newData.goalId ? Number(newData.goalId) : null },
              dataParent
            })
          )
      );
      onClose();
    }
  });

  return (
    <form onSubmit={handleSubmit}>
      <Stack width="244px">
        <Stack>
          <Dropdown
            name="goalId"
            value={values.goalId?.toString() || ''}
            onChange={handleChange}
            onBlur={handleBlur}
            data={
              goalsList.length
                ? goalsList
                    .map((a) => ({ ...a, id: a.id || '', name: a.title }))
                    .filter((a) => a.id && a.status === RoadmapStatus.ACTIVE)
                : []
            }
            label="Select goal"
          />
        </Stack>
        <Stack mt="12px" display="flex" flexDirection="row" gap="12px">
          <Button
            type="button"
            theme="transparent"
            size="extraSmall"
            text="Cancel"
            handleOnClick={onClose}
          />
          <Button type="submit" theme="primary" size="extraSmall" text="Save" />
        </Stack>
      </Stack>
    </form>
  );
};

export default GoalForm;
