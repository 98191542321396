import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { operations } from '../../api/generated-types';
import { axiosPrivate, axiosPublic } from '../../modules/networkTools/networkTools';

type RegisterCandidate = operations['registerForCoach'];

interface CandidateRegDTO {
  candidate: RegisterCandidate['requestBody']['content']['application/json'];
  referenceLink: string | null | undefined;
  candidateId: string | null;
}

interface CandidateStatusDTO {
  candidateId: number;
  candidateStatus: string;
}

interface CandidatePaidDTO {
  candidateId: number;
  paymentType: string;
}

export const postCandidateRegistration = createAsyncThunk<
  RegisterCandidate['responses']['200'],
  CandidateRegDTO
>(
  'candidate/candidateRegistration',
  async ({ candidate, referenceLink, candidateId }: CandidateRegDTO, { rejectWithValue }) => {
    const url = referenceLink
      ? `candidate/${candidateId}/registration/recruiter/${referenceLink}`
      : `candidate/${candidateId}/registration`;
    const axiosRole = referenceLink ? axiosPublic : axiosPrivate;
    try {
      const response = await axiosRole.post(url, candidate);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCandidate: any = createAsyncThunk(
  'candidate/candidateInfo',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`candidate/${id}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putCandidateStatus: any = createAsyncThunk(
  'candidate/changeStatus',
  async ({ candidateId, candidateStatus }: CandidateStatusDTO, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put('candidate/status', {
        candidateId,
        candidateStatus
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putCandidatePaid: any = createAsyncThunk(
  'candidate/changePaid',
  async ({ candidateId, paymentType }: CandidatePaidDTO, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put('candidate/paid', {
        candidateId,
        paymentType
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCandidateCV: any = createAsyncThunk(
  'candidate/downloadCV',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`candidate/${id}/download/cv`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getCandidateVideo: any = createAsyncThunk(
  'candidate/downloadVideo',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`candidate/${id}/download/video`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
