import { FC } from 'react';
import OrganizationSubHeader from 'components/subheader/organizationSubheader/organizationSubHeader';
import { useLocation, useParams } from 'react-router-dom';
import { LinkWithIndirectRouting } from 'components/linkWithConfirm';
import { useGetOrganization } from 'utils/hooks/useGetOrganization';
import { useAppSelector } from 'store/hooks';
import { Stack } from 'components';
import clsx from 'clsx';

import style from './pageWrapper.module.scss';

interface Props {
  subHeaderData?: any;
  hasSideNavigation?: boolean;
  pageContent: JSX.Element;
}

const PageWrapper: FC<Props> = ({ subHeaderData, hasSideNavigation = true, pageContent }) => {
  // we are gonna use programId to find the program name and display it in the side navigation
  const { programID, organizationID, editMode } = useParams();
  const programDetails = useAppSelector((state) => state.organization.program.programDetails);

  const currentOrganization = useGetOrganization(organizationID);

  const { pathname } = useLocation();
  const isNewProgramOpened = pathname.includes('new-program');

  return (
    <>
      <div className={style.pageContainer}>
        <div className={style.insideContainer}>
          {subHeaderData && (
            <OrganizationSubHeader
              title={currentOrganization?.name || subHeaderData.title}
              breadcrumbRouting={{
                breadcrumbItems: subHeaderData.breadcrumbItems,
                breadcrumbLastItem: currentOrganization?.name || subHeaderData.breadcrumbLastItem
              }}
            />
          )}

          <div className={style.sidebarAndMainContent}>
            {hasSideNavigation && (
              <div className={style.sidebar} data-test-id="page-sidebar">
                <Stack component="ul" gap="16px">
                  <LinkWithIndirectRouting
                    key={1}
                    path={`/organizations/about/${organizationID}${editMode ? '/editMode' : ''}`}
                    styleClass={style.linkItem}
                    styleClassActive={style.linkItemSelected}
                    content={<li>About</li>}
                  />
                  <LinkWithIndirectRouting
                    key={2}
                    path={`/organizations/programs/${organizationID}${editMode ? '/editMode' : ''}`}
                    disabled={!organizationID}
                    styleClass={style.linkItem}
                    styleClassActive={clsx(
                      (!programID || isNewProgramOpened) && style.linkItemSelected
                    )}
                    content={<li>Programs</li>}
                  />
                  {programID && (
                    <Stack
                      className={style.selectedProgram}
                      component="li"
                      marginLeft="32px"
                      position="relative"
                    >
                      {programDetails?.details?.name}
                    </Stack>
                  )}
                  {isNewProgramOpened && (
                    <Stack
                      className={style.selectedProgram}
                      component="li"
                      marginLeft="28px"
                      position="relative"
                    >
                      New Program
                    </Stack>
                  )}
                  <LinkWithIndirectRouting
                    key={3}
                    path={`/organizations/sso/${organizationID}${editMode ? '/editMode' : ''}`}
                    disabled={!organizationID}
                    styleClass={style.linkItem}
                    styleClassActive={style.linkItemSelected}
                    content={<li>SSO</li>}
                  />
                  <LinkWithIndirectRouting
                    key={4}
                    disabled={!organizationID}
                    path={`/organizations/users/${organizationID}${editMode ? '/editMode' : ''}`}
                    styleClass={style.linkItem}
                    styleClassActive={style.linkItemSelected}
                    content={<li>Users</li>}
                  />
                  <LinkWithIndirectRouting
                    key={5}
                    disabled={!organizationID}
                    path={`/organizations/integrations/${organizationID}${
                      editMode ? '/editMode' : ''
                    }`}
                    styleClass={style.linkItem}
                    styleClassActive={style.linkItemSelected}
                    content={<li>Integrations</li>}
                  />
                  {!currentOrganization?.isActive && (
                    <LinkWithIndirectRouting
                      key={6}
                      disabled={!organizationID}
                      path={`/organizations/activate/${organizationID}${
                        editMode ? '/editMode' : ''
                      }`}
                      styleClass={style.linkItem}
                      styleClassActive={style.linkItemSelected}
                      content={<li>Activate</li>}
                    />
                  )}
                  <hr />
                </Stack>
              </div>
            )}
            <div
              className={
                hasSideNavigation ? style.pageContentContainer : style.pageContentContainerFullWidth
              }
            >
              {pageContent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageWrapper;
