import * as React from 'react';

import style from './breadcrumbBack.module.scss';

interface ButtonProps {
  handleOnClick?: React.MouseEventHandler<HTMLButtonElement | undefined>;
}

export const BreadcrumbBack: React.FunctionComponent<ButtonProps> = ({ handleOnClick }) => {
  return (
    <button
      className={style.breadcrumbContainer}
      onClick={(e) => handleOnClick && handleOnClick(e)}
    >
      <span style={{ fontSize: '14px' }} className="material-icons">
        arrow_back_ios
      </span>
      Back
    </button>
  );
};

export default BreadcrumbBack;
