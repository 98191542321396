import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { axiosPrivate } from 'modules/networkTools/networkTools';

type City = {
  id: number;
  name: string;
};

export type CitiesAutocompleteResponse = City[];

export const getCitiesAutocomplete = createAsyncThunk(
  'data/getCitiesAutocomplete',
  async (
    { term, state, zipCode }: { term?: string; state?: string; zipCode?: string },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ maxResult: '10' });
    if (term !== '' && term != null) {
      searchParams.append('term', term);
    }
    if (state !== '' && state != null) {
      searchParams.append('state', state);
    }
    if (zipCode !== '' && zipCode != null) {
      searchParams.append('zipCodes', zipCode);
    }

    const url = `catalog/city/autocomplete?${searchParams.toString()}`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data as CitiesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type State = {
  id: number;
  name: string;
};

export type StatesAutocompleteResponse = State[];

export const getStatesAutocomplete = createAsyncThunk(
  'data/getStatesAutocomplete',
  async (
    { term, city, zipCode }: { term?: string; city?: string; zipCode?: string },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ maxResult: '50' });

    if (term !== '' && term != null) {
      searchParams.append('term', term);
    }
    if (city !== '' && city != null) {
      searchParams.append('city', city);
    }
    if (zipCode !== '' && zipCode != null) {
      searchParams.append('zipCodes', zipCode);
    }
    const url = `catalog/state/autocomplete?${searchParams.toString()}`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data as StatesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type ZipCode = {
  id: number;
  name: string;
};

export type ZipCodesAutocompleteResponse = ZipCode[];

export const getZipCodesAutocomplete = createAsyncThunk(
  'data/getZipCodesAutocomplete',
  async (
    { term, city, state }: { term?: string; city?: string; state?: string },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({ maxResult: '10' });

    if (term !== '' && term != null) {
      searchParams.append('term', term);
    }
    if (city !== '' && city != null) {
      searchParams.append('city', city);
    }

    if (state !== '' && state != null) {
      searchParams.append('state', state);
    }
    const url = `catalog/zip-codes/autocomplete?${searchParams.toString()}`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data as ZipCodesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type Language = {
  id: number;
  name: string;
};

export type LanguagesAutocompleteResponse = Language[];

export const getLanguagesAutocomplete = createAsyncThunk(
  'data/getLanguagesAutocomplete',
  async ({ term }: { term: string }, { rejectWithValue }) => {
    const url = `catalog/language/autocomplete?term=${term}&maxResult=10`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data as LanguagesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type Insurance = {
  id: number;
  name: string;
};

export type InsurancesAutocompleteResponse = Insurance[];

export const getInsurancesAutocomplete = createAsyncThunk(
  'data/getInsurancesAutocomplete',
  async ({ term }: { term: string }, { rejectWithValue }) => {
    const url = `catalog/insurance/autocomplete?term=${term}&maxResult=15`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data as InsurancesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type IcdCode = {
  id: number;
  name: string;
  description: string;
};

export type IcdCodesAutocompleteResponse = IcdCode[];

export const getIcdCodesAutocomplete = createAsyncThunk(
  'data/getIcdCodesAutocomplete',
  async ({ term }: { term: string }, { rejectWithValue }) => {
    const url = `catalog/icd-code/autocomplete?term=${term}&maxResult=10`;
    try {
      const response = await axiosPrivate.get(url);
      return response.data as IcdCodesAutocompleteResponse;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        if (error.response.status === 400) {
          return rejectWithValue(false);
        }
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

// https://11hbribkf7.execute-api.us-east-1.amazonaws.com/api/v1/core/catalog/state/autocomplete?maxResult=50&city=&zipCodes=80004
// https://11hbribkf7.execute-api.us-east-1.amazonaws.com/api/v1/core/catalog/state/autocomplete?maxResult=50&city=&zipCode=80004
