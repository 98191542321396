import * as React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Layout } from '../../components';
import { LOGIN_PAGE_URL } from '../../constants/utils';
import { PermissionProps, useUserAuthStatusCheckerByPermission } from '../jwtDecoder';
import { PERMISSION } from './userRolesAndPermissionTypes';

interface PrivateRoutesProps {
  allowedPermissions: PERMISSION[];
}

const PrivateRoutes: React.FunctionComponent<PrivateRoutesProps> = ({ allowedPermissions }) => {
  const auth: PermissionProps = useUserAuthStatusCheckerByPermission();
  const permissions: any = auth?.permissions;

  const location = useLocation();
  const intersectionOFAllowedAndExistingRoles = permissions.filter((value: any) =>
    allowedPermissions.includes(value)
  );

  if (!auth.token) {
    return <Navigate to={LOGIN_PAGE_URL} state={{ from: location }} replace />;
  }

  return auth?.permissions.length > 0 && intersectionOFAllowedAndExistingRoles.length > 0 ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PrivateRoutes;
