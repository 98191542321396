import * as React from 'react';
import { Breadcrumbs as BreadcrumbsMaterial } from '@mui/material';
import { LinkWithIndirectRouting } from 'components/linkWithConfirm';
import clsx from 'clsx';

import style from './breadcrumbs.module.scss';

export interface BreadcrumbItem {
  path: string;
  name: string;
}

export interface BreadcrumbsProps {
  breadcrumbItems: BreadcrumbItem[];
  breadcrumbLastItem: string;
  withBackground?: boolean;
}
const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = ({
  breadcrumbItems,
  breadcrumbLastItem,
  withBackground = false
}) => {
  return (
    <BreadcrumbsMaterial
      aria-label="breadcrumb"
      className={clsx(style.breadcrumbs, withBackground && style.withBackground)}
      separator={
        <span className={clsx('material-icons-outlined', style.separator)}>arrow_forward_ios</span>
      }
    >
      {breadcrumbItems?.map((item) => (
        <LinkWithIndirectRouting key={item.path} path={item.path} content={<>{item.name}</>} />
      ))}
      <div className={style.last}>{breadcrumbLastItem}</div>
    </BreadcrumbsMaterial>
  );
};

export default Breadcrumbs;
