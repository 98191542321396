import { FC } from 'react';
import { Breadcrumbs } from 'components/breadcrumbs';
import { BreadcrumbsProps } from 'components/breadcrumbs/breadcrumbs';
import clsx from 'clsx';

import style from './organizationSubHeader.module.scss';
import { Title } from '../../title';

interface OrganizationSubHeaderProps {
  title: string;
  breadcrumbRouting?: BreadcrumbsProps;
}
const OrganizationSubHeader: FC<OrganizationSubHeaderProps> = ({ title, breadcrumbRouting }) => {
  return (
    <div
      className={clsx(style.subHeaderContainer, breadcrumbRouting && style.subHeaderContainerTitle)}
      data-test-id="page-subheader"
    >
      {breadcrumbRouting && (
        <Breadcrumbs
          breadcrumbItems={breadcrumbRouting.breadcrumbItems}
          breadcrumbLastItem={breadcrumbRouting.breadcrumbLastItem}
        />
      )}
      <div className={style.title}>
        <Title type="h1" text={title} />
      </div>
    </div>
  );
};

export default OrganizationSubHeader;
