import { FC } from 'react';
import { Popper as PopperMaterial, ClickAwayListener } from '@mui/material';
import clsx from 'clsx';

import { Title } from '../title';
import style from './popper.module.scss';

interface Props {
  anchorEl: null | HTMLElement;
  title?: string;
  children: JSX.Element;
  closeIcon?: boolean;
  onClose: () => void;
  open: boolean;
}

const Popper: FC<Props> = ({ anchorEl, title, children, closeIcon, onClose, open }) => {
  return (
    <PopperMaterial open={open} anchorEl={anchorEl} disablePortal={true} className={style.popper}>
      <ClickAwayListener onClickAway={onClose}>
        <div className={style.content}>
          {title && (
            <div className={style.header}>
              <Title type="h1" text={title} />
              {closeIcon && (
                <span className={clsx('material-icons', style.closeIcon)} onClick={onClose}>
                  logout
                </span>
              )}
            </div>
          )}
          <div>{children}</div>
        </div>
      </ClickAwayListener>
    </PopperMaterial>
  );
};

export default Popper;
