import { ComponentProps, useRef, useState } from 'react';
import { Autocomplete } from 'components/autocomplete';
import { Tag } from 'components/tag';
import debounce from 'lodash.debounce';
import { getZipCodesAutocomplete, ZipCodesAutocompleteResponse } from 'store/data/dataActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';

type AutocompleteProps = ComponentProps<typeof Autocomplete>;

type Props = Pick<
  AutocompleteProps,
  'onChange' | 'onBlur' | 'value' | 'disabled' | 'multiple' | 'name'
> & {
  value: ZipCodesAutocompleteResponse[number] | ZipCodesAutocompleteResponse | null;
  placeholder?: string;
};

const ZipCodeAutocomplete = ({
  onChange,
  onBlur,
  value,
  disabled,
  multiple,
  name,
  placeholder = 'Enter ZIP Codes'
}: Props) => {
  const [zipCodeInputValue, setZipCodeInputValue] = useState<string>('');
  const zipCodes = useAppSelector((state) => state.data.zipCodesAutocomplete);
  const dispatch = useAppDispatch();

  const debouncedFetchZipCodes = useRef(
    debounce(async (term) => {
      dispatch(getZipCodesAutocomplete({ term }));
    }, 300)
  ).current;

  const handleOnZipCodeInputChange = async (_: any, zipCode: string, reason: string) => {
    setZipCodeInputValue(zipCode);
    if (reason === 'reset') {
      return;
    }
    if (zipCode === '' || zipCode == null) {
      return;
    }
    await debouncedFetchZipCodes(zipCode);
  };

  return (
    <Autocomplete
      disabled={disabled}
      multiple={multiple}
      loadingText="Loading..."
      loading={zipCodes.loading}
      onChange={onChange}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => {
          const tagProps = getTagProps({ index });
          return <Tag key={tagProps.key} onDelete={tagProps.onDelete} label={option?.name} />;
        })
      }
      name={name}
      placeholder={placeholder}
      onBlur={onBlur}
      onInputChange={handleOnZipCodeInputChange}
      inputValue={zipCodeInputValue}
      value={value}
      isOptionEqualToValue={(option, zipCode) => {
        return option.id === zipCode.id;
      }}
      options={zipCodes.data}
      getOptionLabel={(option) => option.name}
    />
  );
};

export default ZipCodeAutocomplete;
