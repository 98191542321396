import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SlotInfo } from 'react-big-calendar';
import { notifyUserError, notifyUserInfo, notifyUserSuccess } from 'utils/notifications';

import {
  createEnrollmentCalendar,
  deleteEnrollmentCalendar,
  getEnrollmentCalendar,
  getEnrollmentChws,
  getEnrollmentGoalsTemplate,
  getEnrollmentNotConfigured,
  getEnrollmentProgram,
  getEnrollmentPrograms,
  getEnrollmentProgramsByChw,
  getEnrollmentUsersList,
  getTrackerBar,
  getUsersImageGoals,
  putEnrollmentNotConfigured,
  putEnrollmentProgram,
  putEnrollmentUsersList,
  searchEnrollmentCHW,
  searchEnrollmentCHWEvents,
  updateEnrollmentCalendar
} from './enrollmentActions';

export interface EnrollmentProgramDTO {
  id: number;
  name: string;
  eligibleStatuses: string[];
  status: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  valid: boolean;
  autoConnectFrequencyMinutes: number;
  typicalEnrollmentRate: number;
}

export interface EnrollmentNotConfigureProgramDTO {
  organizationId: number;
  programId: number;
  programName: string;
  enrollmentGoal: number | string;
  history: EnrollmentNotConfiguredHistories[];
}

export interface TrackerBarDTO {
  startDate: string;
  endDate: string;
  enrollmentGoal: number;
  actualCaseload: number;
  expectedCaseload: number;
  hasRequiredData: boolean;
}

export interface EnrollmentNotConfiguredHistories {
  startDate?: string;
  enrollmentGoal?: number;
  user: string;
  userProfileImageKey: string;
  createdAt: string;
}

export interface EnrollmentNotConfiguredDTO {
  startDate: string;
  organizationId: number;
  history: EnrollmentNotConfiguredHistories[];
  programs: EnrollmentNotConfigureProgramDTO[];
  hasAllData: boolean;
}

export interface EnrollmentCommunityHealthWorkersDTO {
  id: number;
  name: string;
}

export interface EnrollmentUsersListItem {
  userId: number;
  chwName: string;
  isActive: boolean;
  currentCaseload: number;
  actualCaseload: number;
  expectedCaseload: number;
  profileImageKey: string;
  orderNum: number;
  profileImageUrl?: string;
  maximumRecommendedCaseload: number;
}

export interface EnrollmentUsersListDTO {
  organizationId: number;
  programId: number;
  dateOfCallInitiator: string;
  startDate: string;
  endDate: string;
  enrollmentGoal: number;
  useDefaultChwOrdering: boolean;
  chwDetails: EnrollmentUsersListItem[];
  isLoading: boolean;
}

type RecurringType = 'DAY' | 'WEEK' | 'MONTH';

type EventType = 'SHIFT' | 'UNAVAILABLE';

export interface EnrollmentCalendarSideModalDataProps {
  chwName?: string;
  userId: number;
  organizationId: number;
  programId: number;
  eventType: EventType;
  startDate: string;
  endDate: string | null;
  startTime: string;
  endTime: string;
  location: string;
  note: string;
  recurringType: RecurringType;
  separationCount: number;
  expectedEnrollment?: number;
  updateType?: 'CURRENT' | 'NEXT_OCCURRENCES';
  eventId?: number;
  exceptionId?: number;
  autoConnectFrequencyMinutes?: number;
  typicalEnrollmentRate?: number;
}

type CHWItem = {
  userId: number;
  chwName: string;
  programName: string;
  expectedEnrollment: number;
  active: boolean;
};

export type ChwEvent = {
  allDay: boolean;
  start: string;
  end: string;
  title: string;
  isDraggable: boolean;
  data: {
    eventId: number;
    exceptionId: number;
    recurringType: RecurringType;
    userId: number;
    chwName: string;
    note: string;
    start: string;
    end: string;
    eventType: EventType;
    title: string;
    expectedEnrollment: number;
    separationCount: number;
    programName: string;
    programId: string;
    autoConnectFrequencyMinutes: number;
    typicalEnrollmentRate: number;
  };
};

export type CHW = {
  userId: number;
  chwName: string;
  programName: string;
  expectedEnrollment: number;
  active: boolean;
};

export type CHWByProgramId = {
  id: number;
  programId: number;
  name: string;
  profileImageKey: string;
};

export type SelectedSlot = Pick<SlotInfo, 'start' | 'end' | 'resourceId'> & { id: string };

export interface EnrollmentSliceProps {
  enrollmentList: EnrollmentProgramDTO[];
  enrollmentProgramsByChw: EnrollmentProgramDTO[];
  enrollmentListNotConfigured: EnrollmentNotConfiguredDTO | null;
  enrollmentListCommunityHealthWorkers: EnrollmentCommunityHealthWorkersDTO[];
  enrollmentUsersList: EnrollmentUsersListDTO;
  enrollmentProgram: EnrollmentNotConfigureProgramDTO | null;
  trackerBar: TrackerBarDTO | null;
  enrollmentTemplate: any;
  addEditCalendarEventSideModalOpen: boolean;
  enrollmentCalendarSideModalData: EnrollmentCalendarSideModalDataProps | null;
  calendarEvents: any;
  selectedSlot: SelectedSlot | null;
  currentDateInCalendar: string | undefined;
  chwList: {
    loading: boolean;
    data: CHWItem[];
  };
  chwEvents: {
    offset: number;
    content: ChwEvent[];
    loading: boolean;
  };
  chwListByProgramId: CHWByProgramId[];
  selectedChw: CHW | null;
  autoConnectFrequencyMinutes: number | undefined;
  typicalEnrollmentRate: number | undefined;
}

const initialState: EnrollmentSliceProps = {
  enrollmentList: [],
  enrollmentProgramsByChw: [],
  enrollmentListNotConfigured: null,
  enrollmentListCommunityHealthWorkers: [],
  enrollmentUsersList: {
    organizationId: 0,
    programId: 0,
    dateOfCallInitiator: 'X',
    startDate: '',
    endDate: '',
    enrollmentGoal: 0,
    useDefaultChwOrdering: false,
    chwDetails: [],
    isLoading: true
  },
  enrollmentProgram: null,
  trackerBar: null,
  enrollmentTemplate: null,
  addEditCalendarEventSideModalOpen: false,
  selectedSlot: null,
  calendarEvents: [],
  currentDateInCalendar: undefined,
  enrollmentCalendarSideModalData: null,
  chwList: {
    loading: false,
    data: []
  },
  chwListByProgramId: [],
  chwEvents: {
    offset: 0,
    loading: false,
    content: []
  },
  selectedChw: null,
  autoConnectFrequencyMinutes: undefined,
  typicalEnrollmentRate: undefined
};

export const enrollmentSlice = createSlice({
  name: 'enrollmentSlice',
  initialState,
  reducers: {
    setExpectedEnrollmentData: (state, action) => {
      state.autoConnectFrequencyMinutes = action.payload.autoConnectFrequencyMinutes;
      state.typicalEnrollmentRate = action.payload.typicalEnrollmentRate;
    },
    setCurrentDateInCalendar: (state, action) => {
      state.currentDateInCalendar = action.payload;
    },
    setCalendarEvents: (state, action) => {
      state.calendarEvents = action.payload;
    },
    setProgramId: (state, action) => {
      if (state.enrollmentProgram) {
        state.enrollmentProgram.programId = action.payload;
      }
    },
    setAddEditCalendarEventSideModalOpen: (state, action) => {
      state.addEditCalendarEventSideModalOpen = action.payload;
      if (!action.payload) {
        state.selectedSlot = null;
        state.enrollmentCalendarSideModalData = null;
      }
    },
    updateSideModalData: (state, action) => {
      state.enrollmentCalendarSideModalData = action.payload;
    },
    setChw: (state, action: PayloadAction<CHW | null>) => {
      state.selectedChw = action.payload;
    },
    clearChw: (state) => {
      state.selectedChw = null;
      state.chwEvents.offset = 0;
    },
    setSelectedSlot: (state, action: PayloadAction<SelectedSlot | null>) => {
      state.selectedSlot = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnrollmentPrograms.fulfilled, (state: any, { payload }: any) => {
        state.enrollmentList = payload;
      })
      .addCase(getEnrollmentPrograms.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getEnrollmentProgramsByChw.fulfilled, (state, { payload }: any) => {
        state.enrollmentProgramsByChw = payload;
      })
      .addCase(getEnrollmentProgramsByChw.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getEnrollmentNotConfigured.fulfilled, (state: any, { payload }: any) => {
        state.enrollmentListNotConfigured = payload;
      })
      .addCase(getEnrollmentNotConfigured.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putEnrollmentNotConfigured.fulfilled, (state: any, { payload }: any) => {
        state.enrollmentListNotConfigured = {
          ...state.enrollmentListNotConfigured,
          startDate: payload.startDate
        };
      })
      .addCase(putEnrollmentNotConfigured.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getEnrollmentProgram.fulfilled, (state: any, { payload }: any) => {
        state.enrollmentProgram = payload;
        state.enrollmentUsersList.chwDetails = [];
        state.enrollmentUsersList.isLoading = true;
      })
      .addCase(getEnrollmentProgram.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(putEnrollmentProgram.fulfilled, (state: any, { payload }: any) => {
        const programs =
          state.enrollmentListNotConfigured?.programs.length > 0 &&
          state.enrollmentListNotConfigured?.programs.map(
            (item: EnrollmentNotConfigureProgramDTO) => {
              if (item.programId === payload.programId) {
                return payload;
              }
              return item;
            }
          );
        state.enrollmentListNotConfigured = {
          ...state.enrollmentListNotConfigured,
          programs
        };
      })
      .addCase(putEnrollmentProgram.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getTrackerBar.fulfilled, (state: any, { payload }: any) => {
        state.trackerBar = payload;
      })
      .addCase(getTrackerBar.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getEnrollmentGoalsTemplate.fulfilled, (state: any, { payload }: any) => {
        state.enrollmentTemplate = URL.createObjectURL(new Blob([payload]));
      })
      .addCase(getEnrollmentGoalsTemplate.rejected, (state: any, { payload }: any) => {
        notifyUserError('Failed to download goals template.');
      })
      .addCase(getEnrollmentUsersList.fulfilled, (state: any, { payload }: any) => {
        const chwDetails = payload.chwDetails.map((a: EnrollmentUsersListItem) => {
          if (state.enrollmentUsersList.chwDetails.length > 0) {
            const found = state.enrollmentUsersList.chwDetails.find(
              (b: { userId: number }) => b.userId === a.userId
            );
            return { ...a, profileImageUrl: found?.profileImageUrl };
          }
          return a;
        });

        if (chwDetails.some((a: EnrollmentUsersListItem) => a.orderNum === null)) {
          let previousOrderNum = 0;
          for (const chwDetail of chwDetails) {
            if (chwDetail.orderNum !== null) {
              previousOrderNum = chwDetail.orderNum;
            } else {
              chwDetail.orderNum = ++previousOrderNum;
            }
          }
        }
        state.enrollmentUsersList = {
          ...payload,
          chwDetails: chwDetails,
          isLoading: false
        };
      })
      .addCase(getEnrollmentUsersList.rejected, (state: any, { payload }: any) => {
        state.enrollmentUsersList.chwDetails = [];
        state.enrollmentUsersList.isLoading = false;
        notifyUserError(payload);
      })
      .addCase(putEnrollmentUsersList.fulfilled, (state: any, { payload }: any) => {
        const chwDetails = payload.chwDetails.map((a: EnrollmentUsersListItem) => {
          if (state.enrollmentUsersList.chwDetails.length > 0) {
            const found = state.enrollmentUsersList.chwDetails.find(
              (b: { userId: number }) => b.userId === a.userId
            );
            return { ...a, profileImageUrl: found?.profileImageUrl };
          }
          return a;
        });

        if (chwDetails.some((a: EnrollmentUsersListItem) => a.orderNum === null)) {
          let previousOrderNum = 0;
          for (const chwDetail of chwDetails) {
            if (chwDetail.orderNum !== null) {
              previousOrderNum = chwDetail.orderNum;
            } else {
              chwDetail.orderNum = ++previousOrderNum;
            }
          }
        }
        state.enrollmentUsersList = {
          ...payload,
          chwDetails: chwDetails,
          isLoading: false
        };
      })
      .addCase(putEnrollmentUsersList.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getUsersImageGoals.fulfilled, (state: any, { payload }: any) => {
        const found = state.enrollmentUsersList.chwDetails.findIndex(
          (a: { userId: number }) => a.userId === payload.userId
        );
        const newArr = state.enrollmentUsersList.chwDetails;
        newArr[found].profileImageUrl = URL.createObjectURL(new Blob([payload.data]));
        state.enrollmentUsersList = { ...state.enrollmentUsersList, chwDetails: newArr };
      })
      .addCase(getUsersImageGoals.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(getEnrollmentCalendar.fulfilled, (state: any, { payload }: any) => {
        // state.enrollmentPlannerCalendar = payload;
      })
      .addCase(getEnrollmentCalendar.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(createEnrollmentCalendar.fulfilled, (state, { payload, meta }) => {
        state.enrollmentCalendarSideModalData = payload;
        state.addEditCalendarEventSideModalOpen = false;
        state.enrollmentCalendarSideModalData = null;
        state.selectedSlot = null;
        notifyUserSuccess(`Event successfully added`);
      })
      .addCase(createEnrollmentCalendar.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(updateEnrollmentCalendar.fulfilled, (state, { payload }) => {
        state.enrollmentCalendarSideModalData = payload;
        state.addEditCalendarEventSideModalOpen = false;
        state.enrollmentCalendarSideModalData = null;
        notifyUserSuccess(`Event successfully changed`);
      })
      .addCase(updateEnrollmentCalendar.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(deleteEnrollmentCalendar.fulfilled, (state: any, { payload }: any) => {
        state.enrollmentCalendarSideModalData = null;
        state.addEditCalendarEventSideModalOpen = false;
        if (state.chwEvents.content.length > 0) {
          const arrContent = [...state.chwEvents.content];
          arrContent.splice(
            arrContent.findIndex((i: any) => {
              return i.data.start === payload.start;
            }),
            1
          );
          state.chwEvents = { ...state.chwEvents, content: arrContent };
        }

        notifyUserSuccess(`Event successfully deleted.`);
      })
      .addCase(deleteEnrollmentCalendar.rejected, (state: any, { payload }: any) => {
        notifyUserError(payload);
      })
      .addCase(searchEnrollmentCHW.pending, (state) => {
        state.chwList = {
          data: [],
          loading: true
        };
      })
      .addCase(searchEnrollmentCHW.fulfilled, (state, action) => {
        // BE sends duplicate CHWs if they are assigned to multiple programs
        const ids = action.payload.map(({ userId }: { userId: string }) => userId);
        const deduplicated = action.payload.filter(
          ({ userId }: { userId: string }, index: number) => !ids.includes(userId, index + 1)
        );
        state.chwList = {
          data: deduplicated,
          loading: false
        };
      })
      .addCase(searchEnrollmentCHW.rejected, (state, payload: any) => {
        notifyUserError(payload);
      })
      .addCase(getEnrollmentChws.pending, (state) => {
        state.chwListByProgramId = [];
      })
      .addCase(getEnrollmentChws.fulfilled, (state, action) => {
        // BE sends duplicate CHWs if they are assigned to multiple programs
        const ids = action.payload.content.map(({ id }: { id: string }) => id);
        const deduplicated = action.payload.content.filter(
          ({ id }: { id: string }, index: number) => !ids.includes(id, index + 1)
        );

        state.chwListByProgramId = deduplicated;
      })
      .addCase(searchEnrollmentCHWEvents.pending, (state) => {
        state.chwEvents.loading = true;
      })
      .addCase(searchEnrollmentCHWEvents.fulfilled, (state, { payload, meta }) => {
        if (payload.content.length === 0 && meta.arg.direction) {
          notifyUserInfo(
            meta.arg.direction === 'next'
              ? 'You are at the bottom of the list!'
              : 'You are at the top of the list!'
          );
          state.chwEvents.loading = false;
          return;
        }
        state.chwEvents = {
          ...payload,
          offset: meta.arg.offset,
          loading: false
        };
      })
      .addCase(searchEnrollmentCHWEvents.rejected, (state, { payload, meta }) => {
        notifyUserError('Something went wrong, please try again later.');
      });
  }
});
export const {
  setExpectedEnrollmentData,
  setCurrentDateInCalendar,
  setCalendarEvents,
  setAddEditCalendarEventSideModalOpen,
  updateSideModalData,
  clearChw,
  setChw,
  setProgramId,
  setSelectedSlot
} = enrollmentSlice.actions;
export default enrollmentSlice.reducer;
