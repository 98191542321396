import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { axiosPrivate } from 'modules/networkTools/networkTools';

interface ClientNotesDTO {
  clientId: string;
  organizationId: number;
  types?: string;
  page?: number;
  size?: number;
}

export const getClientNotes = createAsyncThunk(
  'clients/getClientNotes',
  async ({ clientId, organizationId, page, size, types }: ClientNotesDTO, { rejectWithValue }) => {
    const typesFiltered = types ? `&types=${types}` : '';
    try {
      const response = await axiosPrivate.get(
        `clients/${clientId}/profile/notes?organizationId=${organizationId}&page=${page}&size=${size}${typesFiltered}`
      );

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getClientNote = createAsyncThunk(
  'clients/getClientNote',
  async (
    {
      clientId,
      organizationId,
      noteId
    }: {
      clientId: string;
      organizationId: number;
      noteId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.get(
        `clients/${clientId}/profile/notes/${noteId}?organizationId=${organizationId}`
      );

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postCreateNote = createAsyncThunk(
  'clients/postCreateNote',
  async (
    {
      clientId,
      note,
      draft,
      noteType,
      clientReached,
      dateOfEncounter,
      nextContact,
      typeOfSupport,
      timeSpent
    }: {
      clientId: string;
      organizationId: string;
      note: string;
      draft: boolean;
      noteType: string;
      clientReached: string;
      dateOfEncounter: string;
      nextContact: string;
      typeOfSupport: string[];
      timeSpent: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(`clients/${clientId}/profile/notes`, {
        noteType,
        clientReached,
        dateOfEncounter,
        nextContact,
        typeOfSupport,
        note,
        draft,
        timeSpent
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putUpdateNote = createAsyncThunk(
  'clients/putUpdateNote',
  async (
    {
      id,
      clientId,
      note,
      draft,
      noteType,
      clientReached,
      dateOfEncounter,
      nextContact,
      typeOfSupport,
      timeSpent
    }: {
      id: string;
      clientId: string;
      note: string;
      draft: boolean;
      noteType: string;
      clientReached: string;
      dateOfEncounter: string;
      nextContact: string;
      typeOfSupport: string[];
      timeSpent: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(
        `clients/${clientId}/profile/notes`,
        {
          id,
          noteType,
          clientReached,
          dateOfEncounter,
          nextContact,
          typeOfSupport,
          note,
          draft,
          timeSpent
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteNoteInput = createAsyncThunk(
  'clients/deleteNoteInput',
  async ({ clientId, noteId }: { clientId: string; noteId: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.delete(`clients/${clientId}/profile/notes/${noteId}`, {
        data: {
          clientId,
          noteId
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getAddendums = createAsyncThunk(
  'clients/getAddendums',
  async (
    {
      noteId
    }: {
      noteId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.get(`client/note/${noteId}/addendums`);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postCreateAddendum = createAsyncThunk(
  'clients/postCreateAddendum',
  async (
    {
      noteId,
      content
    }: {
      noteId: string;
      content: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.post(`client/note/${noteId}/addendum`, { content });

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putEditAddendum = createAsyncThunk(
  'clients/putEditAddendum',
  async (
    {
      addendumId,
      noteId,
      content
    }: {
      noteId: string;
      addendumId: number;
      content: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(`client/note/${noteId}/addendum/${addendumId}`, {
        content
      });

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchEditAddendum = createAsyncThunk(
  'clients/patchEditAddendum',
  async (
    {
      addendumId,
      noteId,
      content,
      status
    }: {
      status: 'FINAL' | 'DRAFT';
      noteId: string;
      addendumId: number;
      content: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`client/note/${noteId}/addendum/${addendumId}`, {
        content,
        status
      });

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteAddendum = createAsyncThunk(
  'clients/deleteAddendum',
  async (
    {
      addendumId,
      noteId
    }: {
      noteId: string;
      addendumId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.delete(`client/note/${noteId}/addendum/${addendumId}`);

      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUsersImageNotes = createAsyncThunk(
  'clients/getUsersImageNotes',
  async (
    {
      profileImageKey,
      noteId,
      notePopup,
      notesList,
      noteAddendums
    }: {
      profileImageKey: string;
      userId?: number | string;
      noteId?: string;
      notePopup?: boolean;
      notesList?: boolean;
      noteAddendums?: boolean;
    },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/user/profile-image?key=${profileImageKey}`;
    try {
      const response = await axiosPrivate.get(url, { responseType: 'blob' });
      return {
        data: response.data,
        noteId,
        notePopup,
        notesList,
        noteAddendums
      };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
