import { useMemo } from 'react';

import { getLsUserRoleObject } from '../../modules/networkTools/localStorageTokens';

export const isOnlyVisibleToUserWithPermissions = (allowedRoles: string[]): boolean => {
  let isAllowed = false;

  const roleObject = getLsUserRoleObject();

  const permissionsFromObject = roleObject?.currentOrganization?.permissions || [];

  // IS ANY ROLE FROM ALLOWED LISTED IN EXISTING ON THE ROLE OBJECT
  const isAnyExistingRoleAllowedArray = allowedRoles.filter((x) =>
    permissionsFromObject.includes(x)
  );

  isAllowed = isAnyExistingRoleAllowedArray.length > 0 ? true : false;
  return isAllowed;
};

export const useIsOnlyVisibleToUserWithPermissions = (allowedRoles: string[]): boolean => {
  const isVisible = useMemo(() => isOnlyVisibleToUserWithPermissions(allowedRoles), [allowedRoles]);

  return isVisible;
};
