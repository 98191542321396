import { ConfirmationModalContent } from 'components';
import { ModalPopup } from 'components/modal';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setConfirmationModal, setHasUnsavedWork } from 'store/organization/organizationSlice';
import { notifyUserError } from 'utils/notifications';

export interface LinkWithIndirectRoutingProps {}

const RouteChangeConfirmModal: React.FunctionComponent<LinkWithIndirectRoutingProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { linkToGo, openConfirmationModal, formik } = useAppSelector(
    (state) => state.organization.confirmableRouteChange
  );
  const isModalOpen = (modalOpen: boolean): void => {
    dispatch(setConfirmationModal(modalOpen));
  };

  const handleConfirm = async () => {
    if (!formik?.isValid) {
      notifyUserError(
        'Unable to save changes due to errors or missing information in required fields.'
      );
    } else {
      await formik?.submitForm?.();
      navigate(linkToGo ?? '');
    }
    isModalOpen(false);
  };

  return (
    <ModalPopup
      open={openConfirmationModal || false}
      handleClose={() => isModalOpen(false)}
      children={
        <ConfirmationModalContent
          title="You have unsaved changes"
          rejectButtonText="Discard changes"
          confirmButtonText="Save changes"
          handleConfirm={handleConfirm}
          handleReject={() => {
            dispatch(
              setHasUnsavedWork({
                hasUnsavedWork: false,
                formik: null
              })
            );
            isModalOpen(false);
            navigate(linkToGo || '');
          }}
        />
      }
    />
  );
};

export default RouteChangeConfirmModal;
