import * as React from 'react';

import style from './visibilityController.module.scss';
interface InputFieldProps {
  visible: boolean;
}

const VisibilityController: React.FunctionComponent<InputFieldProps> = ({ visible }) => {
  return (
    <div className={style.IconStyling}>
      {visible ? (
        <span className="material-icons">visibility</span>
      ) : (
        <span className="material-icons">visibility_off</span>
      )}
    </div>
  );
};

export default VisibilityController;
