import { FC } from 'react';

import style from './enrollmentPageWrapper.module.scss';

interface Props {
  pageContent: JSX.Element;
}

const EnrollmentPageWrapper: FC<Props> = ({ pageContent }) => {
  return (
    <>
      <div className={style.pageContainer}>
        <div className={style.pageContentContainerFullWidth}>{pageContent}</div>
      </div>
    </>
  );
};

export default EnrollmentPageWrapper;
