import { LOGIN_PAGE_URL } from 'constants/utils';

import clsx from 'clsx';
import { Logo } from 'components/logo';
import Title from 'components/title/title';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTimeout } from 'usehooks-ts';
import { notifyUserSuccess } from 'utils/notifications';

import style from './inviteExpired.module.scss';

interface InviteExpiredPageProps {}

const InviteExpiredPage: React.FunctionComponent<InviteExpiredPageProps> = () => {
  const navigate = useNavigate();
  const redirectTo = () => {
    navigate(LOGIN_PAGE_URL);
  };

  useTimeout(() => notifyUserSuccess('Redirecting to login page'), 500);
  useTimeout(redirectTo, 5000);

  return (
    <div className={clsx(style.inviteExpiredContainer)}>
      <div className={clsx(style.inviteExpiredInsideContainer)}>
        <div className={clsx(style.logoContainer)}>
          <Logo type="orange" />
        </div>
        <div className={clsx(style.textContainer)}>
          <Title text={'Invitation expired'} type={'h2'} />
          <p>This invitation has expired. Please contact IMPaCT for a new invitation.</p>
        </div>
      </div>
    </div>
  );
};

export default InviteExpiredPage;
