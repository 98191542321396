import { Title } from 'components';
import { FC } from 'react';
import clsx from 'clsx';

import { Button } from '../button';
import style from './confirmationBox.module.scss';

interface Props {
  handleConfirm?: any;
  handleReject?: any;
  rejectButtonText: string;
  confirmButtonText: string;
  title?: string;
  text?: string;
  children?: JSX.Element;
  disableConfirm?: boolean;
  customClassInsideContainer?: any;
}

const ConfirmationModalContent: FC<Props> = ({
  handleConfirm,
  handleReject,
  rejectButtonText,
  confirmButtonText,
  title,
  text,
  children,
  disableConfirm,
  customClassInsideContainer
}) => {
  return (
    <div className={clsx(style.confirmBoxContainer, customClassInsideContainer)}>
      {title && <Title type="h3" text={title || 'You have unsaved changes'} />}
      {text ?? (
        <div className={style.text}>If you proceed without saving, information might be lost</div>
      )}
      {children && <div>{children}</div>}
      <div className={style.buttonsSubmitReset}>
        <Button
          theme="transparent"
          text={rejectButtonText}
          handleOnClick={handleReject}
          type="button"
        />
        <Button
          theme="primary"
          text={confirmButtonText}
          handleOnClick={handleConfirm}
          type="button"
          disabled={disableConfirm}
        />
      </div>
    </div>
  );
};

export default ConfirmationModalContent;
