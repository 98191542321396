import { Autocomplete as MaterialAutocomplete } from '@mui/material';
import { InputField } from 'components/inputField';
import { ComponentProps } from 'react';

type Props<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = {
  placeholder: string;
  name: string;
  startAdornment?: React.ReactNode;
  rounded?: boolean;
  classnamesProps?: any;
  endAdornment?: React.ReactNode;
  onEndAdornmentClick?: () => void;
} & Omit<
  ComponentProps<typeof MaterialAutocomplete<T, Multiple, DisableClearable, FreeSolo>>,
  'renderInput'
>;

const Autocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  placeholder,
  name,
  startAdornment,
  rounded = false,
  classnamesProps = '',
  endAdornment,
  onEndAdornmentClick,
  ...props
}: Props<T, Multiple, DisableClearable, FreeSolo>) => {
  return (
    <MaterialAutocomplete
      {...props}
      loadingText="Loading..."
      renderInput={(params) => {
        return (
          <div ref={params.InputProps.ref}>
            <InputField
              rounded={rounded}
              tags={startAdornment ?? params.InputProps.startAdornment}
              disabled={params.inputProps.disabled}
              autoComplete="off"
              placeholder={placeholder}
              onFocus={params.inputProps.onFocus}
              name={name}
              onChange={params.inputProps.onChange}
              onBlur={params.inputProps.onBlur}
              value={params.inputProps.value}
              // @ts-expect-error ref is necessary here but it is not defined in material types
              ref={params.inputProps.ref}
              classnamesProps={classnamesProps}
              endAdornment={endAdornment}
              onEndAdornmentClick={onEndAdornmentClick}
            />
          </div>
        );
      }}
    />
  );
};

export default Autocomplete;
