import { OrganizationsListStatuses } from 'constants/data';

import { SelectChangeEvent } from '@mui/material';
import {
  Badge,
  ConfirmationModalContent,
  Dropdown,
  ModalPopup,
  StandardCheckbox
} from 'components';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  patchChangeOrganizationStatus,
  patchChangeProgramStatus
} from 'store/organization/organizationActions';
import { OrganizationsContentItem, ProgramContentItem } from 'store/organization/organizationSlice';
import { useIsOnlyVisibleToUserWithPermissions } from 'utils/hooks/useIsOnlyVisibleTo';
import { PERMISSION } from 'utils/protectedRoutes/userRolesAndPermissionTypes';

import style from './status.module.scss';

type Props =
  | {
      isProgram: boolean;
      isOrganization?: never;
      program: ProgramContentItem;
      organization?: never;
    }
  | {
      isProgram?: never;
      isOrganization: boolean;
      program?: never;
      organization: OrganizationsContentItem;
    };

const defaultStatuses = [
  {
    id: 'ACTIVE',
    name: 'Active'
  },
  {
    id: 'INACTIVE',
    name: 'Inactive'
  }
];

const Status: FC<Props> = ({ organization, program, isProgram, isOrganization }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [checkboxState, setcheckboxState] = useState({
    terminated: false,
    export: false
  });

  const hasPermissionToChangeOrgStatus = useIsOnlyVisibleToUserWithPermissions([
    PERMISSION.ORG_CHANGE_STATUS
  ]);
  const hasPermissionToChangeProgramStatus = useIsOnlyVisibleToUserWithPermissions([
    PERMISSION.PROGRAM_STATUS_MODIFICATION
  ]);
  const dispatch = useAppDispatch();
  const programs = useAppSelector((state) => state.organization.program.programsList.content);
  const status = organization?.status ?? program?.status ?? '';
  const hasMoreThanOneProgram = programs?.length > 1;

  const changeStatus = async (newStatus: string) => {
    if (isOrganization) {
      const response = await dispatch(
        patchChangeOrganizationStatus({
          data: {
            organizationID: Number(organization?.id),
            status: newStatus
          }
        })
      );

      if (response.meta.requestStatus === 'fulfilled') {
        setShowConfirmationModal(false);
      }
    } else if (isProgram) {
      const response = await dispatch(
        patchChangeProgramStatus({
          data: {
            programID: Number(program?.id),
            status: newStatus
          }
        })
      );

      if (response.meta.requestStatus === 'fulfilled') {
        setShowConfirmationModal(false);
      }
    }
  };

  const handleStatusChange = async (e: SelectChangeEvent<string | string[]>) => {
    if (e.target.value === OrganizationsListStatuses.INACTIVE.toUpperCase()) {
      setShowConfirmationModal(true);
    } else {
      await changeStatus(e.target.value as string);
    }
  };

  const handleInactivate = async () => {
    await changeStatus('INACTIVE');
  };

  const handleChangeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    setcheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked
    });
  };

  const isDisabled = useMemo(() => {
    return !Object.values(checkboxState).every((a) => a);
  }, [checkboxState]);

  const eligibleStatuses =
    status === 'INACTIVATION_PENDING'
      ? [
          {
            id: 'ACTIVE',
            name: 'Active'
          },
          {
            id: 'INACTIVATION_PENDING',
            name: 'Inactivation Pending'
          }
        ]
      : defaultStatuses;

  const StaticStatus = () => {
    return (
      <>
        {status === OrganizationsListStatuses.ACTIVE.toUpperCase() && (
          <Badge text={OrganizationsListStatuses.ACTIVE} type="success" size="small" />
        )}
        {status === OrganizationsListStatuses.INACTIVE.toUpperCase() && (
          <Badge text={OrganizationsListStatuses.INACTIVE} size="small" />
        )}
        {status === 'INACTIVATION_PENDING' && (
          <Badge
            text={OrganizationsListStatuses.INACTIVATION_PENDING}
            type="warning"
            size="small"
          />
        )}
      </>
    );
  };

  if (isOrganization) {
    if (
      !hasPermissionToChangeOrgStatus ||
      status === OrganizationsListStatuses.INACTIVE.toUpperCase()
    ) {
      return <StaticStatus />;
    }
  }

  if (isProgram) {
    if (
      !hasMoreThanOneProgram ||
      status === OrganizationsListStatuses.INACTIVE.toUpperCase() ||
      !hasPermissionToChangeProgramStatus
    ) {
      return <StaticStatus />;
    }
  }

  return (
    <>
      <Dropdown
        type={status === 'ACTIVE' ? 'success' : 'warning'}
        data={eligibleStatuses}
        value={status}
        onChange={handleStatusChange}
        rounded
        size="small"
      />
      <ModalPopup open={showConfirmationModal} handleClose={() => setShowConfirmationModal(false)}>
        <>
          {isProgram && (
            <ConfirmationModalContent
              confirmButtonText="Yes, Inactivate"
              rejectButtonText="Cancel"
              title="Are you sure you want to inactivate this program?"
              text="The customer will no long be able to assign clients to this program or view the program in the Enrollment Planner. Please make sure all CHWs assigned to this program have been reassigned."
              handleConfirm={handleInactivate}
              handleReject={() => setShowConfirmationModal(false)}
            />
          )}
          {isOrganization && (
            <ConfirmationModalContent
              confirmButtonText="Yes, Inactivate"
              rejectButtonText="Cancel"
              title="Are you sure you want to inactivate this Organization?"
              text="This will inactivate any active Customer users and active programs in the organization."
              disableConfirm={isDisabled}
              handleConfirm={handleInactivate}
              handleReject={() => setShowConfirmationModal(false)}
            >
              <div className={style.checkboxWrap}>
                <StandardCheckbox
                  name="terminated"
                  onChange={handleChangeCheckbox}
                  value={checkboxState.terminated}
                  label="Has the contract been terminated?"
                />
                <StandardCheckbox
                  name="export"
                  onChange={handleChangeCheckbox}
                  value={checkboxState.export}
                  label="Has the customer been offered a data export?"
                />
              </div>
            </ConfirmationModalContent>
          )}
        </>
      </ModalPopup>
    </>
  );
};

export default Status;
