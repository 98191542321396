import * as React from 'react';

import style from './labelComponent.module.scss';

interface LabelComponentProps {
  text: string;
  mandatory?: boolean | null;
}

export const LabelComponent: React.FunctionComponent<LabelComponentProps> = ({
  text,
  mandatory = false
}) => {
  return (
    <label htmlFor="name" className={style.labelContainer}>
      {text} {mandatory && <span>*</span>}
    </label>
  );
};

export default LabelComponent;
