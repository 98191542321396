import {
  Autocomplete,
  ErrorContainer,
  InputField,
  LabelComponent,
  Stack,
  StandardCheckbox
} from 'components';
import { useFormikContext } from 'formik';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { getStatesAutocomplete } from 'store/clients/clientsActions';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { FormValues } from '../../clientForm';
import SectionTitle from '../sectionTitle/sectionTitle';

type Props = {
  context: 'primaryAddress' | 'currentLocation';
};

const PrimaryAddress = (props: Props) => {
  const [stateInputValue, setStateInputValue] = useState<string>('');
  const formik = useFormikContext<FormValues>();
  const states = useAppSelector((state) => state.clients.statesAutocomplete);
  const dispatch = useAppDispatch();
  const context = formik.values.sameAsPrimaryAddress ? 'primaryAddress' : props.context;

  const debouncedFetchStates = useRef(
    debounce(
      async ({ state, city, zipCode }: { zipCode?: string; city?: string; state?: string }) => {
        dispatch(getStatesAutocomplete({ term: state, city, zipCode }));
      },
      300
    )
  ).current;

  useEffect(() => {
    debouncedFetchStates({ state: stateInputValue });
    return () => {
      debouncedFetchStates.cancel();
    };
  }, [debouncedFetchStates]);

  const handleOnStateInputChange = async (_: any, value: string, reason: string) => {
    setStateInputValue(value);
    if (reason === 'reset') {
      return;
    }
    if ((value === '' || value == null) && formik.values[context].state) {
      return;
    }
    await debouncedFetchStates({
      state: value
    });
  };

  return (
    <Stack
      gap="24px"
      data-test-id={
        props.context === 'currentLocation' ? 'profile-current-location' : 'profile-primary-address'
      }
    >
      <SectionTitle>
        {props.context === 'currentLocation' ? 'Current Location' : 'Primary address'}
      </SectionTitle>
      <Stack>
        {props.context === 'currentLocation' && (
          <Stack mb="16px">
            <StandardCheckbox
              label="Same as Primary Address"
              value={formik.values.sameAsPrimaryAddress}
              onChange={formik.handleChange}
              name="sameAsPrimaryAddress"
              onBlur={formik.handleBlur}
            />
          </Stack>
        )}
        <fieldset
          disabled={props.context === 'currentLocation' && formik.values.sameAsPrimaryAddress}
        >
          <Stack width="100%" display="flex" flexDirection="row" gap="24px">
            <Stack width="100%">
              <LabelComponent text="Street Address or Location" />
              <InputField
                disabled={props.context === 'currentLocation' && formik.values.sameAsPrimaryAddress}
                value={formik.values[context].streetAddress ?? ''}
                name={`${context}.streetAddress`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="123 Street Name"
              />
              <ErrorContainer
                visible={
                  formik.touched[props.context]?.streetAddress &&
                  formik.errors[props.context]?.streetAddress
                }
                errors={formik.errors[props.context]}
                name="streetAddress"
              />
            </Stack>
            <Stack width="100%">
              <LabelComponent text="Street Address Line 2" />
              <InputField
                disabled={props.context === 'currentLocation' && formik.values.sameAsPrimaryAddress}
                value={formik.values[context].streetAddress2 ?? ''}
                name={`${context}.streetAddress2`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Apt/Unit #"
              />
              <ErrorContainer
                visible={
                  formik.touched[props.context]?.streetAddress2 &&
                  formik.errors[props.context]?.streetAddress2
                }
                errors={formik.errors[props.context]}
                name="streetAddress2"
              />
            </Stack>
          </Stack>
          <Stack width="100%" display="flex" flexDirection="row" gap="24px">
            <Stack width="100%">
              <LabelComponent text="City" />
              <InputField
                disabled={props.context === 'currentLocation' && formik.values.sameAsPrimaryAddress}
                value={formik.values[context].city ?? ''}
                name={`${context}.city`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="City"
              />
              <ErrorContainer
                visible={formik.touched[props.context]?.city && formik.errors[props.context]?.city}
                errors={formik.errors[props.context]}
                name="city"
              />
            </Stack>
            <Stack width="100%">
              <LabelComponent text="State" />
              <Autocomplete
                disabled={props.context === 'currentLocation' && formik.values.sameAsPrimaryAddress}
                openOnFocus
                loadingText="Loading..."
                loading={states.loading}
                onChange={(_, value) => {
                  formik.setFieldValue(`${context}.state`, value ?? '');
                }}
                onBlur={formik.handleBlur}
                onInputChange={handleOnStateInputChange}
                inputValue={stateInputValue}
                value={formik.values[context].state}
                name={`${context}.state`}
                placeholder="State"
                options={states.data.map((option) => option.name)}
              />
              <ErrorContainer
                visible={
                  formik.touched[props.context]?.state && formik.errors[props.context]?.state
                }
                errors={formik.errors[props.context]}
                name="state"
              />
            </Stack>

            <Stack width="100%">
              <LabelComponent text="ZIP" />
              <InputField
                disabled={props.context === 'currentLocation' && formik.values.sameAsPrimaryAddress}
                value={formik.values[context].zipCode ?? ''}
                name={`${context}.zipCode`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="ZIP"
              />
              <ErrorContainer
                visible={
                  formik.touched[props.context]?.zipCode && formik.errors[props.context]?.zipCode
                }
                errors={formik.errors[props.context]}
                name="zipCode"
              />
            </Stack>
          </Stack>

          <Stack width="100%" display="flex" flexDirection="row" gap="24px">
            <Stack width="100%">
              <LabelComponent text="Building" />
              <InputField
                disabled={props.context === 'currentLocation' && formik.values.sameAsPrimaryAddress}
                value={formik.values[context].building ?? ''}
                name={`${context}.building`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Building"
              />
              <ErrorContainer
                visible={formik.touched[context]?.building && formik.errors[context]?.building}
                errors={formik.errors[context]}
                name="building"
              />
            </Stack>
            <Stack width="100%">
              <LabelComponent text="Floor" />
              <InputField
                disabled={props.context === 'currentLocation' && formik.values.sameAsPrimaryAddress}
                value={formik.values[context].floor ?? ''}
                name={`${context}.floor`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Floor"
              />
              <ErrorContainer
                visible={formik.touched[context]?.floor && formik.errors[context]?.floor}
                errors={formik.errors[context]}
                name="floor"
              />
            </Stack>
            <Stack width="100%">
              <LabelComponent text="Room" />
              <InputField
                disabled={props.context === 'currentLocation' && formik.values.sameAsPrimaryAddress}
                value={formik.values[context].room ?? ''}
                name={`${context}.room`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Room"
              />
              <ErrorContainer
                visible={formik.touched[context]?.room && formik.errors[context]?.room}
                errors={formik.errors[context]}
                name="room"
              />
            </Stack>
          </Stack>
        </fieldset>
      </Stack>
    </Stack>
  );
};

export default PrimaryAddress;
