import clsx from 'clsx';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import style from './button.module.scss';

interface ButtonProps {
  handleOnClick?: (e: any) => void;
  disabled?: boolean;
  text?: string;
  customStyleClass?: any;
  type?: 'button' | 'submit' | 'reset';
  shape?: 'circle';
  theme?: 'primary' | 'secondary' | 'transparent' | 'borderless';
  size?: 'small' | 'extraSmall';
  redirectToHref?: string | null;
  redirectToHrefExternal?: string | null;
  icon?: JSX.Element;
  testIdAttr?: string;
  form?: string;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  handleOnClick,
  disabled,
  text,
  customStyleClass,
  type,
  shape,
  size,
  theme = null,
  redirectToHref,
  redirectToHrefExternal,
  icon,
  testIdAttr,
  form
}) => {
  const navigate = useNavigate();
  const handleOnClickLocal = (e: any) => {
    if (redirectToHrefExternal) {
      window.open(redirectToHrefExternal, '_blank');
    } else if (redirectToHref) {
      navigate(redirectToHref);
    } else {
      if (handleOnClick) handleOnClick(e);
    }
  };

  return (
    <>
      <button
        onClick={handleOnClickLocal}
        disabled={disabled}
        type={type}
        className={clsx(
          style.buttonStyle,
          theme === 'primary' && style.buttonStylePrimary,
          theme === 'secondary' && style.buttonStyleSecondary,
          theme === 'transparent' && style.buttonStyleTransparent,
          theme === 'borderless' && style.buttonStyleBorderless,
          shape === 'circle' && style.buttonStyleCircle,
          size === 'small' && style.buttonStyleSmall,
          size === 'extraSmall' && style.buttonStyleExtraSmall,
          icon && !text && style.buttonWithIcon,
          customStyleClass
        )}
        data-test-id={testIdAttr}
        form={form}
      >
        <span className={style.btnInsideSpan}>
          {icon}
          {text}
        </span>
      </button>
    </>
  );
};

export default Button;
