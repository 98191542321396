import clsx from 'clsx';
import { FC } from 'react';

import style from './switchButtons.module.scss';

interface SwitchButtonsProps {
  handleOnClick: any;
  options: { id: string | boolean; name: string }[];
  value: string | string[] | undefined | null | boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;
}

const SwitchButtons: FC<SwitchButtonsProps> = ({
  handleOnClick,
  options,
  value,
  disabled = false,
  fullWidth,
  multiple
}) => {
  const handleOnClickLocal = (eventType: string | boolean) => {
    if (multiple) {
      let updatedValue;
      if (Array.isArray(value)) {
        if (value.includes(eventType as string)) {
          updatedValue = value.filter((item) => item !== eventType);
        } else {
          updatedValue = [...value, eventType];
        }
      } else {
        updatedValue = [eventType];
      }
      return handleOnClick(updatedValue);
    }

    handleOnClick(eventType);
  };

  return (
    <div className={clsx(style.switchButtons)}>
      {options.map((a, index) => (
        <button
          disabled={disabled}
          key={index}
          type="button"
          className={clsx(
            (multiple
              ? Array.isArray(value) && value.length > 0 && value.some((b) => b === a.id)
              : value === a.id) && style.selected,
            fullWidth && style.fullWidth
          )}
          onClick={() => handleOnClickLocal(a.id)}
        >
          {a.name}
        </button>
      ))}
    </div>
  );
};

export default SwitchButtons;
