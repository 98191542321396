import { Chip } from '@mui/material';
import { ComponentProps } from 'react';

type Props = {
  label: string;
  onDelete?: (event: any) => void;
  blueSkin?: boolean;
} & ComponentProps<typeof Chip>;

const Tag = ({ onDelete, label, size = 'small', blueSkin, ...props }: Props) => {
  return (
    <Chip
      {...props}
      sx={{
        backgroundColor: blueSkin ? '#EFF9FB' : '#F8FAFC',
        borderRadius: '4px',
        border: blueSkin ? '1px solid #5EBBCE' : '1px solid #E2E8F0',
        fontFamily: '"Nunito", sans-serif',
        height: blueSkin ? '25px' : '32px',
        '& .MuiChip-label': {
          fontSize: size === 'small' ? '12px' : '14px',
          padding: blueSkin ? '0 8px' : '0 12px',
          color: blueSkin ? '#096F84' : 'auto'
        },
        '& .MuiChip-deleteIcon': {
          fontSize: blueSkin ? '16px' : '22px',
          color: blueSkin ? '#3AA0B6' : 'rgba(0, 0, 0, 0.26)'
        },
        ...props.sx
      }}
      deleteIcon={
        <span className="material-icons" onClick={onDelete}>
          close
        </span>
      }
      label={label}
      onDelete={onDelete}
    />
  );
};

export default Tag;
