import { Button, DatePicker, ErrorContainer, LabelComponent, Stack } from 'components';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { GoalsDTO } from 'store/roadmap/roadmapSlice';
import { useAppDispatch } from 'store/hooks';
import { updateGoal } from 'store/roadmap/roadmapActions';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { dateValidation } from 'utils/validations/validations';

type Props = {
  onClose: () => void;
  goal: GoalsDTO;
};

const GoalEventForm = ({ onClose, goal }: Props) => {
  const { clientId } = useParams();
  const dispatch = useAppDispatch();

  const { handleSubmit, values, dirty, isValid, setFieldValue, setFieldTouched, errors } =
    useFormik({
      initialValues: {
        ...goal,
        date: dayjs(goal.date).format('MM/DD/YYYY') ?? ''
      },
      validationSchema: yup.object().shape({
        date: dateValidation
      }),
      onSubmit: (newData) => {
        dispatch(updateGoal({ data: newData, clientId: clientId ?? '' }));
        onClose();
      }
    });

  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        <Stack>
          <LabelComponent text="Date" />
          <DatePicker
            onChange={(date: string) => {
              setFieldValue('date', date).then(() => {
                setFieldTouched('date', true);
              });
            }}
            value={values.date || ''}
            placeholder="YYYY-MM-DD"
            minDate={dayjs()}
            disablePast
          />
          <ErrorContainer errors={errors} name="date" visible={errors.date} />
        </Stack>
        <Stack mt="12px" display="flex" flexDirection="row" gap="12px">
          <Button
            type="button"
            theme="transparent"
            size="extraSmall"
            text="Cancel"
            handleOnClick={onClose}
          />
          <Button
            type="submit"
            theme="primary"
            size="extraSmall"
            text="Save"
            disabled={!dirty || !isValid}
          />
        </Stack>
      </Stack>
    </form>
  );
};

export default GoalEventForm;
