import clsx from 'clsx';
import { Stack } from 'components';
import { TextareaHTMLAttributes } from 'react';

import style from './joyTextArea.module.scss';

const JoyTextArea = (props: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  return (
    <div className={clsx(style.textAreaWrapper, props.disabled && style.disabled)}>
      <textarea {...props} />
      <Stack display="flex" flexDirection="row" justifyContent="end">
        {/* <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                background: '#314157',
                borderRadius: '100px',
                padding: '8px 12px'
              }
            }
          }}
          title="Coming soon"
        >
          <div className={style.microphoneButton}>
            <span className="material-icons">mic</span>
          </div>
        </Tooltip> */}
      </Stack>
    </div>
  );
};

export default JoyTextArea;
