import { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { setHasUnsavedWork } from 'store/organization/organizationSlice';
import { FormikProps } from 'formik';

export function useHasUnsavedChanges(
  formik: {
    isValid: FormikProps<any>['isValid'];
    submitForm: FormikProps<any>['submitForm'];
  },
  hasUnsavedWork: boolean
) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    //If there are unsaved changes
    //update global state
    dispatch(setHasUnsavedWork({ hasUnsavedWork, formik }));
    return () => {};
  }, [hasUnsavedWork, formik.isValid, formik.submitForm]);
}
