import clsx from 'clsx';
import * as React from 'react';

import style from './page404.module.scss';

const Page404: React.FunctionComponent = (props) => {
  return (
    <>
      <div className={clsx(style.container404)}>
        <div>404 | Page not Found</div>
      </div>
    </>
  );
};

export default Page404;
