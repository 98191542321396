import { LOGIN_PAGE_URL } from 'constants/utils';

import { useAuth0 } from '@auth0/auth0-react';
import { Popper } from 'components';
import {
  deleteLsBothTokens,
  deleteReportingFilters
} from 'modules/networkTools/localStorageTokens';
import { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';

import style from './profile.module.scss';
import ProfileForm from './profileForm';
import ProfileInfo from './profileInfo';

interface Props {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  open: boolean;
}

const ProfilePopper: FC<Props> = ({ anchorEl, onClose, open }) => {
  const [edit, setEdit] = useState(false);

  const profile = useAppSelector((state) => state.profile.info);
  const profileImage = useAppSelector((state) => state.profile.profileImage);

  const { logout } = useAuth0();

  useEffect(() => {
    if (!open) setEdit(false);
  }, [open]);

  const onHandleLogout = () => {
    deleteLsBothTokens();
    deleteReportingFilters();
    logout({
      logoutParams: {
        returnTo: window.location.origin + LOGIN_PAGE_URL
      }
    });
  };

  return (
    <Popper open={open} anchorEl={anchorEl} onClose={onClose}>
      <div className={style.profile} data-test-id="profile-modal">
        {edit ? (
          <ProfileForm
            profile={profile}
            profileImage={profileImage}
            setEdit={() => setEdit(false)}
          />
        ) : (
          <ProfileInfo profile={profile} profileImage={profileImage} />
        )}
        <div className={style.bottomWrap}>
          {!edit && (
            <div className={style.text} onClick={() => setEdit(true)}>
              <span className={'material-icons'}>edit</span>
              <p>Edit profile</p>
            </div>
          )}

          <div className={style.text} onClick={onHandleLogout}>
            <span className={'material-icons-round'}>logout</span>
            <p>Log Out</p>
          </div>
        </div>
      </div>
    </Popper>
  );
};

export default ProfilePopper;
