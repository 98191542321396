import { Avatar } from '@mui/material';
import { Badge, Stack } from 'components';
import dayjs from 'dayjs';
import { Addendum as AddendumType } from 'store/clientProfile/clientProfileSlice';

import { AddendumForm } from '../addendumForm';
import style from './addendum.module.scss';

type Props = {
  addendum: AddendumType;
};

const Addendum = ({ addendum }: Props) => {
  return (
    <Stack py="16px" borderTop="1px solid #F1F5F9">
      <Stack display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Stack gap="4px" display="flex" flexDirection="row" alignItems="center">
          <Avatar
            sx={{
              width: 16,
              height: 16
            }}
            alt={addendum.author.profileImageKey}
            src={addendum.author.profileImageUrl}
          >
            <span className="material-icons-outlined">person</span>
          </Avatar>
          <p className={style.authorName}>{addendum.author.name}</p>
        </Stack>
        <Stack display="flex" flexDirection="row" alignItems="center" gap="8px">
          {addendum.status === 'DRAFT' && <Badge text="Draft" size="small" type="warning" />}
          <p className={style.date}>{dayjs(addendum?.createdAt).format('MM/DD/YYYY')}</p>
        </Stack>
      </Stack>
      {addendum.status === 'DRAFT' ? (
        <AddendumForm
          addendumId={addendum.id}
          initialValues={{ addendum: addendum.content, status: addendum.status }}
          withLabel={false}
          editInitial={false}
        />
      ) : (
        <Stack mt="28px">
          <p className={style.addendumTitle}>
            <strong>Addendum:</strong>
          </p>

          <p className={style.addendumContent}>{addendum.content}</p>
        </Stack>
      )}
    </Stack>
  );
};

export default Addendum;
