import style from '../../styles/exportIntoTsx.module.scss';

export const usePlaceStackedVertically = (
  _line1: number,
  _line2: number,
  _line3: number,
  disableHints?: boolean
) => {
  const DISTANCE = 10;
  if (Math.abs(_line2 - _line1) <= DISTANCE) {
    return {
      line1IconPositionY: disableHints ? 0 : 3,
      line2IconPositionY: disableHints ? -25 : -28,
      line3IconPositionY: disableHints ? -13 : -16
    };
  }
  if (Math.abs(_line1 - _line3) <= DISTANCE) {
    return {
      line1IconPositionY: disableHints ? -10 : -28,
      line2IconPositionY: disableHints ? -10 : -16,
      line3IconPositionY: disableHints ? 0 : 3
    };
  }
  if (Math.abs(_line2 - _line3) <= DISTANCE) {
    return {
      line1IconPositionY: disableHints ? -10 : -16,
      line2IconPositionY: disableHints ? -10 : -28,
      line3IconPositionY: disableHints ? 0 : 3
    };
  } else {
    return {
      line1IconPositionY: disableHints ? -10 : -13,
      line2IconPositionY: disableHints ? -10 : -13,
      line3IconPositionY: disableHints ? -10 : -17
    };
  }
};

export const useReturnLinePositions = (_line1: number, _line2: number, _line3?: number | null) => {
  _line3 = _line3 || 0;
  const maxValue = Math.max(_line1, _line2, _line3);

  const line1IconPositionX = (_line1 / maxValue) * 100;

  const line2IconPositionX = (_line2 / maxValue) * 100;

  const line3IconPositionX = (_line3 / maxValue) * 100;

  return {
    line1IconPositionX: line1IconPositionX,
    line2IconPositionX: line2IconPositionX,
    line3IconPositionX: line3IconPositionX
  };
};

export const useLineColors = (_line1: number, _line3: number | null, isGreen?: boolean) => {
  let line1Color = style.blue;
  if ((_line3 && _line1 >= _line3) || isGreen) {
    line1Color = style.successMiddle;
  }

  return {
    line1Color: line1Color
  };
};
